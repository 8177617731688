import CustomComponent from '../../custom-components/CustomComponent'
import TransparentButton from '../controls/TransparentButton'
import TypedContent from '../shared/typed-content/TypedContent'
import styles from './FilterCard.module.scss'

import { HiTrash } from 'react-icons/hi'

const FilterCard = ({ fieldData, fieldDisplaySettings, filterInfo, clearFilter }) => {
  return (
    <div className={styles.wrapper}>
      <span>{filterInfo?.fieldName}</span>
      <span>{filterInfo?.opsName}</span>
      {filterInfo?.filterValue ? (
        fieldData?.relationFieldId ? (
          <CustomComponent
            value={filterInfo?.filterValue}
            fieldData={fieldData}
            fieldDisplaySettings={fieldDisplaySettings}
          />
        ) : (
          <TypedContent type={fieldData?.type} value={filterInfo?.filterValue} />
        )
      ) : null}

      <TransparentButton width="2rem" height="2rem" onClick={clearFilter}>
        <HiTrash size={'1.8rem'} />
      </TransparentButton>
    </div>
  )
}

export default FilterCard
