import styles from './DevelopmentScreen.module.css'
import GroupsLayout from '../../layouts/GroupsLayout'
import { Route, Routes } from 'react-router-dom'
import DatabasesLayout from '../../layouts/DatabasesLayout'
import TablesLayout from '../../layouts/TablesLayout'
import TableSettingsLayout from '../../layouts/TableSettingsLayout'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentScreen } from '../../store/slices/workspaceSlice'

const DevelopmentScreen = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentScreen('development'))
    return () => dispatch(setCurrentScreen(undefined))
  }, [])

  return (
    <div className={styles.content}>
      <Routes>
        <Route path="/*" element={<GroupsLayout />} />
        <Route path=":groupID/*" element={<DatabasesLayout />} />
        <Route
          path=":groupID/database/:databaseID/*"
          element={<TablesLayout />}
        />
        <Route
          path=":groupID/database/:databaseID/table/:tableID/*"
          element={<TableSettingsLayout />}
        >
          <Route path="view/:viewID/*" element={<TableSettingsLayout />} />
        </Route>
      </Routes>
    </div>
  )
}

export default DevelopmentScreen
