import styles from './ControlsPanel.module.css'

import { MdMoreHoriz } from 'react-icons/md'

const ControlsPanel = ({ controls }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.controls_content}>
        <div className={styles.more_icon}>
          <MdMoreHoriz size={'2rem'} />
        </div>
        {controls}
      </div>
    </div>
  )
}

export default ControlsPanel
