import styles from './ControlButton.module.css'

const ControlButton = ({ icon, title, onClick, disabled }) => {
  return (
    <button
      tabIndex={-1}
      className={
        disabled ? styles.control_button_disabled : styles.control_button
      }
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
    </button>
  )
}

export default ControlButton
