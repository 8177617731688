import { useLayoutEffect, useRef, useState } from "react"
import { useLoadFieldInfoMutation, useLoadKanbanColumnsQuery, useLoadRecordsDataQuery, useUpdateDataRecordMutation, useUpdateViewMutation } from "../services/apiServiceSlice"

const autoUpdateDelay = 500

const baseKanbanOptions = {
    columnsDefiner: null,
    displaySettings: {
        columnsOrder: null
    }
}

const useKanban = ({ groupID, databaseID, tableID, viewID, viewData, skip }) => {
    const lastKnownViewID = useRef(viewID)
    const orderSystemName = useRef(null)
    const kanbanOptionsCache = useRef(null)
    const [columnsData, setColumnsData] = useState(undefined)
    const [relationTableID, setRelationTableID] = useState(undefined)
    const [availableDefiners, setAvailableDefiners] = useState(undefined)
    const [kanbanOptions, setKanbanOptions] = useState(undefined)
    const [columnsDefiner, setColumnsDefiner] = useState(undefined)

    const [updateView] = useUpdateViewMutation()
    const [loadFieldInfo] = useLoadFieldInfoMutation()
    const [updateDataRecord] = useUpdateDataRecordMutation()

    const { data: kanbanColumns } = useLoadKanbanColumnsQuery({ tableID: relationTableID }, { skip: skip || !relationTableID })

    const kanbanDataRequestBody = {
        // fields: ['*'],
        fields: viewData?.fields?.length ? [...viewData?.fields?.map(fieldData => fieldData?.systemName), orderSystemName.current] : undefined,
        filters: viewData?.filters?.filter(filterData => filterData?.key !== columnsDefiner?.systemName),
        joins: viewData?.joins,
        sorts: [{ column: orderSystemName.current, direction: 'ASC' }],
        // view: viewID,
        pagination: {
            perPage: 9999,
            currPage: 1
        }
    }

    // groupings: [{
    //     column: columnsDefiner?.systemName,
    //     aggregations: [{
    //         function: 'json_agg',
    //         column: columnsDefiner?.systemName,
    //     }]
    // }],

    const skipCardsDataLoading = skip || !viewData?.fields?.length || viewData?.displayOptions && !viewData?.displayOptions?.kanban

    const { data: kanbanData } = useLoadRecordsDataQuery({ groupID: groupID, databaseID: databaseID, tableID: tableID, body: kanbanDataRequestBody }, { skip: skipCardsDataLoading })

    async function updateKanbanOptions() {
        const updateResult = await updateView({ viewID: viewID, body: { ...viewData, filters: viewData?.filters?.length ? viewData?.filters : null, displayOptions: { ...viewData?.displayOptions, kanban: kanbanOptions } } })
        if (!updateResult?.error) {
            // console.log(updateResult?.data?.data)
        }
    }

    function changeColumnsDefiner(fieldData) {
        if (!fieldData) return
        if (columnsDefiner && columnsDefiner?.systemName === fieldData?.systemName) return
        setColumnsDefiner(fieldData)
        setKanbanOptions({ ...kanbanOptions, columnsDefiner: fieldData?.systemName, updateStamp: Date.now() })
    }

    ///// механика перемещения колонок и карточек /////
    function replaceElement(type, target, destination, direction) {
        if (!type) return
        if (type === 'column') {
            reorderColumn(target, destination, direction)
            return
        }
        if (type === 'card') {
            if (target?.columnID === destination?.columnID) {
                reorderCard(target, destination, direction)
            } else {
                replaceCard(target, destination, direction)
            }
            return
        }
    }

    function reorderColumn(target, destination, direction) {
        //target - {columnID - id столбца, который перетягивается}  
        //destination - {columnID - столбца, на место которого встает перетягиваемый}
        //direction - 0/1 - положение относительно столбца, на место которого встает перетягиваемый (до/после)
        const preparedOrder = kanbanOptions?.displaySettings?.columnsOrder.filter(columnID => columnID !== target?.columnID)
        const targetColumnIndex = kanbanOptions?.displaySettings?.columnsOrder?.indexOf(target?.columnID)
        const destinationColumnIndex = preparedOrder.indexOf(destination?.columnID)
        if (targetColumnIndex === destinationColumnIndex + direction) return
        preparedOrder.splice(destinationColumnIndex + direction, 0, target?.columnID)
        setKanbanOptions({ ...kanbanOptions, displaySettings: { ...kanbanOptions?.displaySettings, columnsOrder: preparedOrder }, updateStamp: Date.now() })
    }

    async function reorderCard(target, destination, direction) {
        //target - {columnID, cardID} карточки, которая перетягивается 
        //destination - {columnID, cardID} карточки, на место которой встает перетягиваемая
        //direction - 0/1 - положение относительно карточки, на место которой встает перетягиваемая (до/после)
        let updatedTargetCardOrder = 1
        const targetCard = columnsData[destination?.columnID]?.cards.find(recordData => recordData?.id === target?.cardID)
        const preparedColumnCardsData = columnsData[destination?.columnID]?.cards.filter(recordData => recordData?.id !== target?.cardID)

        const destinationCard = columnsData[destination?.columnID]?.cards?.find(recordData => recordData?.id === destination?.cardID)
        const destinationCardOrder = Number(destinationCard[orderSystemName.current])

        let targetCardIndex = -1
        for (let i = 0; i < columnsData[destination?.columnID]?.cards?.length; i++) {
            if (columnsData[destination?.columnID]?.cards[i]?.id === target?.cardID) {
                targetCardIndex = i
                break
            }
        }

        let destinationCardIndex = -1
        for (let i = 0; i < preparedColumnCardsData.length; i++) {
            if (preparedColumnCardsData[i]?.id === destination?.cardID) {
                destinationCardIndex = i
                break
            }
        }
        if (destinationCardIndex < 0) return

        if (targetCardIndex === destinationCardIndex + direction) return

        if (direction === 0) {
            if (destinationCardIndex === 0) {
                updatedTargetCardOrder = destinationCardOrder - 1
            } else {
                updatedTargetCardOrder = (destinationCardOrder + Number(columnsData[destination?.columnID]?.cards[destinationCardIndex - 1][orderSystemName.current])) / 2
            }
        }

        if (direction === 1) {
            if (destinationCardIndex === columnsData[destination?.columnID]?.cards?.length - 1) {
                updatedTargetCardOrder = destinationCardOrder + 1
            } else {
                updatedTargetCardOrder = (destinationCardOrder + Number(columnsData[destination?.columnID]?.cards[destinationCardIndex + 1][orderSystemName.current])) / 2
            }
        }

        preparedColumnCardsData.splice(destinationCardIndex + direction, 0, { ...targetCard, [orderSystemName.current]: updatedTargetCardOrder })
        setColumnsData({ ...columnsData, [destination?.columnID]: { ...columnsData[destination?.columnID], cards: preparedColumnCardsData } })

        await updateDataRecord({
            groupID: groupID,
            databaseID: databaseID,
            tableID: tableID,
            rowID: target?.cardID,
            body: { data: { [orderSystemName.current]: String(updatedTargetCardOrder) } }
        })
    }

    async function replaceCard(target, destination, direction) {
        //target - {columnID, cardID} карточки, которая перетягивается 
        //destination - {columnID, cardID} карточки, на место которой встает перетягиваемая
        //direction - 0/1 - положение относительно карточки, на место которой встает перетягиваемая (до/после)
        let updatedTargetCardOrder = 1
        const targetCard = columnsData[target?.columnID]?.cards?.find(recordData => recordData?.id === target?.cardID)
        const preparedTargetColumnCardsData = columnsData[target?.columnID]?.cards?.filter(recordData => recordData?.id !== target?.cardID)

        const replaceCardRequestData = {
            [columnsDefiner?.systemName]: destination?.columnID !== 'unsorted' ? destination?.columnID : null
        }

        if (!destination?.cardID) {
            // перемещение карточки в пустую колонку
            setColumnsData({
                ...columnsData,
                [target?.columnID]: { ...columnsData[target?.columnID], cards: preparedTargetColumnCardsData },
                [destination?.columnID]: {
                    ...columnsData[destination?.columnID],
                    cards: [{
                        ...targetCard, [columnsDefiner?.systemName]: destination?.columnID !== 'unsorted' ? destination?.columnID : null,
                        [orderSystemName.current]: updatedTargetCardOrder
                    }]
                }
            })
            await updateDataRecord({
                groupID: groupID,
                databaseID: databaseID,
                tableID: tableID,
                rowID: target?.cardID,
                body: { data: { ...replaceCardRequestData, [orderSystemName.current]: String(updatedTargetCardOrder) } }
            })
            return
        }
        const destinationCard = columnsData[destination?.columnID]?.cards?.find(recordData => recordData?.id === destination?.cardID)
        const destinationCardOrder = Number(destinationCard[orderSystemName.current])
        const preparedDestinationColumnCardsData = columnsData[destination?.columnID]?.cards
        let destinationCardIndex = -1
        for (let i = 0; i < preparedDestinationColumnCardsData.length; i++) {
            if (preparedDestinationColumnCardsData[i]?.id === destination?.cardID) {
                destinationCardIndex = i
                break
            }
        }
        if (destinationCardIndex < 0) return

        if (direction === 0) {
            if (destinationCardIndex === 0) {
                updatedTargetCardOrder = destinationCardOrder - 1
            } else {
                updatedTargetCardOrder = (destinationCardOrder + Number(columnsData[destination?.columnID]?.cards[destinationCardIndex - 1][orderSystemName.current])) / 2
            }
        }

        if (direction === 1) {
            if (destinationCardIndex === columnsData[destination?.columnID]?.cards?.length - 1) {
                updatedTargetCardOrder = destinationCardOrder + 1
            } else {
                updatedTargetCardOrder = (destinationCardOrder + Number(columnsData[destination?.columnID]?.cards[destinationCardIndex + 1][orderSystemName.current])) / 2
            }
        }

        preparedDestinationColumnCardsData.splice(destinationCardIndex + direction, 0, { ...targetCard, [orderSystemName.current]: updatedTargetCardOrder })
        setColumnsData({
            ...columnsData,
            [target?.columnID]: { ...columnsData[target?.columnID], cards: preparedTargetColumnCardsData },
            [destination?.columnID]: { ...columnsData[destination?.columnID], cards: preparedDestinationColumnCardsData }
        })

        await updateDataRecord({
            groupID: groupID,
            databaseID: databaseID,
            tableID: tableID,
            rowID: target?.cardID,
            body: { data: { ...replaceCardRequestData, [orderSystemName.current]: String(updatedTargetCardOrder) } }
        })
    }

    //системНейм для поля с ордером карточек
    useLayoutEffect(() => {
        if (!viewID) {
            orderSystemName.current = null
        } else {
            orderSystemName.current = 'order_' + String(viewID).replaceAll('-', '_')
        }
    }, [viewID])

    //заполнение канбанОпшнс из сохраненных во вью
    useLayoutEffect(() => {
        if (skip) return
        if (!viewData?.displayOptions) return
        if (!viewData?.displayOptions?.kanban) {
            setKanbanOptions({ ...baseKanbanOptions, updateStamp: Date.now() })
            return
        }
        if (viewData?.displayOptions?.kanban?.updateStamp < kanbanOptions?.updateStamp) return
        setKanbanOptions(viewData?.displayOptions?.kanban)
    }, [viewData?.displayOptions, skip])

    //проверка соответствия сохраненных дисплейОпшнс с параметрами внешней таблицы
    useLayoutEffect(() => {
        if (skip) return
        if (!kanbanOptions) return
        if (!kanbanOptions?.columnsDefiner) return
        if (!kanbanColumns?.data) return
        //если отсутствуют записи во внешней таблице
        if (!kanbanColumns?.data?.length) {
            if (!kanbanOptions?.displaySettings?.columnsOrder?.filter(columnID => columnID !== 'unsorted')?.length) {
                return
            }
            setKanbanOptions({ ...kanbanOptions, displaySettings: { ...kanbanOptions?.displaySettings, columnsOrder: null }, updateStamp: Date.now() })
            return
        }
        const defaultColumnsOrder = kanbanColumns?.data?.map(recordData => recordData?.id)
        defaultColumnsOrder.push('unsorted')

        if (!kanbanOptions?.displaySettings?.columnsOrder?.length) {
            setKanbanOptions({ ...kanbanOptions, displaySettings: { ...kanbanOptions?.displaySettings, columnsOrder: defaultColumnsOrder }, updateStamp: Date.now() })
            return
        }
        const approvedColumns = kanbanOptions?.displaySettings?.columnsOrder?.filter(column => defaultColumnsOrder.includes(column))
        const missedColumns = defaultColumnsOrder.filter(column => !approvedColumns.includes(column))
        if (JSON.stringify([...approvedColumns, ...missedColumns]) !== JSON.stringify(kanbanOptions?.displaySettings?.columnsOrder)) {
            setKanbanOptions({ ...kanbanOptions, displaySettings: { ...kanbanOptions?.displaySettings, columnsOrder: [...approvedColumns, ...missedColumns] }, updateStamp: Date.now() })
        }
    }, [kanbanOptions, kanbanColumns?.data, skip])

    //проверка и установка коламнсДефайнера
    useLayoutEffect(() => {
        if (skip) return
        if (!kanbanOptions) return
        if (!(viewData?.fields && viewData?.fields?.length)) return
        const definersFields = viewData?.fields?.filter(fieldData => fieldData?.relationFieldId && viewData?.displaySettings[fieldData?.systemName]?.data?.relationTableID)
        if (!definersFields?.length) return
        setAvailableDefiners(definersFields)
        if (!kanbanOptions?.columnsDefiner) {
            setColumnsDefiner(definersFields[0])
            setKanbanOptions({ ...kanbanOptions, columnsDefiner: definersFields[0]?.systemName, updateStamp: Date.now() })
            return
        }
        if (!definersFields.map(fieldData => fieldData?.systemName).includes(kanbanOptions?.columnsDefiner)) {
            setKanbanOptions({ ...kanbanOptions, columnsDefiner: null, updateStamp: Date.now() })
            return
        }
        setColumnsDefiner(definersFields.find(fieldData => fieldData?.systemName === kanbanOptions?.columnsDefiner))
    }, [kanbanOptions, viewData?.fields])

    //установка id внешней таблицы
    useLayoutEffect(() => {
        if (!viewID) return
        if (!columnsDefiner) return
        if (!viewData?.displaySettings) return
        if (!viewData?.displaySettings.hasOwnProperty(columnsDefiner?.systemName)) return
        if (!viewData?.displaySettings[columnsDefiner?.systemName]?.data?.relationTableID) {
            //getRelationFieldData()
            // return
        }
        setRelationTableID(viewData?.displaySettings[columnsDefiner?.systemName]?.data?.relationTableID)
    }, [columnsDefiner, viewData?.displaySettings, skip])

    //распределение данных по столбцам канбан
    useLayoutEffect(() => {
        if (skip) return
        if (!columnsDefiner) return
        if (!kanbanColumns?.data) return
        if (!kanbanData?.data) return
        const preparedColumnsData = {}
        kanbanColumns?.data.forEach(columnRecord => preparedColumnsData[columnRecord?.id] = { header: columnRecord, cards: kanbanData?.data?.filter(dataRecord => dataRecord[columnsDefiner?.systemName] === columnRecord?.id) })
        preparedColumnsData['unsorted'] = { cards: kanbanData?.data?.filter(dataRecord => dataRecord[columnsDefiner?.systemName] === null) }
        setColumnsData(preparedColumnsData)
    }, [columnsDefiner, kanbanColumns?.data, kanbanData?.data, skip])

    //автосохранение настроек канбан-доски
    useLayoutEffect(() => {
        if (skip) return
        const deferredAutoupdate = setTimeout(() => {
            if (!kanbanOptions) return
            if (kanbanOptionsCache.current === null) {
                kanbanOptionsCache.current = JSON.stringify(kanbanOptions)
                return
            }
            if (JSON.stringify(kanbanOptions) !== kanbanOptionsCache.current) {
                kanbanOptionsCache.current = JSON.stringify(kanbanOptions)
                updateKanbanOptions()
            }

        }, autoUpdateDelay)
        return () => clearTimeout(deferredAutoupdate)
    }, [kanbanOptions, skip])

    //сброс параметров при смене вью
    if (viewID && lastKnownViewID.current !== viewID) {
        lastKnownViewID.current = viewID
        kanbanOptionsCache.current = null
        setColumnsData(undefined)
        setRelationTableID(undefined)
        setAvailableDefiners(undefined)
        setKanbanOptions(undefined)
        setColumnsDefiner(undefined)
    }

    //возвращение пустышек во избежание лагов при навигации между вью
    if (!viewID || lastKnownViewID.current !== viewID) {
        return {
            columnsData: undefined,
            availableDefiners: undefined,
            columnsDefiner: undefined,
            changeColumnsDefiner: () => null,
            replaceElement: () => null,
        }
    }

    return { columnsData, columnsOrder: kanbanOptions?.displaySettings?.columnsOrder, availableDefiners, columnsDefiner, changeColumnsDefiner, replaceElement }
}

export default useKanban