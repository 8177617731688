import { useLayoutEffect, useState } from "react"
import { useLoadFieldInfoMutation, useLoadFieldsInfoMutation, useLoadRecordsDataQuery } from "../services/apiServiceSlice"

const recordsDataRequestBody = {
    fields: ['*'],
    filters: null,
    paginate: {
        perPage: 100,
        currentPage: 1,
    },
}

function useRelationField(fieldData, fullDataPath) {
    const [relationTableParams, setRelationTableParams] = useState({ groupID: fullDataPath?.groupID, databaseID: fullDataPath?.databaseID, tableID: null })
    const [relationTableFields, setRelationTableFields] = useState([])

    const [loadFieldInfo] = useLoadFieldInfoMutation()
    const [loadFieldsInfo] = useLoadFieldsInfoMutation()

    const { data: recordsData, isFetching: loadingOptions } = useLoadRecordsDataQuery({ ...relationTableParams, body: recordsDataRequestBody }, { skip: !relationTableParams.tableID })


    async function getRelationTableID() {
        const relationFieldData = await loadFieldInfo({ fieldID: fieldData.relationFieldId })
        if (!relationFieldData.error) {
            setRelationTableParams({ ...relationTableParams, tableID: relationFieldData?.data?.data?.tableId })
        }
    }

    async function getRelationTableFields() {
        const relationTableFieldsData = await loadFieldsInfo({ tableID: relationTableParams.tableID })
        if (!relationTableFieldsData.error) {
            setRelationTableFields(relationTableFieldsData?.data?.data.map(fieldData => {
                if (!fieldData?.isSystemField) {
                    return fieldData
                } else {
                    return { ...fieldData, systemName: fieldData?.name }
                }
            }))
        }
    }

    useLayoutEffect(() => {
        if (!(fieldData && fieldData?.relationFieldId)) return
        if (!relationTableParams.tableID) {
            getRelationTableID()
        } else {
            if (!relationTableFields.length) {
                getRelationTableFields()
            }
        }
    }, [relationTableParams, fieldData])

    return { options: recordsData?.data ?? [], relationTableFields, loadingOptions }
}

export default useRelationField