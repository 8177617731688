import { useParams } from 'react-router-dom'
import CreateEntitiCard from '../components/entities/CreateEntitiCard'
import DatabaseCard from '../components/databases/DatabaseCard'
import styles from './DatabasesLayout.module.css'
import LoadStab from '../components/load/LoadStab'
import useFixedModal from '../features/modals/useFixedModal'
import {
  useCreateDatabaseMutation,
  useLoadDatabasesQuery,
} from '../services/apiServiceSlice'
import CreateEditEntitiForm from '../components/forms/CreateEditEntitiForm'

const DatabasesLayout = () => {
  const { groupID } = useParams()

  const { openModal, closeModal } = useFixedModal()

  const { data: databasesData, isFetching: loadingDatabases } =
    useLoadDatabasesQuery({ groupID: groupID }, { skip: !groupID })

  const [createDatabase] = useCreateDatabaseMutation()

  async function createDatabaseHandler(inputsData) {
    const createResult = await createDatabase({
      groupID: groupID,
      body: inputsData,
    })
    if (!createResult?.error) {
      closeModal()
    }
  }

  return (
    <div className={styles.workspace}>
      <div className={styles.content}>
        {databasesData?.data?.length ? (
          databasesData?.data?.map((databaseData) => (
            <DatabaseCard key={databaseData?.id} databaseData={databaseData} />
          ))
        ) : (
          <></>
        )}
        {loadingDatabases ? (
          <LoadStab />
        ) : (
          <CreateEntitiCard
            onClick={() =>
              openModal(
                <CreateEditEntitiForm
                  onSubmit={createDatabaseHandler}
                  onClose={closeModal}
                  descriptionIsAvailable
                />
              )
            }
          />
        )}
      </div>
    </div>
  )
}

export default DatabasesLayout
