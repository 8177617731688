import EllipsisLine from '../../../../components/text/EllipsisLine'
import styles from './NavigationEntitiHeader.module.css'

const NavigationEntitiHeader = ({
  icon,
  label,
  selected,
  disabled,
  onClick,
  controls,
}) => {
  return (
    <div className={styles.wrapper} onClick={!disabled ? onClick : () => null}>
      <div
        className={[
          styles.content,
          selected ? styles.selected : '',
          disabled ? styles.disabled : styles.active,
        ].join(' ')}
      >
        {icon && icon}
        <EllipsisLine>{label}</EllipsisLine>
        {controls && controls}
      </div>
    </div>
  )
}

export default NavigationEntitiHeader
