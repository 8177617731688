import dayjs from 'dayjs'
import { validateFloat, validateInteger } from '../../utils/validateInputValues'
import ControlledCheckbox from './ControlledCheckbox'
import ControlledInput from './ControlledInput'
import JSONTextArea from './JSONTextArea'
import DateInput from './DateInput'
import TimeInput from './TimeInput'
import DateTimeInput from './DateTimeInput'
import DateTimeTZInput from './DateTimeTZInput'
import SingleSelect from '../../custom-components/selects/SingleSelect'

const TypedInput = ({ type, value, onChange, fieldData, fieldDisplaySettings }) => {
  switch (type) {
    case 'uuid':
      return <ControlledInput value={value ?? ''} onChange={(value) => onChange(value)} />
    case 'string':
      return <ControlledInput value={value ?? ''} onChange={(value) => onChange(value ?? '')} />
    case 'integer':
      return (
        <ControlledInput
          value={value ?? null}
          onChange={(value) => onChange(validateInteger(value) ?? null)}
        />
      )
    case 'double':
      return (
        <ControlledInput
          value={value ?? null}
          onChange={(value) => {
            onChange(validateFloat(value) ?? null)
          }}
        />
      )
    case 'boolean':
      return <ControlledCheckbox checked={value} onChange={onChange} />
    case 'timestamp':
      return <DateTimeInput initialValue={value} onChange={onChange} />
    case 'timestamptz':
      return <DateTimeTZInput initialValue={value} onChange={onChange} />
    case 'date':
      return <DateInput initialValue={value} onChange={onChange} />
    case 'time':
      return <TimeInput initialValue={value} onChange={onChange} />
    case 'json':
      return <JSONTextArea value={value ?? null} onChange={(value) => onChange(value ?? null)} />
    case 'jsonb':
      return <JSONTextArea value={value ?? null} onChange={(value) => onChange(value ?? null)} />
    case 'singleSelect':
      return (
        <SingleSelect
          fieldData={fieldData}
          fieldDisplaySettings={fieldDisplaySettings}
          value={value}
          onChange={onChange}
        />
      )
    default:
      return <ControlledInput value={value ?? ''} onChange={(value) => onChange(value)} />
  }
}

export default TypedInput
