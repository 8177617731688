import styles from './Button.module.css'

const Button = ({
  type = 'button',
  onClick = () => null,
  disabled = false,
  children,
  width = '100%',
  height = '100%',
}) => {
  return (
    <button
      className={[
        styles.button,
        disabled ? styles.disabled : styles.active,
      ].join(' ')}
      style={{ width: width, height: height }}
      type={type}
      onClick={(event) => {
        event.stopPropagation()
        onClick(event)
      }}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
