import styles from './GroupsLayout.module.css'
import GroupCard from '../components/groups/GroupCard'
import CreateEntitiCard from '../components/entities/CreateEntitiCard'
import LoadStab from '../components/load/LoadStab'
import useFixedModal from '../features/modals/useFixedModal'
import {
  useCreateGroupMutation,
  useLoadGroupsQuery,
} from '../services/apiServiceSlice'
import CreateEditEntitiForm from '../components/forms/CreateEditEntitiForm'

const GroupsLayout = () => {
  const { openModal, closeModal } = useFixedModal()

  const { data: groupsData, isFetching: loadingGroups } = useLoadGroupsQuery()

  const [createGroup] = useCreateGroupMutation()

  async function createGroupHandler(inputsData) {
    const createResult = await createGroup({ body: inputsData })
    if (!createResult?.error) {
      closeModal()
    }
  }

  return (
    <div className={styles.workspace}>
      <div className={styles.content}>
        {groupsData?.data?.length ? (
          groupsData?.data?.map((groupData) => (
            <GroupCard key={groupData?.id} groupData={groupData} />
          ))
        ) : (
          <></>
        )}
        {loadingGroups && !groupsData?.data?.length ? <LoadStab /> : <></>}
        {!loadingGroups && (
          <CreateEntitiCard
            onClick={() =>
              openModal(
                <CreateEditEntitiForm
                  onSubmit={createGroupHandler}
                  onClose={closeModal}
                  descriptionIsAvailable
                />
              )
            }
          />
        )}
      </div>
    </div>
  )
}

export default GroupsLayout
