import dayjs from 'dayjs'

import { MdOutlineRadioButtonChecked } from 'react-icons/md'
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md'
import { TbJson } from 'react-icons/tb'
import EllipsisLine from '../../text/EllipsisLine'
import Sticker from '../../../custom-components/stickers/Sticker'
const timestampTZFormat = 'DD.MM.YYYY HH:mm Z'
const timestampFormat = 'DD.MM.YYYY HH:mm'
const dateFormat = 'DD.MM.YYYY'
const timeFormat = 'HH:mm'

const viewValueByType = (type, value) => {
  switch (type) {
    case 'string':
      return <EllipsisLine>{String(value)}</EllipsisLine>
    case 'integer':
      return <EllipsisLine>{String(value)}</EllipsisLine>
    case 'double':
      return <EllipsisLine>{String(value)}</EllipsisLine>
    case 'boolean':
      return /true/.test(value) ? (
        <MdOutlineRadioButtonChecked size={'1.8rem'} />
      ) : (
        <MdOutlineRadioButtonUnchecked size={'1.8rem'} />
      )
    case 'timestamptz':
      return <EllipsisLine>{dayjs(value).format(timestampTZFormat)}</EllipsisLine>
    case 'timestamp':
      return <EllipsisLine>{dayjs(value).format(timestampFormat)}</EllipsisLine>
    case 'date':
      return <EllipsisLine>{dayjs(value).format(dateFormat)}</EllipsisLine>
    case 'time':
      return <EllipsisLine>{String(value)}</EllipsisLine>
    case 'json':
      return <TbJson size={'2rem'} />
    case 'jsonb':
      return <TbJson size={'2rem'} />
    default:
      return <EllipsisLine>{String(value)}</EllipsisLine>
  }
}

const TypedContent = ({ type, value, format }) => {
  return value !== null ? viewValueByType(type, value, format) : ''
}

export default TypedContent
