import './styles/UniversalGrid.scss'

const UniversalGrid = (props) => {
  const {
    componentStab = null,
    fieldsData,
    recordsData,
    displaySettings = {},
    contentComponents = {},
    recordsDataStab = null,
    headerLeftControls = null,
    headerRightControls = null,
    tableRowLeftControl = null,
    tableRowRightControl = null,
    tableLeftControls = null,
    tableRightControls = null,
    tableBottomControls = null,
    topSlotComponent = null,
    bottomSlotComponent = null,
  } = props

  return (
    <div className="grid_wrapper">
      {topSlotComponent && <div className="grid_slot top_slot">{topSlotComponent}</div>}

      <div className="grid_slot main_slot">
        {tableLeftControls && <div className="table_side_slot">{tableLeftControls}</div>}
        <div className="table_container">
          {fieldsData && fieldsData.length ? (
            <div className="table_body">
              <div className="table_headers_row">
                {tableRowLeftControl && <div className="data_row_additional_slot" />}
                {fieldsData?.map((fieldData) => (
                  <div
                    key={fieldData?.id}
                    className="table_header"
                    style={{
                      width: `${displaySettings[fieldData?.systemName]?.width ?? 20}rem`,
                      flexGrow: displaySettings[fieldData?.systemName]?.fixed ? 0 : 1,
                    }}
                  >
                    {headerLeftControls && (
                      <div className="header_additional_slot header_left_controls">
                        {headerLeftControls(fieldData)}
                      </div>
                    )}
                    <span className="text_line">
                      {displaySettings[fieldData?.systemName]?.displayName ?? fieldData?.name}
                    </span>
                    {headerRightControls && (
                      <div className="header_additional_slot header_right_controls">
                        {headerRightControls(fieldData)}
                      </div>
                    )}
                  </div>
                ))}
                {tableRowRightControl && <div className="data_row_additional_slot" />}
              </div>
              {recordsData?.length ? (
                <div className="table_data">
                  {recordsData?.map((recordData) => (
                    <div className="table_data_row" key={recordData?.id}>
                      {tableRowLeftControl && (
                        <div className="data_row_additional_slot">
                          {tableRowLeftControl(recordData)}
                        </div>
                      )}
                      {fieldsData.map((fieldData) => (
                        <div
                          key={fieldData?.systemName}
                          className="table_cell"
                          style={{
                            width: `${displaySettings[fieldData?.systemName]?.width ?? 20}rem`,
                            flexGrow: displaySettings[fieldData?.systemName]?.fixed ? 0 : 1,
                          }}
                        >
                          {contentComponents[fieldData?.systemName] ? (
                            contentComponents[fieldData?.systemName](
                              recordData[fieldData?.systemName] ?? null
                            )
                          ) : (
                            <span className="text_line">
                              {recordData[fieldData?.systemName] ?? null}
                            </span>
                          )}
                        </div>
                      ))}
                      {tableRowRightControl && (
                        <div className="data_row_additional_slot">
                          {tableRowRightControl(recordData)}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                recordsDataStab
              )}
              {tableBottomControls && (
                <div className="table_bottom_slot">{tableBottomControls}</div>
              )}
            </div>
          ) : (
            componentStab
          )}
        </div>
        {tableRightControls && <div className="table_side_slot">{tableRightControls}</div>}
      </div>

      {bottomSlotComponent && <div className="grid_slot bottom_slot">{bottomSlotComponent}</div>}
    </div>
  )
}

export default UniversalGrid
