import { useState } from 'react'
import FormBasis, { ContentBlock } from './components/FormBasis'
import ControlledInput from '../controls/ControlledInput'
import ControlledSelect from '../controls/ControlledSelect'
import { useCreateFieldMutation } from '../../services/apiServiceSlice'
import basicFieldTypes from '../../constants/basicFieldTypes.json'
import basicDateTimeFormats from '../../constants/basicDateTimeFormats.json'
import LabeledControl from '../controls/LabeledControl'
import Button from '../controls/Button'
import ControlledCheckbox from '../controls/ControlledCheckbox'
import { IoMdSettings } from 'react-icons/io'
import TypedInput from '../controls/TypedInput'

const CreateTableFieldForm = ({ tableID, onClose, totalFields = 0 }) => {
  const [basicFieldParams, setBasicFieldParams] = useState({
    name: `New field ${totalFields + 1}`,
    type: 'string',
    defaultValue: null,
    unique: false,
    notNull: false,
    customType: null,
    dateFormat: undefined,
  })

  const [createField] = useCreateFieldMutation()

  async function createFieldHandler() {
    const createResult = await createField({
      tableID: tableID,
      body: basicFieldParams,
    })
    if (!createResult?.error) {
      onClose()
    }
  }

  return (
    <FormBasis width={'30rem'} onSubmit={createFieldHandler}>
      <ContentBlock height={'5rem'}>
        <LabeledControl label={'Field name'}>
          <ControlledInput
            value={basicFieldParams?.name}
            onChange={(nameValue) =>
              setBasicFieldParams({
                ...basicFieldParams,
                name: nameValue ?? null,
              })
            }
          />
        </LabeledControl>
      </ContentBlock>
      <ContentBlock height={'5rem'}>
        <LabeledControl label={'Field type'}>
          <ControlledSelect
            value={basicFieldParams?.type}
            options={basicFieldTypes}
            optionIdentifier={(typeValue) => typeValue?.type}
            optionDisplay={(typeValue) => typeValue?.displayName}
            onChange={(typeValue) =>
              setBasicFieldParams({
                ...basicFieldParams,
                type: typeValue ?? null,
                defaultValue: null,
              })
            }
          />
        </LabeledControl>
      </ContentBlock>
      <ContentBlock height={basicFieldParams?.type?.includes('json') ? 'fit-content' : '5rem'}>
        <LabeledControl label={'Default value'}>
          <TypedInput
            key={basicFieldParams?.type}
            type={basicFieldParams?.type}
            value={basicFieldParams?.defaultValue}
            onChange={(defaultFieldValue) =>
              setBasicFieldParams({
                ...basicFieldParams,
                defaultValue: defaultFieldValue ?? null,
              })
            }
          />
          {/* <ControlledSelect
            value={basicFieldParams?.type}
            options={basicFieldTypes}
            optionIdentifier={(typeValue) => typeValue?.type}
            optionDisplay={(typeValue) => typeValue?.displayName}
            onChange={(typeValue) =>
              setBasicFieldParams({ ...basicFieldParams, type: typeValue })
            }
          /> */}
        </LabeledControl>
      </ContentBlock>
      <ContentBlock height={'3rem'}>
        <ControlledCheckbox
          label={'Is unique'}
          checked={basicFieldParams?.unique}
          onChange={(uniqueValue) =>
            setBasicFieldParams({ ...basicFieldParams, unique: uniqueValue })
          }
        />
        <ControlledCheckbox
          label={'Not null'}
          checked={basicFieldParams?.notNull}
          onChange={(notNullValue) =>
            setBasicFieldParams({ ...basicFieldParams, notNull: notNullValue })
          }
        />
      </ContentBlock>

      {/* {basicFieldParams?.type === 'timestamptz' && (
        <ContentBlock height={'5rem'}>
          <LabeledControl label={'Date format'}>
            <ControlledSelect
              value={basicFieldParams?.dateFormat}
              options={basicDateTimeFormats}
              onChange={(dateFormatValue) =>
                setBasicFieldParams({
                  ...basicFieldParams,
                  dateFormat: dateFormatValue,
                })
              }
            />
          </LabeledControl>
        </ContentBlock>
      )} */}

      <ContentBlock height={'3rem'}>
        <Button type="submit">Create field</Button>
        <Button onClick={onClose}>Cancel</Button>
      </ContentBlock>
    </FormBasis>
  )
}

export default CreateTableFieldForm
