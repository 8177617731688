import { useLayoutEffect, useState } from "react"
import { useLoadFieldInfoMutation, useLoadRecordsDataQuery } from "../services/apiServiceSlice"

function useRelationRecord(fieldData, filterKey, filterValue, relationTableID, skip) {

    const [tableID, setTableID] = useState(relationTableID)

    const [loadFieldInfo] = useLoadFieldInfoMutation()

    const recordsDataRequestBody = {
        fields: ['*'],
        filters: filterValue && filterKey ? [{ key: filterKey, ops: '$eq', val: filterValue }] : [],
        paginate: {
            perPage: 100,
            currentPage: 1,
        },
    }

    const { data: recordsData, isFetching: loadingDataRecord } = useLoadRecordsDataQuery({ groupID: null, databaseID: null, tableID: tableID, body: recordsDataRequestBody }, { skip: skip || !(tableID && filterValue) })

    async function getRelationFieldData() {
        const relationFieldData = await loadFieldInfo({ fieldID: fieldData.relationFieldId })
        if (!relationFieldData.error) {
            setTableID(relationFieldData?.data?.data?.tableId)
        }
    }

    useLayoutEffect(() => {
        if (!(fieldData && fieldData?.relationFieldId)) return
        if (!tableID) {
            getRelationFieldData()
        }
    }, [fieldData, relationTableID])

    return { dataRecord: recordsData?.data?.length ? recordsData?.data[0] : null, loadingDataRecord }
}

export default useRelationRecord