//TO DELETE

import dayjs from 'dayjs'

import { MdOutlineRadioButtonChecked } from 'react-icons/md'
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md'
import EllipsisLine from '../../text/EllipsisLine'

const viewValueByType = (type, value, dateFormat = 'DD.MM.YYYY') => {
  switch (type) {
    case 'string':
      return <EllipsisLine>{String(value)}</EllipsisLine>
    case 'integer':
      return <EllipsisLine>{String(value)}</EllipsisLine>
    case 'double':
      return <EllipsisLine>{String(value)}</EllipsisLine>
    case 'boolean':
      return /true/.test(value) ? (
        <MdOutlineRadioButtonChecked size={'1.8rem'} />
      ) : (
        <MdOutlineRadioButtonUnchecked size={'1.8rem'} />
      )
    case 'timestamptz':
      return <EllipsisLine>{dayjs(value).format(dateFormat)}</EllipsisLine>
    default:
      return <EllipsisLine>{String(value)}</EllipsisLine>
  }
}

const CellContent = ({ type, value, dateFormat }) => {
  return value !== null ? viewValueByType(type, value, dateFormat) : ''
}

export default CellContent
