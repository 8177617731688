//TO DELETE

import { useDeleteDataRecordMutation, useLoadRecordsDataQuery } from '../services/apiServiceSlice'
import { useEffect, useState } from 'react'
import useCombineFilters from '../hooks/useCombineFilters'
import useCombineSorts from '../hooks/useCombineSorts'
import FiltersPanel from '../components/filters/FiltersPanel'
import TitledScrollableBlock from '../components/blocks/TitledScrollableBlock'
import LoadStab from '../components/load/LoadStab'
import CreateDataRecordForm from '../components/forms/CreateDataRecordForm'
import UniversalTable from '../components/universal-table/UniversalTable'
import useFixedModal from '../features/modals/useFixedModal'
import { useSelector } from 'react-redux'
import { developmentSelector } from '../store/slices/developmentSlice'
import { useParams } from 'react-router-dom'

const TablePreviewLayout = () => {
  const { groupID, databaseID, tableID } = useParams()
  const [fieldsOrder, setFieldsOrder] = useState([])

  const [combinedFilters, applyFilter, clearFilter] = useCombineFilters()
  const [combinedSorts, applySort, clearSort] = useCombineSorts()

  const { openModal, closeModal } = useFixedModal()

  const fieldsData = useSelector(developmentSelector.getFields)
  const temporaryJoins = useSelector(developmentSelector.getJoins)

  useEffect(() => {
    setFieldsOrder(fieldsData?.map((fieldData) => fieldData?.systemName))
  }, [fieldsData])

  const [deleteDataRecord] = useDeleteDataRecordMutation()

  const tableDataRequestOptions = {
    groupID: groupID,
    databaseID: databaseID,
    tableID: tableID,
    body: {
      fields: fieldsOrder,
      filters: combinedFilters,
      sorts: combinedSorts,
      joins: temporaryJoins,
      paginate: {
        perPage: 100,
        currentPage: 1,
      },
    },
  }

  const { data: tableData, isFetching: loadingRows } = useLoadRecordsDataQuery(
    tableDataRequestOptions,
    { skip: !(groupID && databaseID && tableID && fieldsOrder?.length) }
  )

  async function deleteDataRecordHandler({ rowID }) {
    const createDataRecordResult = await deleteDataRecord({
      groupID: groupID,
      databaseID: databaseID,
      tableID: tableID,
      rowID: rowID,
    })
    if (createDataRecordResult?.error) {
      alert(createDataRecordResult?.error?.data?.comment)
    }
  }

  function createRow() {
    openModal(
      <CreateDataRecordForm
        groupID={groupID}
        databaseID={databaseID}
        tableID={tableID}
        onClose={closeModal}
        fieldsData={fieldsData}
      />
    )
  }

  {
    /* <FiltersPanel
    fieldsData={fieldsData}
    appliedFilters={combinedFilters}
    applyFilter={applyFilter}
    clearFilter={clearFilter}
  /> */
  }
  return (
    <TitledScrollableBlock label="Table preview">
      {fieldsData?.length ? (
        <UniversalTable
          fieldsData={fieldsData}
          applySort={applySort}
          combinedSorts={combinedSorts}
          tableData={tableData?.data}
          deleteDataRecord={deleteDataRecordHandler}
          fieldsOrder={fieldsOrder}
          createRecord={createRow}
        />
      ) : (
        <LoadStab />
      )}
    </TitledScrollableBlock>
  )
}

export default TablePreviewLayout
