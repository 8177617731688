const useRoots = () => {
  const appRoot = document.getElementById('app_root')
  //Добавить проверку на присутствие элемента в ДОМ-дереве
  const modalsRoot = document.getElementById('modals_root')
  const notificationsRoot = document.getElementById('notifications_root')

  return {
    appRoot,
    modalsRoot,
    notificationsRoot,
  }
}

export default useRoots
