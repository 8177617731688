import styles from './TableBottomControls.module.css'

const TableBottomControls = ({ children, rightControls }) => {
  return (
    <div
      className={[
        styles.content,
        rightControls ? styles.right_controlls : styles.no_controlls,
      ].join(' ')}
    >
      {children}
    </div>
  )
}

export default TableBottomControls
