import { useRef, useState } from 'react'

const useCombineSorts = (entitiID) => {
  const lastKnownEntitiID = useRef(entitiID)
  const [combinedSorts, setCombinedSorts] = useState([])

  function applySort(fieldKey, sortValue) {
    const appliedSorts = [...combinedSorts]
    const { isApplied, sortIndex } = checkIsSortExists(fieldKey)
    if (!isApplied && sortValue) {
      appliedSorts.push({ column: fieldKey, direction: sortValue })
      setCombinedSorts(appliedSorts)
      return
    }
    if (isApplied) {
      if (sortValue && sortValue !== appliedSorts[sortIndex].direction) {
        appliedSorts[sortIndex] = {
          ...appliedSorts[sortIndex],
          direction: sortValue,
        }
        setCombinedSorts(appliedSorts)
        return
      }
      if (!sortValue) {
        clearSort(fieldKey)
      }
    }
  }

  function clearSort(fieldKey) {
    const clearedSorts = combinedSorts.filter(
      (fieldSort) => fieldSort.column !== fieldKey
    )
    setCombinedSorts(clearedSorts)
  }

  function resetSorts() {
    setCombinedSorts([])
  }

  function checkIsSortExists(fieldKey) {
    for (let sortIndex = 0; sortIndex < combinedSorts.length; sortIndex++) {
      if (combinedSorts[sortIndex].column === fieldKey) {
        return { isApplied: true, sortIndex: sortIndex }
      }
    }
    return { isApplied: false, sortIndex: undefined }
  }

  if (lastKnownEntitiID.current !== entitiID) {
    lastKnownEntitiID.current = entitiID
    setCombinedSorts([])
    return {
      combinedSorts: [],
      applySort: () => null,
      clearSort: () => null,
      resetSorts: () => null,
    }
  }

  return { combinedSorts, applySort, clearSort, resetSorts }
}

export default useCombineSorts
