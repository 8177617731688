import styles from './TableSettingsLayout.module.css'
import TablePreviewLayout from './TablePreviewLayout'
import TableSetupLayout from './table-setup-layout/TableSetupLayout'

const TableSettingsLayout = () => {
  return (
    <div className={styles.workspace}>
      <div className={styles.content}>
        <section className={styles.settings_panel}>
          <TableSetupLayout />
        </section>
        <section className={styles.preview_panel}>
          <TablePreviewLayout />
        </section>
      </div>
    </div>
  )
}

export default TableSettingsLayout
