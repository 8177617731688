import styles from './TableHeaders.module.css'

const TableHeaders = ({ children, rightControls }) => {
  return (
    <div
      className={[
        styles.content,
        rightControls ? styles.right_controls : styles.no_controls,
      ].join(' ')}
    >
      {children}
    </div>
  )
}

export default TableHeaders
