import styles from './styles/UniversalKanban.module.scss'
import KanbanColumn from './components/KanbanColumn'
import KanbanCard from './components/KanbanCard'
import EllipsisLine from '../../text/EllipsisLine'
import KanbanCardRecord from './components/KanbanCardRecord'
import { useState } from 'react'
import KanbanCardPlaceholder from './components/KanbanCardPlaceholder'

const UniversalKanban = ({
  columnsDefiner,
  columnsData,
  columnsOrder,
  fieldsData,
  displaySettings,
  contentComponents,
  componentStab = 'нет данных',
  replaceElement,
}) => {
  const [dragData, setDragData] = useState(null)

  return (
    <div className={styles.wrapper}>
      <div className={styles.main_slot}>
        <div className={styles.columns_container}>
          {columnsOrder && columnsData
            ? columnsOrder?.map((columnID) => (
                <KanbanColumn
                  key={columnID}
                  headerComponent={
                    columnsData[columnID]?.header ? (
                      contentComponents.hasOwnProperty(columnsDefiner?.systemName) ? (
                        contentComponents[columnsDefiner?.systemName](
                          columnsData[columnID]?.header?.id,
                          columnsData[columnID]?.header
                        )
                      ) : (
                        <EllipsisLine>{columnsData[columnID]?.header?.id}</EllipsisLine>
                      )
                    ) : null
                  }
                  draggable
                  droppable={
                    dragData &&
                    dragData?.type === 'column' &&
                    dragData?.target?.columnID !== columnID
                  }
                  onDragStart={() =>
                    setDragData({ type: 'column', target: { columnID: columnID } })
                  }
                  onDragEnd={() => setDragData(null)}
                  onDrop={(direction) => {
                    replaceElement(
                      dragData?.type,
                      dragData?.target,
                      { columnID: columnID },
                      direction
                    )
                    setDragData(null)
                  }}
                >
                  {columnsData[columnID]?.cards?.map((cardData) => (
                    <KanbanCard
                      key={cardData?.id}
                      draggable
                      droppable={
                        dragData &&
                        dragData?.type === 'card' &&
                        dragData?.target?.cardID !== cardData?.id
                      }
                      onDragStart={() =>
                        setDragData({
                          type: 'card',
                          target: { columnID: columnID, cardID: cardData?.id },
                        })
                      }
                      onDragEnd={() => setDragData(null)}
                      onDrop={(direction) => {
                        replaceElement(
                          dragData?.type,
                          dragData?.target,
                          { columnID: columnID, cardID: cardData?.id },
                          direction
                        )
                        setDragData(null)
                      }}
                    >
                      {fieldsData?.map((fieldData) => (
                        <KanbanCardRecord
                          key={fieldData?.id}
                          title={
                            displaySettings[fieldData?.systemName]?.displayName ?? fieldData?.name
                          }
                        >
                          {contentComponents[fieldData?.systemName](
                            cardData[fieldData?.systemName]
                          )}
                        </KanbanCardRecord>
                      ))}
                    </KanbanCard>
                  ))}
                  {!columnsData[columnID]?.cards.length && dragData?.type === 'card' ? (
                    <KanbanCardPlaceholder
                      droppable={dragData && dragData?.type === 'card'}
                      onDragStart={() =>
                        setDragData({
                          type: 'card',
                          target: { columnID: columnID },
                        })
                      }
                      onDragEnd={() => setDragData(null)}
                      onDrop={() => {
                        replaceElement(dragData?.type, dragData?.target, { columnID: columnID })
                        setDragData(null)
                      }}
                    />
                  ) : null}
                </KanbanColumn>
              ))
            : componentStab}
        </div>
      </div>
    </div>
  )
}

export default UniversalKanban
