import styles from './ControlledInput.module.css'

const ControlledInput = ({
  placeholder = '',
  value = '',
  type = 'text',
  onChange = () => null,
  width = '100%',
  height = '100%',
}) => {
  return (
    <input
      className={styles.input}
      style={{ width: width, height: height }}
      placeholder={placeholder}
      value={value}
      type={type}
      onChange={(event) => onChange(event.target.value)}
    />
  )
}

export default ControlledInput
