import TypedInput from '../controls/TypedInput'
import { useMemo, useState } from 'react'
import { useUpdateDataRecordMutation } from '../../services/apiServiceSlice'
import FormBasis, { ContentBlock } from './components/FormBasis'
import LabeledControl from '../controls/LabeledControl'
import Button from '../controls/Button'
import CustomInput from '../../custom-components/CustomInput'

const EditDataRecordForm = ({
  groupID,
  databaseID,
  tableID,
  rowID,
  onClose,
  fieldsData,
  displaySettings = {},
  rowData,
}) => {
  const [rowInputsData, setRowInputsData] = useState(rowData)
  const [updateDataRecord] = useUpdateDataRecordMutation()

  async function updateDataRecordHandler() {
    const rowChangesData = {}

    Object.keys(rowInputsData).forEach((key) => {
      if (JSON.stringify(rowInputsData[key]) !== JSON.stringify(rowData[key])) {
        rowChangesData[key] = rowInputsData[key]
      }
    })

    if (!Object.keys(rowChangesData).length) {
      onClose()
      return
    }

    const updateResult = await updateDataRecord({
      groupID: groupID,
      databaseID: databaseID,
      tableID: tableID,
      rowID: rowID,
      body: { data: rowChangesData },
    })
    if (!updateResult?.error) {
      onClose()
    }
  }

  const directWritingFields = useMemo(
    () => fieldsData.filter((fieldData) => fieldData?.tableId === tableID),
    [fieldsData]
  )

  return (
    <FormBasis width={'50rem'} onSubmit={updateDataRecordHandler}>
      {directWritingFields?.length > 0 &&
        directWritingFields?.map((fieldData) => (
          <ContentBlock
            height={fieldData?.type?.includes('json') ? 'fit-content' : '5rem'}
            key={fieldData?.systemName}
          >
            <LabeledControl
              label={displaySettings[fieldData?.systemName]?.displayName ?? fieldData?.name}
            >
              {fieldData?.relationFieldId ? (
                <CustomInput
                  value={rowInputsData[fieldData?.systemName] ?? null}
                  onChange={(value) => {
                    setRowInputsData({
                      ...rowInputsData,
                      [fieldData?.systemName]: value,
                    })
                  }}
                  fieldData={fieldData}
                  fieldDisplaySettings={displaySettings[fieldData?.systemName]}
                />
              ) : (
                <TypedInput
                  type={fieldData?.type}
                  value={rowInputsData[fieldData?.systemName] ?? null}
                  onChange={(value) => {
                    setRowInputsData({
                      ...rowInputsData,
                      [fieldData?.systemName]: value,
                    })
                  }}
                />
              )}
            </LabeledControl>
          </ContentBlock>
        ))}
      <ContentBlock height={'3rem'}>
        <Button type="submit">Save</Button>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>
      </ContentBlock>
    </FormBasis>
  )
}

export default EditDataRecordForm
