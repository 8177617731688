import styles from './TitledScrollableBlock.module.css'

const TitledScrollableBlock = ({ label, children }) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>{children}</div>
      {label && <div className={styles.block_label}>{label}</div>}
    </section>
  )
}

export default TitledScrollableBlock
