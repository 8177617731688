import { useContext } from 'react'
import {
  useCreateDatabaseMutation,
  useDeleteGroupMutation,
  useLoadDatabasesQuery,
  useUpdateGroupMutation,
} from '../../../../services/apiServiceSlice'
import DatabasesNavigator from './DatabaseNavigator'
import GroupedContent from './GroupedContent'
import NavigationEntitiHeader from './NavigationEntitiHeader'
import AddEntitiButton from './AddEntitiButton'
import useFixedModal from '../../../../features/modals/useFixedModal'
import CreateEditEntitiForm from '../../../../components/forms/CreateEditEntitiForm'
import { useParams } from 'react-router'
import { NavigationContext } from './NavigationPanel'
import { FaLayerGroup } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { HiTrash } from 'react-icons/hi'
import TransparentButton from '../../../../components/controls/TransparentButton'
import ConfirmDialog from '../../../../components/gialogs/ConfirmDialog'

const GroupNavigator = ({ groupData }) => {
  const { groupID } = useParams()
  const { readOnly, branchDepth, onGroupSelect } = useContext(NavigationContext)

  const { openModal, closeModal } = useFixedModal()
  const { data: databasesData } = useLoadDatabasesQuery({
    groupID: groupData?.id,
  })

  const [createDatabase] = useCreateDatabaseMutation()
  const [deleteGroup] = useDeleteGroupMutation()
  const [updateGroup] = useUpdateGroupMutation()

  async function createDatabaseHandler(inputsData) {
    const creationResult = await createDatabase({
      groupID: groupData?.id,
      body: inputsData,
    })
    if (!creationResult?.error) {
      closeModal()
    }
  }

  async function deleteGroupHandler() {
    const deleteResult = await deleteGroup({ groupID: groupData?.id })
    if (!deleteResult?.error) {
      closeModal()
    }
  }

  async function updateGroupHandler(inputsData) {
    const updateResult = await updateGroup({
      groupID: groupData?.id,
      body: inputsData,
    })
    if (!updateResult?.error) {
      closeModal()
    }
  }

  return (
    <>
      <NavigationEntitiHeader
        icon={<FaLayerGroup size={'1.2rem'} style={{ flexShrink: 0 }} />}
        label={groupData?.name}
        onClick={onGroupSelect}
        selected={groupData?.id === groupID}
        disabled={!onGroupSelect}
        controls={
          !readOnly ? (
            <>
              <TransparentButton
                width="1.2rem"
                height="1.2rem"
                onClick={() =>
                  openModal(
                    <CreateEditEntitiForm
                      name={groupData?.name}
                      description={groupData?.description}
                      descriptionIsAvailable
                      onSubmit={updateGroupHandler}
                      onClose={closeModal}
                    />
                  )
                }
              >
                <MdEdit />
              </TransparentButton>
              <TransparentButton
                width="1.2rem"
                height="1.2rem"
                onClick={() =>
                  openModal(
                    <ConfirmDialog
                      title={'Delete group?'}
                      onConfirm={() => deleteGroupHandler(groupData?.id)}
                      onCancel={closeModal}
                    />
                  )
                }
              >
                <HiTrash />
              </TransparentButton>
            </>
          ) : undefined
        }
      />
      {branchDepth !== 'group' && (
        <GroupedContent>
          {databasesData?.data?.map((databaseData) => (
            <DatabasesNavigator
              key={databaseData?.id}
              groupData={groupData}
              databaseData={databaseData}
            />
          ))}
        </GroupedContent>
      )}
      {!readOnly && (
        <AddEntitiButton
          label={'+ database'}
          onClick={() =>
            openModal(
              <CreateEditEntitiForm
                descriptionIsAvailable
                onSubmit={createDatabaseHandler}
                onClose={closeModal}
              />
            )
          }
        />
      )}
    </>
  )
}

export default GroupNavigator
