import styles from './LoadStab.module.css'

const LoadStab = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.dot} />
        <div className={styles.dot} style={{ animationDelay: '250ms' }} />
        <div className={styles.dot} style={{ animationDelay: '500ms' }} />
      </div>
    </div>
  )
}

export default LoadStab
