export function validateTimeValue(hours, minutes, seconds) {
    let validatedHr = String(hours).replace(/[^\d]+/g, '')
    let validatedMn = String(minutes).replace(/[^\d]+/g, '')
    let validatedSc = String(seconds).replace(/[^\d]+/g, '')

    if (validatedHr) {
        if (Number(validatedHr) > 23) {
            validatedHr = '23'
        }
    }
    if (validatedMn) {
        if (Number(validatedMn) > 59) {
            validatedMn = '59'
        }
    }
    if (validatedSc) {
        if (Number(validatedSc) > 59) {
            validatedSc = '59'
        }
    }

    return {
        hours: validatedHr,
        minutes: validatedMn,
        seconds: validatedSc,
    }

}