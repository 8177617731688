import styles from '../styles/KanbanCardPlaceholder.module.scss'
import DNDController from './DNDController'

const KanbanCardPlaceholder = ({
  children,
  draggable,
  droppable,
  onDragStart,
  onDragEnd,
  onDrop,
}) => {
  return (
    <DNDController
      className={styles.card_placeholder_wrapper}
      dropDirection="full"
      draggable={draggable}
      droppable={droppable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
    >
      <div className={styles.card_placeholder_content}>{children}</div>
    </DNDController>
  )
}

export default KanbanCardPlaceholder
