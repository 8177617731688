import { useNavigate, useParams } from 'react-router-dom'
import EntitiCard from '../entities/EntitiCard'
import { FaDatabase } from 'react-icons/fa'
import {
  useDeleteDatabaseMutation,
  useUpdateDatabaseMutation,
} from '../../services/apiServiceSlice'
import { setNavigatedDatabase } from '../../store/slices/workspaceSlice'
import { useDispatch } from 'react-redux'
import useFixedModal from '../../features/modals/useFixedModal'
import CreateEditEntitiForm from '../forms/CreateEditEntitiForm'
import ConfirmDialog from '../gialogs/ConfirmDialog'

const DatabaseCard = ({ databaseData }) => {
  const { groupID } = useParams()

  const navigateTo = useNavigate()
  const dispatch = useDispatch()
  const { openModal, closeModal } = useFixedModal()
  const [deleteDatabase] = useDeleteDatabaseMutation()
  const [updateDatabase] = useUpdateDatabaseMutation()

  async function updateDatabaseHandler(inputsData) {
    const updateResult = await updateDatabase({
      groupID: groupID,
      databaseID: databaseData?.id,
      body: inputsData,
    })
    if (!updateResult?.error) {
      closeModal()
    }
  }

  function onEditHandler() {
    openModal(
      <CreateEditEntitiForm
        name={databaseData?.name}
        description={databaseData?.description}
        descriptionIsAvailable
        onSubmit={updateDatabaseHandler}
        onClose={closeModal}
      />
    )
  }

  function onRemoveHandler() {
    openModal(
      <ConfirmDialog
        title={'Remove this database?'}
        onConfirm={async () => {
          await deleteDatabase({
            groupID: groupID,
            databaseID: databaseData?.id,
          })
          closeModal()
        }}
        onCancel={closeModal}
      />
    )
  }

  function selectDatabase() {
    dispatch(setNavigatedDatabase(databaseData))
    navigateTo(`database/${databaseData?.id}`)
  }

  return (
    <EntitiCard
      icon={<FaDatabase size={'1.3rem'} />}
      title={databaseData?.name}
      description={databaseData?.description}
      onClick={selectDatabase}
      onRemove={onRemoveHandler}
      onEdit={onEditHandler}
    />
  )
}

export default DatabaseCard
