import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAccessTokenIsExpired } from '../store/slices/authSlice'
import { applyNotification } from '../store/slices/notificationsSlice'

const defaultQueryParams = {
  perPage: 100,
  currPage: 1,
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
  },
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().auth.accessToken
    if (accessToken) {
      headers.append('Authorization', `Bearer ${accessToken}`)
    }
  },
})

const baseQueryWithErrorsHandler = async (args, api, extraOptions) => {
  const fetchResult = await baseQuery(
    args,
    api,
    extraOptions
  )

  if (fetchResult?.error) {
    if (fetchResult?.error?.originalStatus === 401) {
      api.dispatch(setAccessTokenIsExpired(true))
    } else {
      api.dispatch(applyNotification({ type: 'error', message: fetchResult?.error?.data?.error }))
    }
  }

  return fetchResult
}

export const apiServiceSlice = createApi({
  reducerPath: 'apiService',
  baseQuery: baseQueryWithErrorsHandler,

  tagTypes: ['group', 'database', 'table', 'field', 'data', 'view', 'views'],

  endpoints: (builder) => ({
    //////////////////////// Groups ////////////////////////
    loadGroups: builder.query({
      query: (params) => ({
        url: '/group',
        params: { ...defaultQueryParams, ...params },
      }),
      providesTags: ['group'],
    }),

    loadGroupInfo: builder.query({
      query: ({ groupID, params }) => ({
        url: `/group/${groupID}`,
        params: params,
      }),
      providesTags: ['group'],
    }),

    createGroup: builder.mutation({
      query: ({ body }) => ({
        url: '/group',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['group'],
    }),

    updateGroup: builder.mutation({
      query: ({ groupID, body }) => ({
        url: `/group/${groupID}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['group'],
    }),

    deleteGroup: builder.mutation({
      query: ({ groupID }) => ({
        url: `/group/${groupID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['group'],
    }),

    //////////////////////// Databases ////////////////////////
    loadDatabases: builder.query({
      query: ({ groupID, params }) => ({
        url: `/database`,
        params: { groupId: groupID, ...defaultQueryParams, ...params },
      }),
      providesTags: ['database'],
    }),

    loadDatabaseInfo: builder.query({
      query: ({ databaseID }) => ({
        url: `/database/${databaseID}`,
      }),
      providesTags: ['database'],
    }),

    createDatabase: builder.mutation({
      query: ({ groupID, body }) => ({
        url: `/database`,
        method: 'POST',
        body: { groupId: groupID, ...body },
      }),
      invalidatesTags: ['database'],
    }),

    updateDatabase: builder.mutation({
      query: ({ databaseID, body }) => ({
        url: `/database/${databaseID}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['database'],
    }),

    deleteDatabase: builder.mutation({
      query: ({ databaseID }) => ({
        url: `/database/${databaseID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['database'],
    }),

    //////////////////////// Tables ////////////////////////
    loadTables: builder.query({
      query: ({ databaseID, params }) => ({
        url: `/table`,
        params: { databaseId: databaseID, ...defaultQueryParams, ...params },
      }),
      providesTags: ['table'],
    }),

    loadTableInfo: builder.mutation({
      query: ({ tableID }) => ({
        url: `/table/${tableID}`,
      }),
      providesTags: ['table'],
    }),

    createTable: builder.mutation({
      query: ({ databaseID, body }) => ({
        url: `/table`,
        method: 'POST',
        body: { databaseId: databaseID, ...body },
      }),
      invalidatesTags: ['table'],
    }),

    updateTable: builder.mutation({
      query: ({ tableID, body }) => ({
        url: `/table/${tableID}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['table'],
    }),

    deleteTable: builder.mutation({
      query: ({ tableID }) => ({
        url: `/table/${tableID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['table'],
    }),

    //////////////////////// Fields ////////////////////////
    loadFields: builder.query({
      query: ({ tableID, params }) => ({
        url: `/field`,
        params: { tableId: tableID, ...defaultQueryParams, ...params },
      }),
      providesTags: ['field'],
    }),

    loadFieldsInfo: builder.mutation({
      method: 'GET',
      query: ({ tableID, params }) => ({
        url: `/field`,
        params: { tableId: tableID, ...defaultQueryParams, ...params },
      }),
      providesTags: ['field'],
    }),

    loadFieldInfo: builder.mutation({
      method: 'GET',
      query: ({ fieldID }) => ({
        url: `/field/${fieldID}`,
      }),
      providesTags: ['field'],
    }),

    createField: builder.mutation({
      query: ({ tableID, body }) => ({
        url: '/field',
        method: 'POST',
        body: { tableId: tableID, ...body },
      }),
      invalidatesTags: ['field', 'data'],
    }),

    updateField: builder.mutation({
      query: ({ fieldID, body }) => ({
        url: `/field/${fieldID}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['field', 'data', 'view'],
    }),

    deleteField: builder.mutation({
      query: ({ fieldID }) => ({
        url: `/field/${fieldID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['field', 'data', 'view'],
    }),

    //////////////////////// Indexes ////////////////////////
    loadIndexes: builder.query({
      query: ({ tableID, params }) => ({
        url: `/index`,
        params: { tableId: tableID, ...defaultQueryParams, ...params },
      }),
      providesTags: ['index'],
    }),

    createIndex: builder.mutation({
      query: ({ tableID, body }) => ({
        url: '/index',
        method: 'POST',
        body: { tableId: tableID, ...body },
      }),
      invalidatesTags: ['index'],
    }),

    deleteIndex: builder.mutation({
      query: ({ indexID }) => ({
        url: `/index/${indexID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['index'],
    }),

    //////////////////////// Views ////////////////////////
    loadViews: builder.query({
      query: ({ tableID, params }) => ({
        url: '/view',
        params: { tableId: tableID, ...defaultQueryParams, ...params },
      }),
      providesTags: ['views'],
    }),

    loadViewInfo: builder.query({
      method: 'GET',
      query: ({ viewID }) => ({
        url: `/view/${viewID}`,
      }),
      providesTags: ['view'],
    }),

    createView: builder.mutation({
      query: ({ tableID, body }) => ({
        url: '/view',
        method: 'POST',
        body: { tableId: tableID, ...body },
      }),
      invalidatesTags: ['views'],
    }),

    updateView: builder.mutation({
      query: ({ viewID, body }) => ({
        url: `/view/${viewID}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['view'],
    }),

    silentUpdateView: builder.mutation({
      query: ({ viewID, body }) => ({
        url: `/view/${viewID}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: [],
    }),

    deleteView: builder.mutation({
      query: ({ viewID }) => ({
        url: `/view/${viewID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['views'],
    }),

    //////////////////////// Data ////////////////////////
    loadRecordsData: builder.query({
      query: ({ groupID, databaseID, tableID, body }) => ({
        url: `/group/${groupID}/database/${databaseID}/table/${tableID}/list`,
        method: 'POST',
        body: body,
      }),
      providesTags: ['data'],
    }),
    createDataRecord: builder.mutation({
      query: ({ groupID, databaseID, tableID, body }) => ({
        url: `/group/${groupID}/database/${databaseID}/table/${tableID}/data`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['data'],
    }),
    updateDataRecord: builder.mutation({
      query: ({ groupID, databaseID, tableID, rowID, body }) => ({
        url: `/group/${groupID}/database/${databaseID}/table/${tableID}/data/${rowID}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['data'],
    }),
    deleteDataRecord: builder.mutation({
      query: ({ groupID, databaseID, tableID, rowID }) => ({
        url: `/group/${groupID}/database/${databaseID}/table/${tableID}/data/${rowID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['data'],
    }),

    //////////////////////// Kanban ////////////////////////
    loadKanbanColumns: builder.query({
      query: ({ groupID, databaseID, tableID }) => ({
        url: `/group/${groupID}/database/${databaseID}/table/${tableID}/list`,
        method: 'POST',
        body: {
          fields: ['*'],
          paginate: {
            perPage: 999,
            currentPage: 1
          }
        },
      }),
      providesTags: ['data'],
    }),
  }),
})

export const {
  ///// Groups /////
  useLoadGroupsQuery,
  useLoadGroupInfoQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  ///// Databases /////
  useLoadDatabasesQuery,
  useLoadDatabaseInfoQuery,
  useCreateDatabaseMutation,
  useUpdateDatabaseMutation,
  useDeleteDatabaseMutation,
  ///// Tables /////
  useLoadTablesQuery,
  useLoadTableInfoMutation,
  useCreateTableMutation,
  useUpdateTableMutation,
  useDeleteTableMutation,
  ///// Fields /////
  useLoadFieldsQuery,
  useLoadFieldsInfoMutation,
  useLoadFieldInfoMutation,
  useCreateFieldMutation,
  useUpdateFieldMutation,
  useDeleteFieldMutation,
  ///// Indexes /////
  useLoadIndexesQuery,
  useCreateIndexMutation,
  useDeleteIndexMutation,
  ///// Views /////
  useLoadViewsQuery,
  useLoadViewInfoQuery,
  useCreateViewMutation,
  useUpdateViewMutation,
  useSilentUpdateViewMutation,
  useDeleteViewMutation,
  ///// Data /////
  useLoadRecordsDataQuery,
  useCreateDataRecordMutation,
  useUpdateDataRecordMutation,
  useDeleteDataRecordMutation,
  ///// Kanban /////
  useLoadKanbanColumnsQuery,
} = apiServiceSlice
