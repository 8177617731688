import styles from './Notification.module.css'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  destroyNotification,
  notificationsSelector,
} from '../../../store/slices/notificationsSlice'

const Notification = ({ notificationData }) => {
  const { id, data } = notificationData
  const autohideDuration = useSelector(notificationsSelector.getDestroyDuration)
  const dispatch = useDispatch()

  useEffect(() => {
    const hideNotification = setTimeout(() => dispatch(destroyNotification(id)), autohideDuration)
    return () => clearTimeout(hideNotification)
  }, [notificationData, autohideDuration])
  return (
    <div className={styles.wrapper}>
      <div className={[styles.content, styles[data?.type] ?? ''].join(' ')}>{data?.message}</div>
    </div>
  )
}

export default Notification
