import EllipsisLine from '../text/EllipsisLine'
import styles from './LabeledControl.module.css'

const LabeledControl = ({ label, children }) => {
  return label ? (
    <label className={styles.label}>
      <div className={styles.label_text}>
        <EllipsisLine>{label}</EllipsisLine>
      </div>
      {children}
    </label>
  ) : (
    children
  )
}

export default LabeledControl
