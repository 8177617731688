import styles from './NotificationsWrapper.module.css'

const NotificationsWrapper = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default NotificationsWrapper
