import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAccessToken } from '../../store/slices/authSlice'

const loginUrl = `${
  process.env.REACT_APP_KEYCLOAK_URL
}/realms/ucp/protocol/openid-connect/auth?client_id=base&redirect_uri=${
  window.location.origin + window.location.pathname
}&response_mode=fragment&response_type=token&scope=openid`

const logoutUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/ucp/protocol/openid-connect/logout?client_id=base&post_logout_redirect_uri=${window.location.origin}`

const useAuth = () => {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAuthorized) {
      if (!window.location.hash.includes('access_token')) {
        getLocalToken()
      } else {
        saveLocalToken()
      }
    }
  }, [isAuthorized])

  function getLocalToken() {
    const localAccessToken = localStorage.getItem('accessToken')
    if (localAccessToken) {
      const lastLocation = localStorage.getItem('lastLocation')
      if (lastLocation) {
        localStorage.removeItem('lastLocation')
        window.location.href = lastLocation
      }
      dispatch(setAccessToken(localAccessToken))
      setIsAuthorized(true)
    } else {
      localStorage.setItem(
        'lastLocation',
        window.location.origin + window.location.pathname
      )
      window.location.href = loginUrl
    }
  }

  function saveLocalToken() {
    const searchParams = new URLSearchParams(window.location.hash.substring(1))
    const accessToken = searchParams.get('access_token')
    if (!accessToken) return
    const lastLocation = localStorage.getItem('lastLocation')
    localStorage.setItem('accessToken', accessToken)
    if (lastLocation) {
      localStorage.removeItem('lastLocation')
      window.location.href = lastLocation
    }
    dispatch(setAccessToken(accessToken))
    setIsAuthorized(true)
  }

  function updateToken() {
    localStorage.removeItem('accessToken')
    localStorage.setItem(
      'lastLocation',
      window.location.origin + window.location.pathname
    )
    setIsAuthorized(false)
  }

  function clearSessionData() {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const cookieName = eqPos > -1 ? cookie.substring(0, eqPos) : cookie
      document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
    localStorage.removeItem('accessToken')
    localStorage.removeItem('lastLocation')
  }

  function logOut() {
    clearSessionData()
    window.location.href = logoutUrl
  }

  return { isAuthorized, updateToken, logOut }
}

export default useAuth
