import styles from './TableData.module.css'

const TableData = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default TableData
