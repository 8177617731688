import styles from './AddFilterCard.module.scss'

import { useLayoutEffect, useState } from 'react'

import fieldTypesFilterOps from '../../constants/fieldTypesFilterOps.json'
import filterOpsParams from '../../constants/filterOpsParams.json'

import { FaCheck } from 'react-icons/fa6'
import { MdClose } from 'react-icons/md'
import TypedInput from '../controls/TypedInput'
import TransparentButton from '../controls/TransparentButton'
import CustomInput from '../../custom-components/CustomInput'

const AddFilterCard = ({
  fieldsData = [],
  displaySettings = {},
  onApply = () => null,
  onClose = () => null,
}) => {
  const [selectedField, setSelectedField] = useState(undefined)
  const [selectedOpsValue, setSelectedOpsValue] = useState(undefined)
  const [enteredFilterValue, setEnteredFilterValue] = useState(undefined)

  useLayoutEffect(() => {
    if (!fieldsData || !fieldsData?.length) return
    setSelectedField(fieldsData[0])
  }, [fieldsData])

  useLayoutEffect(() => {
    if (!selectedField) return
    if (!fieldTypesFilterOps[selectedField?.type]?.includes(selectedOpsValue)) {
      setSelectedOpsValue(fieldTypesFilterOps[selectedField?.type][0])
    }
  }, [selectedField])

  return (
    <div className={styles.wrapper}>
      {fieldsData?.length ? (
        <>
          <select
            className={styles.params_select}
            value={selectedField?.systemName}
            onChange={(event) =>
              setSelectedField(
                fieldsData?.find((fieldData) => fieldData?.systemName === event.target.value)
              )
            }
          >
            {fieldsData?.map((fieldData) => (
              <option key={fieldData?.systemName} value={fieldData?.systemName}>
                {displaySettings[fieldData?.systemName]?.displayName ?? fieldData?.name}
              </option>
            ))}
          </select>
          <select
            className={styles.params_select}
            value={selectedOpsValue}
            onChange={(event) => setSelectedOpsValue(event.target.value)}
          >
            {fieldTypesFilterOps[selectedField?.type]?.map((opsValue) => (
              <option key={opsValue} value={opsValue}>
                {filterOpsParams[opsValue]?.name}
              </option>
            ))}
          </select>
          {filterOpsParams[selectedOpsValue]?.filterValueIsRequired &&
            (selectedField?.relationFieldId ? (
              <CustomInput
                key={selectedField?.id}
                fieldData={selectedField}
                fieldDisplaySettings={displaySettings[selectedField?.systemName]}
                onChange={setEnteredFilterValue}
              />
            ) : (
              <TypedInput
                key={selectedField?.id}
                type={selectedField?.type}
                value={enteredFilterValue}
                onChange={setEnteredFilterValue}
              />
            ))}
          <TransparentButton
            width="2rem"
            height="2rem"
            onClick={() => onApply(selectedField?.systemName, selectedOpsValue, enteredFilterValue)}
          >
            <FaCheck size={'1.8rem'} />
          </TransparentButton>
          <TransparentButton width="2rem" height="2rem" onClick={onClose}>
            <MdClose size={'2rem'} />
          </TransparentButton>
        </>
      ) : null}
    </div>
  )
}

export default AddFilterCard
