//TO DELETE

import { useState } from 'react'
import { objectDeepClone } from '../utils/objectDeepClone'

const useCombineView = () => {
  const [combinedViewJoins, setCombinedViewJoins] = useState([])
  const [combinedViewFields, setCombinedViewFields] = useState([])
  const [
    combinedViewFieldsDisplaySettings,
    setCombinedViewFieldsDisplaySettings,
  ] = useState({})

  // Добавление поля во View
  function applyViewField(fieldData) {
    if (!(fieldData && fieldData?.systemName)) return

    const viewFields = objectDeepClone(combinedViewFields)
    const appliedFieldsDisplaySettings = objectDeepClone(
      combinedViewFieldsDisplaySettings
    )

    if (!viewFields.length) {
      viewFields.push(fieldData)
      appliedFieldsDisplaySettings[fieldData?.systemName] =
        fieldData?.displaySettings
    } else {
      const appliedFields = viewFields.map(
        (appliedFieldData) => appliedFieldData?.systemName
      )

      if (appliedFields.includes(fieldData?.systemName)) return
      viewFields.push(fieldData)
      appliedFieldsDisplaySettings[fieldData?.systemName] =
        fieldData?.displaySettings
    }

    setCombinedViewFieldsDisplaySettings(appliedFieldsDisplaySettings)
    setCombinedViewFields(viewFields)
  }

  // Добавление массива полей во View
  function applyMultipleViewFields(fieldsArray) {
    if (!(fieldsArray && fieldsArray?.length)) return

    const viewFields = objectDeepClone(combinedViewFields)

    const appliedFields = viewFields.map(
      (appliedFieldData) => appliedFieldData?.systemName
    )

    const appliedFieldsDisplaySettings = objectDeepClone(
      combinedViewFieldsDisplaySettings
    )

    for (
      let fieldDataIndex = 0;
      fieldDataIndex < fieldsArray.length;
      fieldDataIndex++
    ) {
      if (!appliedFields.includes(fieldsArray[fieldDataIndex]?.systemName)) {
        viewFields.push(fieldsArray[fieldDataIndex])
        appliedFieldsDisplaySettings[fieldsArray[fieldDataIndex]?.systemName] =
          fieldsArray[fieldDataIndex]?.displaySettings
      }
    }

    setCombinedViewFieldsDisplaySettings(appliedFieldsDisplaySettings)
    setCombinedViewFields(viewFields)
  }

  // Удаление поля из итогового View
  function clearViewField(fieldData) {
    if (!(fieldData && fieldData?.systemName)) return

    const appliedFields = combinedViewFields.filter(
      (appliedFieldData) =>
        appliedFieldData?.systemName !== fieldData?.systemName
    )

    const appliedFieldsDisplaySettings = objectDeepClone(
      combinedViewFieldsDisplaySettings
    )

    delete appliedFieldsDisplaySettings[fieldData?.systemName]

    setCombinedViewFieldsDisplaySettings(appliedFieldsDisplaySettings)
    setCombinedViewFields(appliedFields)
  }

  // Изменение displaySettings для отдельного поля
  function applyViewFieldDisplaySettings(fieldKey, displaySettingsData) {
    if (!(fieldKey && displaySettingsData)) return

    const appliedFieldsDisplaySettings = objectDeepClone(
      combinedViewFieldsDisplaySettings
    )

    appliedFieldsDisplaySettings[fieldKey] = displaySettingsData

    setCombinedViewFieldsDisplaySettings(appliedFieldsDisplaySettings)
  }

  // Добавление параметров джойна во View
  function applyJoin(
    leftTableLinkedField,
    rightTableData,
    rightTableLinkedField,
    rightTableFieldsData,
    joinType
  ) {
    if (
      !leftTableLinkedField ||
      !rightTableData ||
      !rightTableLinkedField ||
      !rightTableFieldsData
    )
      return

    if (leftTableLinkedField?.tableId === rightTableData?.id) return
    if (checkIsJoinApplied(rightTableData?.id)) return

    const joinData = {
      leftTableLinkedField: objectDeepClone(leftTableLinkedField),
      rightTableData: objectDeepClone(rightTableData),
      rightTableLinkedField: objectDeepClone(rightTableLinkedField),
      rightTableFieldsData: objectDeepClone(rightTableFieldsData),
      joinType: joinType ? joinType : 'left',
    }

    setCombinedViewJoins([...combinedViewJoins, joinData])
  }

  // Генерация объекта для сохранения/изменения View
  function prepareViewRequestBody(viewName) {
    const viewRequestBody = { name: viewName, displayOptions: {} }

    viewRequestBody['columns'] = combinedViewFields.map(
      (fieldData) => fieldData?.systemName
    )

    if (combinedViewJoins.length) {
      const requestBodyJoins = combinedViewJoins.map((viewJoinData) => {
        const joinData = {
          mainTableId: viewJoinData?.leftTableLinkedField?.tableId,
          mainTableKey: viewJoinData?.leftTableLinkedField?.systemName,
          joinTableId: viewJoinData?.rightTableData?.id,
          joinTableKey: viewJoinData?.rightTableLinkedField?.systemName,
          type: viewJoinData?.joinType,
        }

        return joinData
      })
      viewRequestBody['joins'] = requestBodyJoins
    }

    viewRequestBody['displaySettings'] = objectDeepClone(
      combinedViewFieldsDisplaySettings
    )

    return viewRequestBody
  }

  function checkIsJoinApplied(rightTableID) {
    if (combinedViewJoins.length === 0) return false

    for (let joinIndex = 0; joinIndex < combinedViewJoins.length; joinIndex++) {
      if (combinedViewJoins[joinIndex]?.rightTableData?.id === rightTableID)
        return true
    }

    return false
  }

  function resetCombinedView() {
    setCombinedViewJoins([])
    setCombinedViewFields([])
    setCombinedViewFieldsDisplaySettings({})
  }

  return {
    combinedViewFields,
    combinedViewJoins,
    applyViewField,
    applyMultipleViewFields,
    clearViewField,
    applyViewFieldDisplaySettings,
    applyJoin,
    resetCombinedView,
    prepareViewRequestBody,
  }
}

export default useCombineView
