import styles from './CreateEntitiCard.module.css'
import { FaPlus } from 'react-icons/fa'

export const CreateEntitiCard = ({ onClick }) => {
  return (
    <button className={styles.card} onClick={onClick}>
      <div className={styles.icon}>
        <FaPlus size={'2rem'} />
      </div>
    </button>
  )
}

export default CreateEntitiCard
