import { useLayoutEffect, useState } from 'react'
import FormBasis, { ContentBlock } from './components/FormBasis'
import ControlledSelect from '../controls/ControlledSelect'
import {
  useCreateFieldMutation,
  useLoadFieldsQuery,
  useLoadTablesQuery,
} from '../../services/apiServiceSlice'
import EllipsisLine from '../text/EllipsisLine'
import Button from '../controls/Button'
import ControlledCheckbox from '../controls/ControlledCheckbox'
import LabeledControl from '../controls/LabeledControl'
import useTableFields from '../../hooks/useTableFields'

const AddViewFieldsForm = ({
  unappliedViewFields = [],
  availableViewFields = [],
  joinedTablesIDs = [],
  databaseID,
  tableID,
  onSubmit,
  onClose,
}) => {
  const [joinMode, setJoinMode] = useState(false)
  const [additionalJoinProps, setAdditionslJoinProps] = useState(false)
  const [pickedFields, setPickedFields] = useState([])
  const [asCustomField, setAsCustomField] = useState(false)
  const [rightTableData, setRightTableData] = useState(null)
  const [leftTableLinckedFieldData, setLeftTableLinckedFieldData] = useState(null)
  const [rightTableLinckedFieldData, setRightTableLinckedFieldData] = useState(null)
  const [joinType, setJoinType] = useState('left')

  const pickedFieldsChecker = pickedFields?.map((fieldData) => fieldData?.systemName)

  const { data: databaseTablesData } = useLoadTablesQuery(
    { databaseID: databaseID },
    { skip: !databaseID }
  )

  const { tableFields: rightTableFields, editableTableFields: rightTableEditableFields } =
    useTableFields(rightTableData?.id)

  const [createField] = useCreateFieldMutation()

  function pickedFieldsToggler(fieldData) {
    if (!fieldData) return
    if (!pickedFieldsChecker.includes(fieldData?.systemName)) {
      setPickedFields([...pickedFields, fieldData])
    } else {
      setPickedFields(
        pickedFields.filter((pickedField) => pickedField?.systemName !== fieldData?.systemName)
      )
    }
  }

  useLayoutEffect(() => {
    setPickedFields([])
    setAsCustomField(false)
  }, [rightTableData])

  useLayoutEffect(() => {
    if (!rightTableFields) return
    setRightTableLinckedFieldData(rightTableFields.find((fieldData) => fieldData?.isSystemField))
  }, [rightTableFields])

  useLayoutEffect(() => {
    setRightTableData(null)
    setAdditionslJoinProps(false)
    setRightTableData(null)
    setLeftTableLinckedFieldData(null)
    setRightTableLinckedFieldData(null)
    setJoinType('left')
    setPickedFields([])
    setAsCustomField(false)
  }, [joinMode])

  async function handleSubmit() {
    // добавление полей из главной таблицы (или из уже связанных таблиц)
    if (!joinMode) {
      onSubmit(pickedFields)
      onClose()
      return
    }

    //добавление полей из таблицы с новым джойном
    if (!rightTableData) {
      onClose()
      return
    }

    // базовые параметры тела джойна
    const joinBody = { rightTableID: rightTableData?.id, joinType: joinType }

    joinBody['rightTableKey'] = rightTableLinckedFieldData?.systemName

    let pickedCustomField = {}

    if (!leftTableLinckedFieldData) {
      joinBody['leftTableID'] = tableID
      // создание нового поля для связи в джойн
      const createFieldRequestBody = {
        name: rightTableData?.name + '_link',
        type: rightTableLinckedFieldData?.type,
        relationFieldId: rightTableLinckedFieldData?.id,
        unique: false,
        notNull: false,
      }
      const linkField = await createField({ tableID: tableID, body: createFieldRequestBody })
      if (!linkField?.error) {
        pickedCustomField = linkField?.data?.data
        joinBody['leftTableKey'] = linkField?.data?.data?.systemName
      } else {
        return
      }
    } else {
      joinBody['leftTableID'] = leftTableLinckedFieldData?.tableId
      joinBody['leftTableKey'] = leftTableLinckedFieldData?.systemName
    }
    onSubmit(asCustomField ? [pickedCustomField] : pickedFields, joinBody)
    onClose()
  }

  return (
    <FormBasis width={'35rem'} onSubmit={handleSubmit}>
      <ContentBlock direction="column">
        <EllipsisLine>Добавить поля во вью:</EllipsisLine>
      </ContentBlock>
      <ContentBlock height={'3rem'}>
        <Button disabled={!joinMode} onClick={() => setJoinMode(false)}>
          Из доступных полей
        </Button>
        <Button disabled={joinMode} onClick={() => setJoinMode(true)}>
          Из другой таблицы
        </Button>
      </ContentBlock>
      {!joinMode ? (
        <ContentBlock direction="column">
          {unappliedViewFields?.length > 1 ? (
            unappliedViewFields
              .filter((fieldData) => !fieldData?.isSystemField)
              .map((fieldData) => (
                <ControlledCheckbox
                  key={fieldData?.systemName}
                  checked={pickedFieldsChecker.includes(fieldData?.systemName)}
                  label={fieldData?.name}
                  onChange={() => pickedFieldsToggler(fieldData)}
                />
              ))
          ) : (
            <EllipsisLine>Все доступные поля задействованы во вью</EllipsisLine>
          )}
        </ContentBlock>
      ) : (
        <ContentBlock direction="column">
          <ContentBlock height={'3rem'}>
            <ControlledSelect
              placeholder="Выберите таблицу"
              value={rightTableData?.id}
              onChange={(tableID) =>
                setRightTableData(
                  databaseTablesData?.data?.find((tableData) => tableData?.id === tableID)
                )
              }
              options={databaseTablesData?.data?.filter(
                (tableData) => tableData?.id !== tableID && !joinedTablesIDs.includes(tableData?.id)
              )}
              optionDisplay={(tableData) => tableData?.name}
              optionIdentifier={(tableData) => tableData?.id}
            />
          </ContentBlock>
          {rightTableData && rightTableFields.length ? (
            <ContentBlock direction="column">
              <ControlledCheckbox
                label="Добавить как кастомный компонент"
                checked={asCustomField}
                onChange={setAsCustomField}
              />
              {rightTableFields
                ?.filter((fieldData) => !fieldData?.isSystemField)
                .map((fieldData) => (
                  <ControlledCheckbox
                    key={fieldData?.systemName}
                    disabled={asCustomField}
                    checked={
                      asCustomField ? false : pickedFieldsChecker.includes(fieldData?.systemName)
                    }
                    label={fieldData?.name}
                    onChange={() => pickedFieldsToggler(fieldData)}
                  />
                ))}
              <Button height="3rem" onClick={() => setAdditionslJoinProps(!additionalJoinProps)}>
                Дополнительные параметры джойна
              </Button>
              {additionalJoinProps && (
                <ContentBlock direction="column">
                  <LabeledControl label={'Поле левой таблицы'}>
                    <ControlledSelect
                      placeholder="автоматически создать новое поле"
                      height="3rem"
                      value={leftTableLinckedFieldData?.systemName}
                      options={availableViewFields}
                      optionDisplay={(fieldData) => `${fieldData?.name} (${fieldData?.type})`}
                      optionIdentifier={(fieldData) => fieldData?.systemName}
                      onChange={(fieldSystemName) =>
                        setLeftTableLinckedFieldData(
                          availableViewFields.find(
                            (fieldData) => fieldData?.systemName === fieldSystemName
                          ) ?? null
                        )
                      }
                    />
                  </LabeledControl>
                  <LabeledControl label={'Поле правой таблицы'}>
                    <ControlledSelect
                      height="3rem"
                      value={rightTableLinckedFieldData?.systemName}
                      options={rightTableFields}
                      optionDisplay={(fieldData) => `${fieldData?.name} (${fieldData?.type})`}
                      optionIdentifier={(fieldData) => fieldData?.systemName}
                      onChange={(fieldSystemName) =>
                        setRightTableLinckedFieldData(
                          rightTableFields.find(
                            (fieldData) => fieldData?.systemName === fieldSystemName
                          ) ?? null
                        )
                      }
                    />
                  </LabeledControl>
                </ContentBlock>
              )}
            </ContentBlock>
          ) : (
            'Нет доступных полей'
          )}
        </ContentBlock>
      )}
      <ContentBlock height={'3rem'}>
        <Button type="submit" disabled={!pickedFields?.length && !asCustomField}>
          Добавить поля во вью
        </Button>
        <Button onClick={onClose}>Отмена</Button>
      </ContentBlock>
    </FormBasis>
  )
}

export default AddViewFieldsForm
