import styles from './TableHeader.module.css'
import EllipsisLine from '../../text/EllipsisLine'

const TableHeader = ({
  children,
  basicControls,
  additionalControls,
  canStretch,
  width,
}) => {
  return (
    <div
      className={styles.wrapper}
      style={{
        flexGrow: canStretch ? '1' : '0',
        width: width ? `${width}rem` : '10rem',
      }}
    >
      {basicControls && basicControls}
      <div className={styles.content}>
        <EllipsisLine>{children}</EllipsisLine>
      </div>
      {additionalControls && additionalControls}
    </div>
  )
}

export default TableHeader
