import { useEffect } from 'react'

export function useClickOutside(
  componentRef,
  triggerCallback,
  trackClick = true
) {
  useEffect(() => {
    if (!trackClick) return
    function clickOutsideHandler(event) {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        triggerCallback()
      }
    }
    document.addEventListener('mousedown', clickOutsideHandler)
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [componentRef, triggerCallback, trackClick])
}
