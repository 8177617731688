import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  useLoadFieldInfoMutation,
  useLoadFieldsInfoMutation,
  useLoadViewInfoQuery,
  useUpdateViewMutation,
} from '../services/apiServiceSlice'
import useCombineFilters from './useCombineFilters'
import useCombineJoins from './useCombineJoins'

//TODO: переделать обновление вью на точечное (отдельно дисплейсеттингс, отдельно джойны и т.д.)

const useView = (viewID) => {
  const lastKnownViewID = useRef(viewID)
  const [requestedView, setRequestedView] = useState(null) //тело открытой в данный момент Вью
  const [availableViewFields, setAvailableViewFields] = useState([]) //все доступные для вью поля
  const lastSavedViewCache = useRef(null)

  const [viewFields, setViewFields] = useState([]) //поля для отображения в гриде
  const [viewDisplaySettings, setViewDisplaySettings] = useState({}) //дисплейСеттингс для отображения в гриде

  const [updateView] = useUpdateViewMutation()
  const [loadTableFields] = useLoadFieldsInfoMutation()
  const [loadFieldInfo] = useLoadFieldInfoMutation()

  //фильтры вью
  const {
    combinedFilters,
    applyFilter,
    applyViewFilters,
    clearFilter,
    clearFieldFilters,
    resetFilters,
  } = useCombineFilters(viewID)

  //джойны вью
  const { combinedJoins, applyJoin, applyViewJoins, clearJoin, resetJoins } =
    useCombineJoins(viewID)

  // Загрузка Вью
  const { data: viewData, isFetching: loadingViewData } = useLoadViewInfoQuery(
    { viewID: viewID },
    { skip: !viewID }
  )

  //обновление параметров вью
  async function autoUpdateViewOnChange(viewParamsCache) {
    if (viewParamsCache === lastSavedViewCache.current) return
    if (lastSavedViewCache.current === null) {
      lastSavedViewCache.current = viewParamsCache
      return
    }
    const viewBody = {
      columns: viewFields?.map((fieldData) => fieldData?.systemName),
      displaySettings: viewDisplaySettings,
      filters: combinedFilters?.length ? combinedFilters : null,
      joins: combinedJoins?.length ? combinedJoins : null,
    }

    const updateResult = await updateView({
      viewID: requestedView?.id,
      body: viewBody,
    })
    if (!updateResult?.error) {
      lastSavedViewCache.current = viewParamsCache
      fillAvailableViewFields()
    }
  }

  //функционал автосохранения вью при редактировании
  useLayoutEffect(() => {
    if (!viewID) return
    if (!requestedView) return
    if (!viewFields?.length) return
    if (!Object.keys(viewDisplaySettings).length) return

    const viewFieldsKeys = viewFields.map((fieldData) => fieldData?.systemName)

    let viewParamsCache = ''

    viewFieldsKeys.sort((a, b) => {
      if (a < b) {
        return -1
      } else {
        return 1
      }
    })

    viewFieldsKeys.forEach((fieldKey) => {
      viewParamsCache += fieldKey
      viewParamsCache += JSON.stringify(viewDisplaySettings[fieldKey])
    })
    if (combinedFilters.length) {
      const viewFilters = [...combinedFilters].sort((a, b) => {
        if (a.key + a.ops < b.key + b.ops) {
          return -1
        } else {
          return 1
        }
      })
      viewFilters.forEach((filterData) => {
        viewParamsCache += filterData.key
        viewParamsCache += filterData.ops
        if (filterData.val) {
          viewParamsCache += filterData.val
        }
      })
    }
    if (combinedJoins.length) {
      const viewJoins = [...combinedJoins].sort((a, b) => {
        if (a.mainTableKey + a.joinTableKey < b.mainTableKey + b.joinTableKey) {
          return -1
        } else {
          return 1
        }
      })
      viewJoins.forEach((joinData) => {
        viewParamsCache += joinData.mainTableKey
        viewParamsCache += joinData.joinTableKey
        viewParamsCache += joinData.type
      })
    }
    autoUpdateViewOnChange(viewParamsCache)

    // допилить кеширование и сделать отложенный автосейв
    // const deferedAutosave = setTimeout(() => autoUpdateViewOnChange(viewParamsCache), 1000)
    // return () => clearTimeout(deferedAutosave)
  }, [requestedView, viewDisplaySettings, combinedFilters, combinedJoins])

  //загрузка полей всех связанных со вью таблиц/восстановление джойнов для полей с relationFieldId
  async function fillAvailableViewFields() {
    let combinedViewFields = []
    const availableViewTables = []
    const viewJoins = requestedView?.joins ?? []

    const parentTableFields = await loadTableFields({ tableID: requestedView?.tableId })
    if (!parentTableFields?.error) {
      combinedViewFields = combinedViewFields.concat(parentTableFields?.data?.data)
    }

    const relatedFields = parentTableFields?.data?.data?.filter(fieldData => fieldData?.relationFieldId)
    const unjoinedRelatedFields = relatedFields?.filter(fieldData => !requestedView?.joins?.some(joinData => joinData?.mainTableKey === fieldData?.systemName))
    if (unjoinedRelatedFields?.length) {
      await Promise.all(unjoinedRelatedFields.map(async fieldData => {
        const relatedTableFieldData = await loadFieldInfo({ fieldID: fieldData?.relationFieldId })
        if (!relatedTableFieldData?.error) {
          const relatedTableField = relatedTableFieldData?.data?.data
          viewJoins.push({
            joinTableId: relatedTableField?.tableId,
            joinTableKey: relatedTableField?.isSystemField ? relatedTableField?.name : relatedTableField?.systemName,
            mainTableId: fieldData?.tableId,
            mainTableKey: fieldData?.systemName,
            type: 'left'
          })
        }
      }))
    }

    if (viewJoins?.length) {
      viewJoins.forEach(joinData => availableViewTables.push(joinData?.joinTableId))
    }

    await Promise.all(
      availableViewTables.map(async tableKey => {
        const joinedTableFields = await loadTableFields({ tableID: tableKey })
        if (!joinedTableFields?.error) {
          combinedViewFields = combinedViewFields.concat(joinedTableFields?.data?.data)
        }
      })
    )
    applyViewJoins(viewJoins)
    setAvailableViewFields(combinedViewFields)
  }

  //заполнение всех доступных полей вью
  useLayoutEffect(() => {
    if (!viewID) return
    if (!requestedView) return
    fillAvailableViewFields()
  }, [requestedView])

  //сохранение тела вью в локальном стейте
  useLayoutEffect(() => {
    if (viewData?.data?.id !== viewID) return
    setRequestedView(viewData?.data)
  }, [viewData?.data])

  //сортировка филдов и заполнение дисплейСеттингс
  function matchFieldsDisplaySettings(fieldsData, displaySettings) {
    const matchedDisplaySettings = {}

    const sortedViewFields = []
    const unsortedViewFields = []

    let lastEngagedOrder = 1

    fieldsData.forEach((fieldData) => {
      if (displaySettings[fieldData?.systemName]) {
        sortedViewFields.push(fieldData)
      } else {
        unsortedViewFields.push(fieldData)
      }
    })

    sortedViewFields.sort(
      (a, b) =>
        displaySettings[a?.systemName].order -
        displaySettings[b?.systemName].order
    )

    //заполнение дисплейСеттингс существующими, с переназначением ордера
    sortedViewFields.forEach((fieldData) => {
      matchedDisplaySettings[fieldData?.systemName] = {
        ...displaySettings[fieldData?.systemName],
        order: lastEngagedOrder,
      }
      lastEngagedOrder += 1
    })

    //заполнение отсуствующих дисплейСеттингс шаблонными
    unsortedViewFields.forEach((fieldData) => {
      matchedDisplaySettings[fieldData?.systemName] = {
        displayName: fieldData?.name,
        width: 20,
        fixed: false,
        order: lastEngagedOrder,
        data: {}
      }
      lastEngagedOrder += 1
    })

    const matchedFieldsData = sortedViewFields.concat(unsortedViewFields)

    return { matchedFieldsData, matchedDisplaySettings }
  }

  //заполнение параметров из загруженной вью
  useLayoutEffect(() => {
    if (!viewID) return
    if (!requestedView) return

    const { matchedFieldsData, matchedDisplaySettings } = matchFieldsDisplaySettings(requestedView?.fields, requestedView?.displaySettings)

    applyViewFilters(requestedView?.filters)
    applyViewJoins(requestedView?.joins)
    setViewFields(matchedFieldsData)
    setViewDisplaySettings(matchedDisplaySettings)
  }, [requestedView])

  //перемещение поля
  function reorderViewField(fieldData, destinationOrder) {
    const destinationIndex = destinationOrder - 1

    const reorderedViewFields = [...viewFields].filter(
      (viewFieldData) => viewFieldData?.systemName !== fieldData?.systemName
    )

    reorderedViewFields.splice(destinationIndex, 0, fieldData)

    const displaySettings = {}

    reorderedViewFields.forEach(
      (fieldData, index) =>
      (displaySettings[fieldData?.systemName] = {
        ...viewDisplaySettings[fieldData?.systemName],
        order: index + 1,
      })
    )

    setViewFields(reorderedViewFields)
    setViewDisplaySettings(displaySettings)
  }

  //удаление поля из вью
  function removeViewField(fieldData) {
    if (!fieldData) return
    const clearedViewFields = [...viewFields].filter(
      (viewFieldData) => viewFieldData?.systemName !== fieldData?.systemName
    )

    const clearedDisplaySettings = { ...viewDisplaySettings }

    delete clearedDisplaySettings[fieldData?.systemName]

    clearFieldFilters(fieldData?.systemName)
    setViewFields(clearedViewFields)
    setViewDisplaySettings(clearedDisplaySettings)
  }

  //изменение дисплейСеттингс для поля
  function updateFieldDisplaySettings(fieldData, displaySettingsData) {
    const displaySettings = { ...viewDisplaySettings }
    displaySettings[fieldData?.systemName] = {
      ...displaySettingsData
    }

    setViewDisplaySettings(displaySettings)
  }

  //добавление полей вью
  function applyViewFields(fieldsData) {
    if (!(fieldsData && fieldsData?.length)) return
    const { matchedFieldsData, matchedDisplaySettings } = matchFieldsDisplaySettings([...viewFields, ...fieldsData], viewDisplaySettings)
    setViewFields(matchedFieldsData)
    setViewDisplaySettings(matchedDisplaySettings)
  }

  //сброс параметров при смене вью
  if (viewID && lastKnownViewID.current !== viewID) {
    lastKnownViewID.current = viewID
    lastSavedViewCache.current = null
    setRequestedView(null)
    setViewFields([])
    setAvailableViewFields([])
    setViewDisplaySettings({})
  }

  //возвращение пустышек во избежание лагов при навигации между вью
  if (!viewID || lastKnownViewID.current !== viewID) {
    return {
      viewFields: undefined,
      availableViewFields: undefined,
      displaySettings: undefined,
      displayOptions: undefined,
      reorderViewField: () => null,
      updateFieldDisplaySettings: () => null,
      removeViewField: () => null,
      applyViewFields: () => null,
      viewFilters: undefined,
      applyFilter: () => null,
      clearFilter: () => null,
      viewJoins: undefined,
      applyViewJoin: () => null,
    }
  }

  return {
    viewFields,
    availableViewFields,
    displaySettings: viewDisplaySettings,
    displayOptions: requestedView?.displayOptions,
    reorderViewField,
    updateFieldDisplaySettings,
    removeViewField,
    applyViewFields,
    viewFilters: combinedFilters,
    applyFilter,
    clearFilter,
    viewJoins: combinedJoins,
    applyViewJoin: applyJoin,
  }
}

export default useView
