import SingleSelect from './selects/SingleSelect'

const CustomInput = ({ value, onChange, fieldData, fieldDisplaySettings }) => {
  switch (fieldDisplaySettings?.data?.component) {
    case 'sticker':
      return (
        <SingleSelect
          fieldData={fieldData}
          value={value}
          onChange={onChange}
          keyField={fieldDisplaySettings?.data['keyField']}
          displayField={fieldDisplaySettings?.data['stickerText']}
        />
      )
    case 'coloredSticker':
      return (
        <SingleSelect
          fieldData={fieldData}
          value={value}
          onChange={onChange}
          keyField={fieldDisplaySettings?.data['keyField']}
          displayField={fieldDisplaySettings?.data['stickerText']}
        />
      )
    case 'userAvatar':
      return (
        <SingleSelect
          fieldData={fieldData}
          value={value}
          onChange={onChange}
          keyField={fieldDisplaySettings?.data['keyField']}
          displayField={fieldDisplaySettings?.data['userName']}
        />
      )
    default:
      return <SingleSelect fieldData={fieldData} value={value} onChange={onChange} />
  }
}

export default CustomInput
