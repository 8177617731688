import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accessToken: null,
  accessTokenIsExpired: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setAccessTokenIsExpired: (state, action) => {
      state.accessTokenIsExpired = action.payload
    },
  },
})

export const authSelector = {
  getAccessToken: (state) => state.auth.accessToken,
  getAccessTokenIsExpired: (state) => state.auth.accessTokenIsExpired,
}

export const { setAccessToken, setAccessTokenIsExpired } = authSlice.actions

export default authSlice.reducer
