import { useParams } from 'react-router-dom'
import {
  useDeleteTableMutation,
  useDeleteViewMutation,
  useLoadViewsQuery,
  useUpdateTableMutation,
} from '../../../../services/apiServiceSlice'
import GroupedContent from './GroupedContent'
import NavigationEntitiSticker from './NavigationEntitiSticker'
import { useContext } from 'react'
import { NavigationContext } from './NavigationPanel'
import { FaTable } from 'react-icons/fa'
import { MdViewCarousel, MdEdit } from 'react-icons/md'
import { HiTrash } from 'react-icons/hi'
import TransparentButton from '../../../../components/controls/TransparentButton'
import useFixedModal from '../../../../features/modals/useFixedModal'
import CreateEditEntitiForm from '../../../../components/forms/CreateEditEntitiForm'
import ConfirmDialog from '../../../../components/gialogs/ConfirmDialog'

const TablesNavigator = ({ groupData, databaseData, tableData }) => {
  const { tableID, viewID } = useParams()

  const { openModal, closeModal } = useFixedModal()

  const { readOnly, branchDepth, onTableSelect, onViewSelect } =
    useContext(NavigationContext)

  const { data: viewsData } = useLoadViewsQuery({
    tableID: tableData?.id,
  })

  const [updateTable] = useUpdateTableMutation()
  const [deleteTable] = useDeleteTableMutation()

  // const [updateView] = useUpdateViewMutation()
  const [deleteView] = useDeleteViewMutation()

  async function updateTableHandler(inputsData) {
    const updateResult = await updateTable({
      tableID: tableData?.id,
      body: inputsData,
    })
    if (!updateResult?.error) {
      closeModal()
    }
  }

  async function deleteTableHandler() {
    const deleteResult = await deleteTable({
      tableID: tableData?.id,
    })
    if (!deleteResult?.error) {
      closeModal()
    }
  }

  async function deleteViewHandler(viewID) {
    const deleteResult = await deleteView({
      viewID: viewID,
    })
    if (!deleteResult?.error) {
      closeModal()
    }
  }

  return (
    <>
      <NavigationEntitiSticker
        icon={<FaTable size={'1.1rem'} style={{ flexShrink: 0 }} />}
        label={tableData?.name}
        onClick={() => onTableSelect(groupData, databaseData, tableData)}
        selected={tableData?.id === tableID}
        disabled={!onTableSelect}
        controls={
          !readOnly ? (
            <>
              <TransparentButton
                width="1.2rem"
                height="1.2rem"
                onClick={() =>
                  openModal(
                    <CreateEditEntitiForm
                      name={tableData?.name}
                      onSubmit={updateTableHandler}
                      onClose={closeModal}
                    />
                  )
                }
              >
                <MdEdit />
              </TransparentButton>
              <TransparentButton
                width="1.2rem"
                height="1.2rem"
                onClick={() =>
                  openModal(
                    <ConfirmDialog
                      title={'Delete table?'}
                      onConfirm={deleteTableHandler}
                      onCancel={closeModal}
                    />
                  )
                }
              >
                <HiTrash />
              </TransparentButton>
            </>
          ) : undefined
        }
      />
      {branchDepth !== 'table' && (
        <GroupedContent>
          {viewsData?.data?.map((viewData) => (
            <NavigationEntitiSticker
              icon={
                <MdViewCarousel size={'1.4rem'} style={{ flexShrink: 0 }} />
              }
              key={viewData?.id}
              label={viewData?.name}
              onClick={() =>
                onViewSelect(groupData, databaseData, tableData, viewData)
              }
              selected={viewData?.id === viewID}
              disabled={!onViewSelect}
              controls={
                !readOnly ? (
                  <TransparentButton
                    width="1.2rem"
                    height="1.2rem"
                    onClick={() =>
                      openModal(
                        <ConfirmDialog
                          title={'Delete view?'}
                          onConfirm={() => deleteViewHandler(viewData?.id)}
                          onCancel={closeModal}
                        />
                      )
                    }
                  >
                    <HiTrash />
                  </TransparentButton>
                ) : null
              }
            />
          ))}
        </GroupedContent>
      )}
    </>
  )
}

export default TablesNavigator
