function getMaxMonthDay(year, month) {
    if (Number(month) === 2) {
        if (Number(year) % 4 !== 0) {
            return 28
        } else {
            return 29
        }
    }
    if ([4, 6, 9, 11].includes(Number(month))) {
        return 30
    }
    return 31
}

export function validateDateValue(day, month, year) {
    let validatedY = String(year).replace(/[^\d]+/g, '')
    let validatedM = String(month).replace(/[^\d]+/g, '')
    let validatedD = String(day).replace(/[^\d]+/g, '')

    if (validatedM) {
        if (Number(validatedM) > 12) {
            validatedM = '12'
        }
    }
    if (validatedD) {
        const maxDayValue = getMaxMonthDay(validatedY, validatedM)
        if (Number(validatedD) > maxDayValue) {
            validatedD = String(maxDayValue)
        }
    }

    return {
        day: validatedD,
        month: validatedM,
        year: validatedY,
    }

}