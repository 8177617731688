import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  groupsData: [],
  databasesData: [],
  tablesData: [],
  fieldsData: [],
  viewsData: [],
}

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setGroupsData: (state, action) => {
      state.groupsData = action.payload
    },
    setDatabasesData: (state, action) => {
      state.databasesData = action.payload
    },
    setTablesData: (state, action) => {
      state.tablesData = action.payload
    },
    setFieldsData: (state, action) => {
      state.fieldsData = action.payload
    },
    setViewsData: (state, action) => {
      state.viewsData = action.payload
    },
  },
})

export const dataSelector = {
  getGroupsData: (state) => state.data.groupsData,
  getDatabasesData: (state) => state.data.databasesData,
  getTablesData: (state) => state.data.tablesData,
  getFieldsData: (state) => state.data.fieldsData,
  getViewsData: (state) => state.data.viewsData,
}

export const {
  setGroupsData,
  setDatabasesData,
  setTablesData,
  setFieldsData,
  setViewsData,
} = dataSlice.actions

export default dataSlice.reducer
