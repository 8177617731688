import TypedInput from '../controls/TypedInput'
import { useMemo, useState } from 'react'
import { useCreateDataRecordMutation } from '../../services/apiServiceSlice'
import FormBasis, { ContentBlock } from './components/FormBasis'
import LabeledControl from '../controls/LabeledControl'
import Button from '../controls/Button'
import CustomInput from '../../custom-components/CustomInput'

const CreateDataRecordForm = ({
  groupID,
  databaseID,
  tableID,
  onClose = () => null,
  fieldsData = [],
  displaySettings = {},
}) => {
  const [rowInputsData, setRowInputsData] = useState({})
  const [createDataRecord] = useCreateDataRecordMutation()

  async function createDataRecordHandler() {
    const createDataRecordResult = await createDataRecord({
      groupID: groupID,
      databaseID: databaseID,
      tableID: tableID,
      body: { data: [rowInputsData] },
    })
    if (!createDataRecordResult?.error) {
      onClose()
    }
  }

  const directWritingFields = useMemo(
    () => fieldsData.filter((fieldData) => fieldData?.tableId === tableID),
    [fieldsData]
  )

  return (
    <FormBasis width={'50rem'} onSubmit={createDataRecordHandler}>
      {directWritingFields?.length > 0 &&
        directWritingFields?.map((fieldData) => (
          <ContentBlock
            height={fieldData?.type?.includes('json') ? 'fit-content' : '5rem'}
            key={fieldData?.systemName}
          >
            <LabeledControl
              label={displaySettings[fieldData?.systemName]?.displayName ?? fieldData?.name}
            >
              {fieldData?.relationFieldId ? (
                <CustomInput
                  value={rowInputsData[fieldData?.systemName] ?? null}
                  onChange={(value) => {
                    setRowInputsData({
                      ...rowInputsData,
                      [fieldData?.systemName]: value,
                    })
                  }}
                  fieldData={fieldData}
                  fieldDisplaySettings={displaySettings[fieldData?.systemName]}
                />
              ) : (
                <TypedInput
                  type={fieldData?.type}
                  value={rowInputsData[fieldData?.systemName] ?? null}
                  onChange={(value) => {
                    setRowInputsData({
                      ...rowInputsData,
                      [fieldData?.systemName]: value,
                    })
                  }}
                />
              )}
            </LabeledControl>
          </ContentBlock>
        ))}
      <ContentBlock height={'3rem'}>
        <Button type="submit">Create</Button>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>
      </ContentBlock>
    </FormBasis>
  )
}

export default CreateDataRecordForm
