import styles from './ServicePanel.module.scss'

const ServicePanel = ({ mainSlotComponent, leftControls, rightControls }) => {
  return (
    <div className={styles.panel_wrapper}>
      {leftControls && <div className={styles.left_controls}>{leftControls}</div>}
      {mainSlotComponent && <div className={styles.main_slot}>{mainSlotComponent}</div>}
      {rightControls && <div className={styles.right_controls}>{rightControls}</div>}
    </div>
  )
}

export default ServicePanel
