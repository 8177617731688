export function validateFloat(value) {
  if (!value) return
  let isNegative = false
  let validatedValue = value

  if (validatedValue[0] === '-') {
    isNegative = true
  }

  validatedValue = validatedValue.replace(/[^\d\.]/g, '')

  if (
    validatedValue.length > 1 &&
    validatedValue[0] === '0' &&
    validatedValue[1] !== '.'
  ) {
    validatedValue = validatedValue.slice(1)
  }

  if (validatedValue.split('.').length > 2) {
    validatedValue =
      validatedValue.split('.')[0] +
      '.' +
      validatedValue.split('.').slice(1).join('')
  }

  if (isNegative) {
    validatedValue = '-' + validatedValue
  }

  return String(validatedValue)
}

export function validateInteger(value) {
  if (!value) return
  let isNegative = false
  let validatedValue = value

  if (validatedValue[0] === '-') {
    isNegative = true
  }

  validatedValue = validatedValue.replace(/[^\d]/g, '')

  if (validatedValue.length > 1 && validatedValue[0] === '0') {
    validatedValue = validatedValue.slice(1)
  }

  if (isNegative) {
    if (validatedValue > 2147483648) {
      validatedValue = 2147483648
    }
    validatedValue = '-' + validatedValue
  } else {
    if (validatedValue > 2147483647) {
      validatedValue = 2147483647
    }
  }

  return Number(validatedValue)
}
