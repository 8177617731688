import { useNavigate, useParams } from 'react-router-dom'
import EntitiCard from '../entities/EntitiCard'
import { BiTable } from 'react-icons/bi'
import {
  useDeleteTableMutation,
  useUpdateTableMutation,
} from '../../services/apiServiceSlice'
import { useDispatch } from 'react-redux'
import { setNavigatedTable } from '../../store/slices/workspaceSlice'
import CreateEditEntitiForm from '../forms/CreateEditEntitiForm'
import useFixedModal from '../../features/modals/useFixedModal'
import ConfirmDialog from '../gialogs/ConfirmDialog'

const TableCard = ({ tableData }) => {
  const { groupID, databaseID } = useParams()

  const navigateTo = useNavigate()
  const dispatch = useDispatch()
  const { openModal, closeModal } = useFixedModal()
  const [deleteTable] = useDeleteTableMutation()
  const [updateTable] = useUpdateTableMutation()

  async function updateTableHandler(inputsData) {
    const updateResult = await updateTable({
      groupID: groupID,
      databaseID: databaseID,
      tableID: tableData?.id,
      body: inputsData,
    })
    if (!updateResult?.error) {
      closeModal()
    }
  }

  function onEditHandler() {
    openModal(
      <CreateEditEntitiForm
        name={tableData?.name}
        onSubmit={updateTableHandler}
        onClose={closeModal}
      />
    )
  }

  function onRemoveHandler() {
    openModal(
      <ConfirmDialog
        title={'Remove this table?'}
        onConfirm={async () => {
          await deleteTable({
            groupID: groupID,
            databaseID: databaseID,
            tableID: tableData?.id,
          })
          closeModal()
        }}
        onCancel={closeModal}
      />
    )
  }

  function selectTable() {
    dispatch(setNavigatedTable(tableData))
    navigateTo(`table/${tableData?.id}`)
  }

  return (
    <EntitiCard
      icon={<BiTable size={'1.3rem'} />}
      title={tableData?.name}
      onClick={selectTable}
      onRemove={onRemoveHandler}
      onEdit={onEditHandler}
    />
  )
}

export default TableCard
