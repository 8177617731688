import styles from './DataDisplayLayout.module.css'
import NavigationPanel from '../screens/usage/components/navigation-panel/NavigationPanel'
import DataDisplay from '../screens/usage/components/DataDisplay'
// import ViewDataDisplay from '../screens/usage/components/ViewDataDisplay'
import { useNavigate, useParams } from 'react-router-dom'
import ControlledCheckbox from '../components/controls/ControlledCheckbox'
import { useState } from 'react'

const DataDisplayLayout = () => {
  const [adminMode, setAdminMode] = useState(true)
  const { groupID, databaseID, tableID, viewID } = useParams()
  const navigateTo = useNavigate()

  function navigateToTable(groupData, databaseData, tableData) {
    if (!(groupData && databaseData && tableData)) return
    navigateTo(
      `/${groupData?.id}/database/${databaseData?.id}/table/${tableData?.id}`
    )
  }

  function navigateToView(groupData, databaseData, tableData, viewData) {
    if (!(groupData && databaseData && tableData && viewData)) return
    navigateTo(
      `/${groupData?.id}/database/${databaseData?.id}/table/${tableData?.id}/view/${viewData?.id}`
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.side_panel}>
          <ControlledCheckbox
            label={'Admin mode'}
            checked={adminMode}
            onChange={setAdminMode}
          />
          <NavigationPanel
            readOnly={!adminMode}
            onTableSelect={navigateToTable}
            onViewSelect={navigateToView}
          />
        </div>
        {tableID ? <DataDisplay /> : null}
      </div>
    </div>
  )
}

export default DataDisplayLayout
