import styles from './UsageScreen.module.css'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentScreen } from '../../store/slices/workspaceSlice'
import { Route, Routes } from 'react-router-dom'
import DataDisplayLayout from '../../layouts/DataDisplayLayout'

const UsageScreen = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentScreen('usage'))
    return () => dispatch(setCurrentScreen(undefined))
  }, [])
  return (
    <div className={styles.content}>
      <Routes>
        <Route path="/*" element={<DataDisplayLayout />} />
        <Route path=":groupID/*" element={<DataDisplayLayout />} />
        <Route
          path=":groupID/database/:databaseID/*"
          element={<DataDisplayLayout />}
        />
        <Route
          path=":groupID/database/:databaseID/table/:tableID/*"
          element={<DataDisplayLayout />}
        >
          <Route path="view/:viewID/*" element={<DataDisplayLayout />} />
        </Route>
      </Routes>
    </div>
  )
}

export default UsageScreen
