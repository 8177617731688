import EllipsisLine from '../../components/text/EllipsisLine'
import styles from './ColoredSticker.module.css'

const ColoredSticker = ({ stickerText, stickerColor }) => {
  return (
    <div className={styles.container} style={{ backgroundColor: stickerColor }}>
      <EllipsisLine>{stickerText}</EllipsisLine>
    </div>
  )
}

export default ColoredSticker
