import styles from './TableRow.module.css'

const TableRow = ({ children, onClick, controlsPanel }) => {
  return (
    <div className={styles.content} onClick={onClick}>
      {children}
      {controlsPanel && (
        <div className={styles.controls_panel}>{controlsPanel}</div>
      )}
    </div>
  )
}

export default TableRow
