import styles from './FormBasis.module.css'

export const ContentBlock = ({
  children,
  width,
  height,
  minHeight,
  direction = 'row',
  flexShrink = '1',
}) => {
  return (
    <div
      className={styles.content_block}
      style={{
        width: width ? width : '100%',
        height: height ? height : '100%',
        minHeight: minHeight ? minHeight : '1rem',
        flexDirection: direction,
        flexShrink: flexShrink,
      }}
    >
      {children}
    </div>
  )
}

const FormBasis = ({ children, width, onSubmit = () => null }) => {
  return (
    <div className={styles.wrapper}>
      <form
        className={styles.content}
        style={{ width: width ? width : '100%' }}
        onSubmit={(event) => {
          event.preventDefault()
          onSubmit()
        }}
      >
        {children}
      </form>
    </div>
  )
}

export default FormBasis
