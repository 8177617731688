import { useClickOutside } from '../../hooks/useClickOutside'
import styles from './InlinePopupMenu.module.css'
import { useRef, useState } from 'react'

const InlinePopupMenu = ({ size = '100%', icon, menuItems }) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const popupContainerRef = useRef(null)

  useClickOutside(
    popupContainerRef,
    () => {
      if (menuOpened) setMenuOpened(false)
    },
    menuOpened
  )

  return (
    <div ref={popupContainerRef} className={styles.popup_wrapper}>
      <button
        className={styles.controller_button}
        style={{ width: size, height: size }}
        onClick={() => setMenuOpened(!menuOpened)}
      >
        {icon ? icon : '...'}
      </button>
      {menuOpened && (
        <div
          className={styles.menu_items_container}
          style={{
            height: size,
            top: `calc(50% - ${size} / 2)`,
            left: `calc(${menuItems?.length} * ${size} * -1 + (100% - ${size})/2)`,
          }}
        >
          {menuItems.map((menuItem) => (
            <div className={styles.menu_item} style={{ width: size }}>
              {menuItem}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default InlinePopupMenu
