import { useSelector } from 'react-redux'
import { notificationsSelector } from '../../store/slices/notificationsSlice'
import Notification from './components/Notification'
import useRoots from '../../hooks/useRoots'
import { createPortal } from 'react-dom'
import NotificationsWrapper from './components/NotificationsWrapper'

const NotificationsProvider = ({ children }) => {
  const { notificationsRoot } = useRoots()
  const userNotifications = useSelector(notificationsSelector.getNotifications)

  return (
    <>
      {userNotifications.length > 0 &&
        createPortal(
          <NotificationsWrapper>
            {userNotifications.map((notificationData) => (
              <Notification key={notificationData?.id} notificationData={notificationData} />
            ))}
          </NotificationsWrapper>,
          notificationsRoot
        )}
      {children}
    </>
  )
}

export default NotificationsProvider
