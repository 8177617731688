import styles from './SelectRightTableForm.module.css'
import FormBasis from './components/FormBasis'
import { useLayoutEffect, useState } from 'react'
import {
  useLoadDatabasesQuery,
  useLoadFieldsQuery,
  useLoadGroupsQuery,
  useLoadTablesQuery,
} from '../../services/apiServiceSlice'

const SelectRightTableForm = ({ leftTableFieldsData, onApply, onClose }) => {
  const [selectedGroup, setSelectedGroup] = useState(undefined)
  const [selectedDatabase, setSelectedDatabase] = useState(undefined)
  const [selectedTable, setSelectedTable] = useState(undefined)
  const [rightLinkedField, setRightLinkedField] = useState(undefined)
  const [leftLinkedField, setLeftLinkedField] = useState(undefined)

  const groupID = selectedGroup?.id
  const databaseID = selectedDatabase?.id
  const tableID = selectedTable?.id

  useLayoutEffect(() => {
    setSelectedDatabase(null)
    setSelectedTable(null)
    setRightLinkedField(null)
  }, [selectedGroup])

  useLayoutEffect(() => {
    setSelectedTable(null)
    setRightLinkedField(null)
  }, [selectedDatabase])

  useLayoutEffect(() => {
    setRightLinkedField(null)
  }, [selectedTable])

  const { data: groupsData, isFetching: loadingGroups } = useLoadGroupsQuery()
  const { data: databasesData, isFetching: loadingDatabases } =
    useLoadDatabasesQuery({ groupID: groupID }, { skip: !groupID })
  const { data: tablesData, isFetching: loadingTables } = useLoadTablesQuery(
    { groupID: groupID, databaseID: databaseID },
    { skip: !(groupID && databaseID) }
  )
  const { data: fieldsData, isFetching: loadingFields } = useLoadFieldsQuery(
    { groupID: groupID, databaseID: databaseID, tableID: tableID },
    { skip: !(groupID && databaseID && tableID) }
  )

  return (
    <FormBasis>
      <div className={styles.content}>
        <div>
          {selectedGroup && (
            <span
              onClick={() => {
                setSelectedDatabase(undefined)
              }}
            >
              {selectedGroup?.name}
            </span>
          )}
          {selectedDatabase && <span>{selectedDatabase?.name}</span>}
          {selectedTable && <span>{selectedTable?.name}</span>}
        </div>
        {!selectedGroup && (
          <div className={styles.entities_column}>
            <h1>Group</h1>
            {groupsData?.data?.map((groupData) => (
              <button
                key={groupData?.id}
                onClick={() => setSelectedGroup(groupData)}
              >
                {groupData?.name}
              </button>
            ))}
          </div>
        )}
        {selectedGroup && !selectedDatabase && (
          <div className={styles.entities_column}>
            <h1>Database</h1>
            {databasesData?.data?.map((databaseData) => (
              <button
                key={databaseData?.id}
                onClick={() => setSelectedDatabase(databaseData)}
              >
                {databaseData?.name}
              </button>
            ))}
          </div>
        )}
        {selectedDatabase && !selectedTable && (
          <div className={styles.entities_column}>
            <h1>Table</h1>
            {tablesData?.data?.map((tableData) => (
              <button
                key={tableData?.id}
                onClick={() => setSelectedTable(tableData)}
              >
                {tableData?.name}
              </button>
            ))}
          </div>
        )}
        {selectedTable && !rightLinkedField && (
          <div className={styles.entities_column}>
            <h1>Fields</h1>
            {fieldsData?.data?.map((fieldData) => (
              <button
                key={fieldData?.id}
                onClick={() => setRightLinkedField(fieldData)}
              >
                {fieldData?.name}
              </button>
            ))}
          </div>
        )}
        {rightLinkedField && (
          <select
            value={leftLinkedField?.id}
            onChange={(event) =>
              setLeftLinkedField(
                leftTableFieldsData.find(
                  (fieldData) => fieldData?.id === event.target.value
                )
              )
            }
          >
            <option value={undefined}>Select key...</option>
            {leftTableFieldsData.map((leftTableFieldData) => (
              <option
                key={leftTableFieldData?.id}
                value={leftTableFieldData?.id}
              >
                {leftTableFieldData?.name}
              </option>
            ))}
          </select>
        )}
        <button
          disabled={!(selectedTable && rightLinkedField && leftLinkedField)}
          onClick={() => {
            onApply({
              rightTableData: selectedTable,
              rightTableFieldsData: fieldsData?.data,
              rightTableLinkedField: rightLinkedField,
              leftTableLinkedField: leftLinkedField,
            })
            onClose()
          }}
        >
          Apply join
        </button>
      </div>
    </FormBasis>
  )
}

export default SelectRightTableForm
