import styles from './JSONTextArea.module.css'

import { useEffect, useRef, useState } from 'react'
import TransparentButton from './TransparentButton'
import { FaRegFolderOpen } from 'react-icons/fa6'

//количество пробелов в отступе при форматировании JSON
const depthGap = 2

const serviceChars = [' ', '\n', '\t']

const JSONTextArea = ({ value, onChange }) => {
  const [isValid, setIsValid] = useState(false)
  const textAreaRef = useRef(null)
  const fileInputRef = useRef(null)

  useEffect(() => {
    if (!value) return
    if (!textAreaRef.current) return
    const { isValid, formattedJSON } = validateAndFormatJSON(JSON.stringify(value))
    setIsValid(isValid)
    textAreaRef.current.value = formattedJSON
  }, [])

  //валидация и форматирование JSON
  function validateAndFormatJSON(rawValue, userCursorPosition) {
    let cursorPositionOffset = 0
    let isValid = false
    let parsedJSON = null
    let formattedJSON = rawValue

    try {
      parsedJSON = JSON.parse(rawValue)
      formattedJSON = JSON.stringify(parsedJSON, null, depthGap)
      isValid = true

      for (let i = 0; i < userCursorPosition; i++) {
        if (rawValue[i] !== formattedJSON[i + cursorPositionOffset]) {
          if (serviceChars.includes(rawValue[i])) {
            for (let a = i + cursorPositionOffset; a > -1; a--) {
              if (rawValue[i] === formattedJSON[i + cursorPositionOffset]) {
                break
              } else {
                cursorPositionOffset--
              }
            }
          } else {
            for (let a = i + cursorPositionOffset; a < formattedJSON.length; a++) {
              if (rawValue[i] === formattedJSON[i + cursorPositionOffset]) {
                break
              } else {
                cursorPositionOffset++
              }
            }
          }
        }
      }
    } catch (error) {
    } finally {
      return { isValid, parsedJSON, formattedJSON, cursorPositionOffset }
    }
  }

  function textAreaContentHandler(event) {
    const userCursorPosition = event.target.selectionStart
    const { isValid, parsedJSON, formattedJSON, cursorPositionOffset } = validateAndFormatJSON(
      event.target.value,
      userCursorPosition
    )
    setIsValid(isValid)
    event.target.value = formattedJSON
    event.target.setSelectionRange(
      userCursorPosition + cursorPositionOffset,
      userCursorPosition + cursorPositionOffset
    )
    onChange(parsedJSON)
  }

  function fileOnLoadHandler(fileData) {
    const { isValid, parsedJSON, formattedJSON } = validateAndFormatJSON(fileData)
    setIsValid(isValid)
    textAreaRef.current.value = formattedJSON
    onChange(parsedJSON)
  }

  function fileImportHandler(event) {
    if (!(event.target.files && event.target.files.length)) return
    const localFile = event.target.files[0]
    const fr = new FileReader()
    fr.onload = (event) => fileOnLoadHandler(event.target.result)
    fr.readAsText(localFile)
    event.target.value = null
  }

  return (
    <div className={styles.wrapper}>
      <textarea
        ref={textAreaRef}
        className={[styles.input_area, isValid ? styles.valid : styles.invalid].join(' ')}
        onChange={textAreaContentHandler}
      />
      <input
        ref={fileInputRef}
        type="file"
        accept="application/json"
        hidden
        onChange={fileImportHandler}
      />
      <div className={styles.controls_panel}>
        <TransparentButton width="2rem" height="2rem" onClick={() => fileInputRef.current.click()}>
          <FaRegFolderOpen size={'1.8rem'} />
        </TransparentButton>
      </div>
    </div>
  )
}

export default JSONTextArea
