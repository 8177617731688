import styles from './TableCell.module.css'

const TableCell = ({ children, width, canStretch }) => {
  return (
    <div
      className={styles.content}
      style={{
        width: width ? `${width}rem` : '10rem',
        flexGrow: canStretch ? '1' : '0',
      }}
    >
      {children}
    </div>
  )
}

export default TableCell
