import styles from './SidePanel.module.css'
import NavigationButton from './NavigationButton'
import { FaLayerGroup } from 'react-icons/fa'
import { TbLogout } from 'react-icons/tb'
import { IoMdSettings } from 'react-icons/io'
import useAuth from '../../features/auth/useAuth'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { workspaceSelector } from '../../store/slices/workspaceSlice'

const SidePanel = () => {
  const currentScreen = useSelector(workspaceSelector.getCurrentScreen)
  const navigateTo = useNavigate()
  const { logOut } = useAuth()

  return (
    <div className={styles.panel}>
      <NavigationButton
        buttonIcon={<FaLayerGroup size={'1.6rem'} />}
        onClick={() => navigateTo('/')}
        isSelected={currentScreen === 'usage'}
        disabled={false}
      />
      {/* <NavigationButton
        buttonIcon={<IoMdSettings size={'2rem'} />}
        onClick={() => navigateTo('development')}
        isSelected={currentScreen === 'development'}
        disabled={false}
      /> */}
      <NavigationButton
        buttonIcon={<TbLogout size={'2rem'} />}
        onClick={logOut}
      />
    </div>
  )
}

export default SidePanel
