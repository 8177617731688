import styles from './TablesLayout.module.css'

import CreateEntitiCard from '../components/entities/CreateEntitiCard'
import TableCard from '../components/tables/TableCard'
import { useParams } from 'react-router-dom'
import LoadStab from '../components/load/LoadStab'
import useFixedModal from '../features/modals/useFixedModal'
import {
  useCreateTableMutation,
  useLoadTablesQuery,
} from '../services/apiServiceSlice'
import CreateEditEntitiForm from '../components/forms/CreateEditEntitiForm'

const TablesLayout = () => {
  const { groupID, databaseID } = useParams()

  const { openModal, closeModal } = useFixedModal()

  const { data: tablesData, isFetching: loadingTables } = useLoadTablesQuery({
    groupID: groupID,
    databaseID: databaseID,
  })

  const [createTable] = useCreateTableMutation()

  async function createTableHandler(inputsData) {
    const createResult = await createTable({
      groupID: groupID,
      databaseID: databaseID,
      body: inputsData,
    })
    if (!createResult?.error) {
      closeModal()
    }
  }

  return (
    <div className={styles.workspace}>
      <div className={styles.content}>
        {tablesData?.data?.length ? (
          tablesData?.data?.map((tableData) => (
            <TableCard key={tableData?.id} tableData={tableData} />
          ))
        ) : (
          <></>
        )}
        {loadingTables ? (
          <LoadStab />
        ) : (
          <CreateEntitiCard
            onClick={() =>
              openModal(
                <CreateEditEntitiForm
                  onSubmit={createTableHandler}
                  onClose={closeModal}
                />
              )
            }
          />
        )}
      </div>
    </div>
  )
}

export default TablesLayout
