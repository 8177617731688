import styles from './FiltersPanel.module.css'
import FilterCard from './FilterCard'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa6'

import filterOpsParams from '../../constants/filterOpsParams.json'
import AddFilterCard from './AddFilterCard'
import TransparentButton from '../controls/TransparentButton'

const FiltersPanel = ({
  fieldsData = [],
  displaySettings = {},
  appliedFilters,
  hiddenFilters = [],
  applyFilter = () => null,
  clearFilter = () => null,
}) => {
  const [addMode, setAddMode] = useState(false)

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.panel_title}>Фильтры:</div>
        {appliedFilters?.map((appliedFilter) => {
          if (hiddenFilters.includes(appliedFilter?.key)) {
            return null
          }
          const linkedField = fieldsData?.find(
            (fieldData) => fieldData?.systemName === appliedFilter?.key
          )
          const linkedOps = filterOpsParams[appliedFilter?.ops]
          return (
            <FilterCard
              key={appliedFilter?.key + appliedFilter?.ops + appliedFilter?.val}
              fieldData={linkedField}
              fieldDisplaySettings={displaySettings[linkedField?.systemName]}
              filterInfo={{
                fieldName:
                  displaySettings[linkedField?.systemName]?.displayName ?? linkedField?.name,
                opsName: linkedOps?.name,
                filterValue: appliedFilter?.val,
              }}
              clearFilter={() =>
                clearFilter(appliedFilter?.key, appliedFilter?.ops, appliedFilter?.val)
              }
            />
          )
        })}
        {addMode ? (
          <AddFilterCard
            fieldsData={
              !hiddenFilters?.length
                ? fieldsData
                : fieldsData.filter((fieldData) => !hiddenFilters.includes(fieldData?.systemName))
            }
            displaySettings={displaySettings}
            onClose={() => setAddMode(false)}
            onApply={(key, ops, value) => {
              applyFilter(key, ops, value)
              setAddMode(false)
            }}
          />
        ) : (
          <TransparentButton width="3rem" height="3rem" onClick={() => setAddMode(true)}>
            <FaPlus size={'2rem'} />
          </TransparentButton>
        )}
      </div>
    </div>
  )
}

export default FiltersPanel
