import EntitiCard from '../entities/EntitiCard'
import { FaLayerGroup } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import {
  useDeleteGroupMutation,
  useUpdateGroupMutation,
} from '../../services/apiServiceSlice'
import { useDispatch } from 'react-redux'
import { setNavigatedGroup } from '../../store/slices/workspaceSlice'
import useFixedModal from '../../features/modals/useFixedModal'
import ConfirmDialog from '../gialogs/ConfirmDialog'
import CreateEditEntitiForm from '../forms/CreateEditEntitiForm'

const GroupCard = ({ groupData }) => {
  const { openModal, closeModal } = useFixedModal()

  const navigateTo = useNavigate()
  const dispatch = useDispatch()
  const [deleteGroup] = useDeleteGroupMutation()
  const [updateGroup] = useUpdateGroupMutation()

  async function updateGroupHandler(inputsData) {
    const updateResult = await updateGroup({
      groupID: groupData?.id,
      body: inputsData,
    })
    if (!updateResult?.error) {
      closeModal()
    }
  }

  function onEditHandler() {
    openModal(
      <CreateEditEntitiForm
        name={groupData?.name}
        description={groupData?.description}
        descriptionIsAvailable
        onSubmit={updateGroupHandler}
        onClose={closeModal}
      />
    )
  }

  function onRemoveHandler() {
    openModal(
      <ConfirmDialog
        title={'Remove this group?'}
        onConfirm={async () => {
          await deleteGroup({ groupID: groupData?.id })
          closeModal()
        }}
        onCancel={closeModal}
      />
    )
  }

  function selectGroup() {
    dispatch(setNavigatedGroup(groupData))
    navigateTo(`${groupData?.id}`)
  }

  return (
    <EntitiCard
      icon={<FaLayerGroup size={'1.3rem'} />}
      title={groupData?.name}
      description={groupData?.description}
      onClick={selectGroup}
      onRemove={onRemoveHandler}
      onEdit={onEditHandler}
    />
  )
}

export default GroupCard
