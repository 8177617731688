import { useState } from 'react'
import { useUpdateFieldMutation } from '../../services/apiServiceSlice'
import FormBasis, { ContentBlock } from './components/FormBasis'
import LabeledControl from '../controls/LabeledControl'
import ControlledInput from '../controls/ControlledInput'
import ControlledSelect from '../controls/ControlledSelect'
import ControlledCheckbox from '../controls/ControlledCheckbox'

import basicFieldTypes from '../../constants/basicFieldTypes.json'
import basicDateTimeFormats from '../../constants/basicDateTimeFormats.json'
import Button from '../controls/Button'
import TypedInput from '../controls/TypedInput'

const EditTableFieldForm = ({ fieldData, onClose }) => {
  const [fieldParams, setFieldParams] = useState({
    name: fieldData?.name,
    type: fieldData?.type,
    unique: fieldData?.unique,
    notNull: fieldData?.notNull,
    customType: fieldData?.customType?.name,
    defaultValue: fieldData?.defaultValue?.value,
  })

  const [updateField] = useUpdateFieldMutation()

  async function updateFieldHandler() {
    const fieldUpdateParams = {
      name:
        fieldParams?.name !== fieldData?.name ? fieldParams?.name : undefined,
      type:
        fieldParams?.type !== fieldData?.type ? fieldParams?.type : undefined,
      unique:
        fieldParams?.unique !== fieldData?.unique
          ? fieldParams?.unique
          : undefined,
      notNull:
        fieldParams?.notNull !== fieldData?.notNull
          ? fieldParams?.notNull
          : undefined,
      customType:
        fieldParams?.customType !== fieldData?.customType?.name
          ? fieldParams?.customType
          : undefined,
      defaultValue:
        fieldParams?.defaultValue !== fieldData?.defaultValue?.value
          ? fieldParams?.defaultValue
          : undefined,
    }

    const updatedValues = Object.values(fieldUpdateParams)

    if (
      !(
        updatedValues.length &&
        updatedValues.some((value) => value !== undefined)
      )
    ) {
      onClose()
      return
    }

    const updateResult = await updateField({
      fieldID: fieldData?.id,
      body: fieldUpdateParams,
    })
    if (!updateResult?.error) {
      onClose()
    }
  }

  return (
    <FormBasis width={'25rem'} onSubmit={updateFieldHandler}>
      <ContentBlock height={'5rem'}>
        <LabeledControl label={'Table field name'}>
          <ControlledInput
            value={fieldParams?.name}
            onChange={(nameValue) =>
              setFieldParams({ ...fieldParams, name: nameValue })
            }
          />
        </LabeledControl>
      </ContentBlock>
      <ContentBlock height={'5rem'}>
        <LabeledControl label={'Field type'}>
          <ControlledSelect
            value={fieldParams?.type}
            options={basicFieldTypes}
            optionIdentifier={(typeValue) => typeValue?.type}
            optionDisplay={(typeValue) => typeValue?.displayName}
            onChange={(typeValue) =>
              setFieldParams({ ...fieldParams, type: typeValue })
            }
          />
        </LabeledControl>
      </ContentBlock>

      {/* {fieldParams?.type === 'timestamptz' && (
        <ContentBlock height={'5rem'}>
          <LabeledControl label={'Date format'}>
            <ControlledSelect
              value={fieldParams?.dateFormat}
              options={basicDateTimeFormats}
              onChange={(dateFormatValue) =>
                setFieldParams({
                  ...fieldParams,
                  dateFormat: dateFormatValue,
                })
              }
            />
          </LabeledControl>
        </ContentBlock>
      )} */}

      <ContentBlock height={'3rem'}>
        <ControlledCheckbox
          label={'Not null'}
          checked={fieldParams?.notNull}
          onChange={(notNullValue) =>
            setFieldParams({ ...fieldParams, notNull: notNullValue })
          }
        />
      </ContentBlock>
      {fieldParams?.notNull && (
        <ContentBlock height={'5rem'}>
          <LabeledControl label={'Default value'}>
            <TypedInput
              type={fieldParams?.type}
              value={fieldParams?.defaultValue}
              onChange={(value) =>
                setFieldParams({ ...fieldParams, defaultValue: value })
              }
            />
          </LabeledControl>
        </ContentBlock>
      )}
      <ContentBlock height={'3rem'}>
        <ControlledCheckbox
          label={'Is unique'}
          checked={fieldParams?.unique}
          onChange={(uniqueValue) =>
            setFieldParams({ ...fieldParams, unique: uniqueValue })
          }
        />
      </ContentBlock>
      <ContentBlock height={'3rem'}>
        <Button type="submit">Update field</Button>
        <Button onClick={onClose}>Cancel</Button>
      </ContentBlock>
    </FormBasis>
  )
}

export default EditTableFieldForm
