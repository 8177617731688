import MaskedInput from './MaskedInput'
import { useLayoutEffect, useRef, useState } from 'react'
import { validateTimeValue } from '../../utils/validateTimeValue'

import { MdOutlineAccessTime } from 'react-icons/md'
import TransparentButton from './TransparentButton'

const inputTemplate = '{*hr**--**2*}:{*mn**--**2*}:{*sc**00**2*}'

const TimeInput = ({ initialValue, onChange, width = '100%', height = '100%' }) => {
  const [timeValue, setTimeValue] = useState({ hr: '', mn: '', sc: '' })
  const [inputIsValid, setInputIsValid] = useState(true)
  const [inputIsEmpty, setInputIsEmpty] = useState(true)
  const timePickerRef = useRef(null)

  function onTimeChangeHandler(value) {
    const { hours, minutes, seconds } = validateTimeValue(value.hr, value.mn, value.sc)
    const isValid = Boolean(hours && minutes)
    const isEmpty = !Boolean(hours || minutes)
    setInputIsValid(isValid || isEmpty)
    setInputIsEmpty(isEmpty)
    setTimeValue({ hr: hours, mn: minutes, sc: seconds })
    onChange(
      isValid
        ? `${'00'.slice(0, 2 - String(Number(hours)).length) + String(Number(hours))}:${
            '00'.slice(0, 2 - String(Number(minutes)).length) + String(Number(minutes))
          }:${'00'.slice(0, 2 - String(Number(seconds)).length) + String(Number(seconds))}`
        : null
    )
  }

  function onTimePickHandler(event) {
    onTimeChangeHandler({
      ...timeValue,
      hr: event.target.value.slice(0, 2),
      mn: event.target.value.slice(-2),
    })
  }

  useLayoutEffect(() => {
    if (!(initialValue && inputIsEmpty)) return
    const [hr, mn, sc] = initialValue.split(':')
    setTimeValue({ hr: hr, mn: mn, sc: sc })
    setInputIsEmpty(false)
    setInputIsValid(true)
  }, [initialValue])

  return (
    <div style={{ position: 'relative', width: width, height: height }}>
      <MaskedInput
        value={timeValue}
        isValid={inputIsValid}
        inputTemplate={inputTemplate}
        onChange={onTimeChangeHandler}
        control={
          <TransparentButton onClick={() => timePickerRef.current.showPicker()}>
            <MdOutlineAccessTime size={'2rem'} />
          </TransparentButton>
        }
      />
      <input
        ref={timePickerRef}
        value={timeValue.hr + ':' + timeValue.mn}
        type="time"
        style={{
          position: 'absolute',
          height: '0',
          width: '0',
          bottom: '0',
          border: 'none',
          visibility: 'hidden',
        }}
        onChange={onTimePickHandler}
      />
    </div>
  )
}

export default TimeInput
