import { useRef, useState } from 'react'

/////////////////////////////////////////////////////////////////////////////////////////
// SELECT *                                                                            //
// FROM leftTableID                                                                    //
// joinType JOIN rightTableID ON leftTableID.leftTableKey = rightTableID.rightTableKey //
/////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////
// Обозначения на бэке:         //
// leftTableID - mainTableId    //
// leftTableKey - mainTableKey  //
// rightTableID - joinTableId   //
// rightTableKey - joinTableKey //
//////////////////////////////////

const useCombineJoins = (entitiID) => {
  const lastKnownEntitiID = useRef(entitiID)
  const [combinedJoins, setCombinedJoins] = useState([])

  function applyJoin({ leftTableID, leftTableKey, rightTableID, rightTableKey, joinType }) {
    if (!leftTableKey || !rightTableID || !rightTableKey) return

    if (rightTableID === leftTableID) return

    if (checkIsJoinApplied(rightTableID)) return

    const appliedJoins = [...combinedJoins]
    appliedJoins.push({
      mainTableId: leftTableID,
      mainTableKey: leftTableKey,
      joinTableKey: rightTableKey,
      joinTableId: rightTableID,
      type: joinType ? joinType : 'left',
    })
    setCombinedJoins(appliedJoins)
  }

  function checkIsJoinApplied(rightTableID) {
    if (combinedJoins?.length === 0) return false

    for (let joinIndex = 0; joinIndex < combinedJoins?.length; joinIndex++) {
      if (combinedJoins[joinIndex]?.joinTableId === rightTableID) return true
    }

    return false
  }

  function clearJoin(rightTableID) {
    if (!rightTableID) return

    const clearedJoins = [...combinedJoins].filter(
      (combinedJoin) => combinedJoin?.mainTableKey !== rightTableID
    )
    setCombinedJoins(clearedJoins)
  }

  function resetJoins() {
    setCombinedJoins([])
  }

  function applyViewJoins(joinsData) {
    if (!(joinsData && joinsData?.length)) return
    setCombinedJoins(joinsData)
  }

  if (entitiID && lastKnownEntitiID.current !== entitiID) {
    lastKnownEntitiID.current = entitiID
    setCombinedJoins([])
    return {
      combinedJoins: [],
      applyJoin: () => null,
      applyViewJoins: () => null,
      clearJoin: () => null,
      resetJoins: () => null,
    }
  }

  return { combinedJoins, applyJoin, applyViewJoins, clearJoin, resetJoins }
}

export default useCombineJoins
