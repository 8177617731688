import styles from './AddEntitiButton.module.css'

const AddEntitiButton = ({ label, onClick }) => {
  return (
    <div className={styles.wrapper}>
      <button onClick={onClick} className={styles.content}>
        {label}
      </button>
    </div>
  )
}

export default AddEntitiButton
