import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  navigatedGroup: undefined,
  navigatedDatabase: undefined,
  navigatedTable: undefined,
  navigatedView: undefined,
  currentScreen: undefined,
}

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setNavigatedGroup: (state, action) => {
      if (action.payload?.id !== state.navigatedGroup?.id) {
        state.navigatedDatabase = undefined
        state.navigatedTable = undefined
        state.navigatedView = undefined
      }
      state.navigatedGroup = action.payload
    },
    setNavigatedDatabase: (state, action) => {
      if (action.payload?.id !== state.navigatedDatabase?.id) {
        state.navigatedTable = undefined
        state.navigatedView = undefined
      }
      state.navigatedDatabase = action.payload
    },
    setNavigatedTable: (state, action) => {
      if (action.payload?.id !== state.navigatedTable?.id) {
        state.navigatedView = undefined
      }
      state.navigatedTable = action.payload
    },
    setNavigatedView: (state, action) => {
      state.navigatedView = action.payload
    },
    setCurrentScreen: (state, action) => {
      state.currentScreen = action.payload
    },
  },
})

export const workspaceSelector = {
  getNavigatedGroup: (state) => state.workspace.navigatedGroup,
  getNavigatedDatabase: (state) => state.workspace.navigatedDatabase,
  getNavigatedTable: (state) => state.workspace.navigatedTable,
  getNavigatedView: (state) => state.workspace.navigatedView,
  getCurrentScreen: (state) => state.workspace.currentScreen,
}

export const {
  setNavigatedGroup,
  setNavigatedDatabase,
  setNavigatedTable,
  setNavigatedView,
  setCurrentScreen,
} = workspaceSlice.actions

export default workspaceSlice.reducer
