import { render, unmountComponentAtNode } from 'react-dom'
import styles from './FixedModal.module.css'
import { Provider } from 'react-redux'
import useRoots from '../../hooks/useRoots'
import { store } from '../../store/store'
import { v4 } from 'uuid'
import { useRef } from 'react'

const useFixedModal = () => {
  const { appRoot, modalsRoot } = useRoots()
  const modalContainers = useRef([])

  function closeOnEscape(event) {
    if (event.key === 'Escape') {
      closeModal()
    }
  }

  function openModal(component) {
    if (!(component && appRoot && modalsRoot)) return
    appRoot.setAttribute('inert', true)
    document.addEventListener('keydown', closeOnEscape)
    // console.log(modalsRoot.lastChild)

    const modalContainer = document.createElement('div')
    modalContainer.setAttribute('id', v4())
    modalContainers.current.push(modalContainer)
    modalsRoot.appendChild(modalContainer)

    // const previousModal = modalsRoot.lastChild

    // if (previousModal) {
    //   previousModal.setAttribute('inert', true)
    // }

    render(
      <Provider store={store}>
        <div className={styles.wrapper} onMouseDown={closeModal}>
          <div
            className={styles.content}
            onMouseDown={(event) => event.stopPropagation()}
          >
            {component}
          </div>
        </div>
      </Provider>,
      modalContainer
    )
  }

  function closeModal() {
    // const previousModal = modalsRoot.lastChild

    // if (!modalsRoot.children.length) {
    document.removeEventListener('keydown', closeOnEscape)
    if (appRoot.attributes['inert']) {
      appRoot.attributes.removeNamedItem('inert')
    }
    // } else {
    //   previousModal.removeNamedItem('inert')
    // }
    unmountComponentAtNode(
      modalContainers.current[modalContainers.current.length - 1]
    )
    modalContainers.current[modalContainers.current.length - 1].remove()
    modalContainers.current.length -= 1
  }

  return { openModal, closeModal }
}

export default useFixedModal
