import { useLayoutEffect, useState } from 'react'
import FormBasis, { ContentBlock } from './components/FormBasis'
import LabeledControl from '../controls/LabeledControl'
import ControlledInput from '../controls/ControlledInput'
import ControlledCheckbox from '../controls/ControlledCheckbox'

import Button from '../controls/Button'
import TypedInput from '../controls/TypedInput'
import ControlledSelect from '../controls/ControlledSelect'
import customComponents from '../../custom-components/customComponents.json'
import useRelationField from '../../hooks/useRelationField'
import { removeObjectProperty } from '../../utils/removeObjectProperty'

const EditViewFieldForm = ({ fieldData, displaySettingsData, onSubmit, onClose }) => {
  const documentRemToPx = parseFloat(getComputedStyle(document.documentElement).fontSize)

  const [editCustomOptions, setEditCustomOptions] = useState(false)
  const [displaySettings, setDisplaySettings] = useState(displaySettingsData)

  const { relationTableFields } = useRelationField(fieldData)

  function onSubmitHandler() {
    onSubmit(fieldData, displaySettings)
    onClose()
  }

  useLayoutEffect(() => {
    if (!fieldData) return
    if (!fieldData?.relationFieldId) return
    if (!relationTableFields.length) return
    const relationFieldData = relationTableFields.find(
      (relationTableField) => relationTableField?.id === fieldData?.relationFieldId
    )
    if (!displaySettings.data?.relationTableID) {
      setDisplaySettings({
        ...displaySettings,
        data: { ...displaySettings.data, relationTableID: relationFieldData?.tableId },
      })
    }
  }, [fieldData, relationTableFields, displaySettings])

  return (
    <FormBasis width={'40rem'} onSubmit={onSubmitHandler}>
      <ContentBlock height={'5rem'}>
        <LabeledControl label={'Заголовок'}>
          <ControlledInput
            value={displaySettings?.displayName}
            onChange={(nameValue) =>
              setDisplaySettings({ ...displaySettings, displayName: nameValue })
            }
          />
        </LabeledControl>
      </ContentBlock>

      <ContentBlock height={'3rem'}>
        <ControlledCheckbox
          label={'Фиксированная ширина'}
          checked={displaySettings.fixed}
          onChange={(canStretchValue) =>
            setDisplaySettings({
              ...displaySettings,
              fixed: canStretchValue,
            })
          }
        />
        <LabeledControl label={`Ширина (*${documentRemToPx}px)`}>
          <TypedInput
            type={'integer'}
            value={displaySettings?.width}
            onChange={(widthValue) =>
              setDisplaySettings({
                ...displaySettings,
                width: widthValue,
              })
            }
          />
        </LabeledControl>
      </ContentBlock>
      {fieldData?.relationFieldId && (
        <Button height="3rem" onClick={() => setEditCustomOptions(!editCustomOptions)}>
          Параметры отображения
        </Button>
      )}
      {editCustomOptions && (
        <ContentBlock direction="column" height={'fit-content'}>
          <LabeledControl label={'Компонент для отображения'}>
            <ControlledSelect
              height="3rem"
              placeholder={'выберите компонент...'}
              options={customComponents}
              value={displaySettings?.data?.component}
              optionDisplay={(option) => option?.displayName}
              optionIdentifier={(option) => option?.component}
              onChange={(value) => {
                if (!value) {
                  setDisplaySettings({
                    ...displaySettings,
                    data: {},
                  })
                } else {
                  setDisplaySettings({
                    ...displaySettings,
                    data: { component: value },
                  })
                }
              }}
            />
          </LabeledControl>
          {displaySettings?.data?.component && (
            <ContentBlock direction="column" height={'fit-content'}>
              {customComponents
                .find((component) => component.component === displaySettings?.data?.component)
                .entries.map((componentProp) => (
                  <LabeledControl
                    key={displaySettings?.data?.component + componentProp.entrie}
                    label={componentProp.name}
                  >
                    <ControlledSelect
                      height="3rem"
                      placeholder={'выберите значение...'}
                      value={
                        displaySettings.data.hasOwnProperty(componentProp.entrie)
                          ? displaySettings.data[componentProp.entrie]
                          : undefined
                      }
                      options={relationTableFields}
                      optionDisplay={(option) => option?.name}
                      optionIdentifier={(option) => option?.systemName}
                      onChange={(value) => {
                        if (!value) {
                          setDisplaySettings({
                            ...displaySettings,
                            data: removeObjectProperty(displaySettings.data, componentProp.entrie),
                          })
                        } else {
                          setDisplaySettings({
                            ...displaySettings,
                            data: { ...displaySettings.data, [componentProp.entrie]: value },
                          })
                        }
                      }}
                    />
                  </LabeledControl>
                ))}
            </ContentBlock>
          )}
        </ContentBlock>
      )}
      <ContentBlock height={'3rem'}>
        <Button type="submit">Сохранить</Button>
        <Button onClick={onClose}>Отмена</Button>
      </ContentBlock>
    </FormBasis>
  )
}

export default EditViewFieldForm
