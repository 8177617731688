import EllipsisLine from '../../components/text/EllipsisLine'
import styles from './Sticker.module.css'

const Sticker = ({ stickerText }) => {
  return (
    <div className={styles.container}>
      <EllipsisLine>{stickerText}</EllipsisLine>
    </div>
  )
}

export default Sticker
