import { useState } from 'react'
import ControlledInput from '../controls/ControlledInput'
import FormBasis, { ContentBlock } from './components/FormBasis'
import Button from '../controls/Button'
import LabeledControl from '../controls/LabeledControl'

const CreateEditEntitiForm = ({
  name,
  description,
  descriptionIsAvailable,
  onSubmit = () => null,
  onClose = () => null,
}) => {
  const [inputsData, setInputsData] = useState({
    name: name ?? '',
    description: description ?? '',
  })

  function handleSubmit(event) {
    onSubmit({
      name: inputsData?.name?.length ? inputsData?.name : null,
      description: descriptionIsAvailable
        ? inputsData?.description?.length
          ? inputsData?.description
          : null
        : undefined,
    })
  }

  return (
    <FormBasis width={'25rem'} onSubmit={handleSubmit}>
      <ContentBlock height={'5rem'}>
        <LabeledControl label={'Name'}>
          <ControlledInput
            value={inputsData?.name}
            onChange={(value) => setInputsData({ ...inputsData, name: value })}
          />
        </LabeledControl>
      </ContentBlock>
      {descriptionIsAvailable ? (
        <ContentBlock height={'5rem'}>
          <LabeledControl label={'Description'}>
            <ControlledInput
              value={inputsData?.description}
              onChange={(value) => setInputsData({ ...inputsData, description: value })}
            />
          </LabeledControl>
        </ContentBlock>
      ) : (
        <></>
      )}
      <ContentBlock height={'3rem'}>
        <Button type="submit" disabled={!inputsData.name}>
          Save
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ContentBlock>
    </FormBasis>
  )
}

export default CreateEditEntitiForm
