import styles from './TableSetupLayout.module.css'
import TitledScrollableBlock from '../../components/blocks/TitledScrollableBlock'
import { useParams } from 'react-router-dom'
import { useLoadFieldsQuery } from '../../services/apiServiceSlice'
import useCombineView from '../../hooks/useCombineView'
import SelectRightTableForm from '../../components/forms/SelectRightTableForm'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import useFixedModal from '../../features/modals/useFixedModal'
import { setFields, setJoins } from '../../store/slices/developmentSlice'
import ScrollableBlock from '../../components/blocks/ScrollableBlock'
import FiltersPanel from '../../components/filters/FiltersPanel'
import EntitiSticker from '../../components/entities/EntitiSticker'
import CreateViewForm from '../../components/forms/CreateViewForm'
import CreateFieldForm from '../../components/forms/CreateFieldForm'

const TableSetupLayout = () => {
  const { groupID, databaseID, tableID } = useParams()
  const dispatch = useDispatch()
  const { openModal, closeModal } = useFixedModal()
  const [appliedFields, setAppliedFields] = useState([])

  const { data: leftTableFieldsData, isFetching: loadingLeftTableFields } =
    useLoadFieldsQuery({
      groupID: groupID,
      databaseID: databaseID,
      tableID: tableID,
    })

  const {
    combinedViewFields,
    combinedViewJoins,
    applyViewField,
    applyMultipleViewFields,
    clearViewField,
    applyJoin,
    prepareViewRequestBody,
  } = useCombineView()

  function createFieldHandler() {
    openModal(
      <CreateFieldForm
        groupID={groupID}
        databaseID={databaseID}
        tableID={tableID}
        onClose={closeModal}
      />
    )
  }

  function createViewHandler() {
    const viewRequestBody = prepareViewRequestBody()
    openModal(
      <CreateViewForm
        groupID={groupID}
        databaseID={databaseID}
        tableID={tableID}
        viewRequestBody={viewRequestBody}
        onClose={closeModal}
      />
    )
  }

  function selectRightTable() {
    openModal(
      <SelectRightTableForm
        leftTableFieldsData={leftTableFieldsData?.data}
        onApply={applyRightTable}
        onClose={closeModal}
      />
    )
  }

  function applyRightTable({
    rightTableData,
    rightTableFieldsData,
    rightTableLinkedField,
    leftTableLinkedField,
  }) {
    applyJoin(
      leftTableLinkedField,
      rightTableData,
      rightTableLinkedField,
      rightTableFieldsData
    )
  }

  useEffect(() => {
    const preparedViewData = prepareViewRequestBody()
    dispatch(setFields(combinedViewFields))
    dispatch(setJoins(preparedViewData.joins))
    setAppliedFields(preparedViewData.columns)
  }, [combinedViewFields])

  return (
    <ScrollableBlock>
      <div className={styles.content}>
        <TitledScrollableBlock label={'Table fields'}>
          {leftTableFieldsData?.data?.map((fieldData) => (
            <EntitiSticker
              key={fieldData?.systemName}
              label={fieldData?.name}
              onClick={() => applyViewField(fieldData)}
              disabled={appliedFields.includes(fieldData?.systemName)}
            />
          ))}
          <button onClick={createFieldHandler}>Add field</button>
        </TitledScrollableBlock>

        <TitledScrollableBlock label={'View fields'}>
          {combinedViewFields?.map((fieldData) => (
            <EntitiSticker
              key={fieldData?.systemName}
              label={fieldData?.name}
              onClick={() => clearViewField(fieldData)}
            />
          ))}
          <button
            onClick={createViewHandler}
            disabled={!combinedViewFields.length}
          >
            Save view
          </button>
        </TitledScrollableBlock>

        <TitledScrollableBlock label={'Joined tables'}>
          {combinedViewJoins?.map((joinData) => (
            <ScrollableBlock key={joinData?.rightTableData?.id}>
              {joinData?.rightTableFieldsData?.map((fieldData) => (
                <EntitiSticker
                  key={fieldData?.systemName}
                  label={fieldData?.name}
                  onClick={() => applyViewField(fieldData)}
                  disabled={appliedFields.includes(fieldData?.systemName)}
                />
              ))}
            </ScrollableBlock>
          ))}
          <button onClick={selectRightTable}>Join table</button>
        </TitledScrollableBlock>
      </div>
    </ScrollableBlock>
  )
}

export default TableSetupLayout
