import { createSlice } from '@reduxjs/toolkit'
import { v4 } from 'uuid'

const initialState = {
  notifications: [],
  destroyDuration: 5000,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    applyNotification: (state, action) => {
      const notificationID = v4()
      const notificationData = { id: notificationID, data: action.payload }
      state.notifications = [...state.notifications, notificationData]
    },
    destroyNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (notificationData) => notificationData?.id !== action.payload
      )
    },
  },
})

export const notificationsSelector = {
  getNotifications: (state) => state.notifications.notifications,
  getDestroyDuration: (state) => state.notifications.destroyDuration,
}

export const { applyNotification, destroyNotification } =
  notificationsSlice.actions

export default notificationsSlice.reducer
