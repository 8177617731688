import Sticker from './stickers/Sticker'
import ColoredSticker from './stickers/ColoredSticker'
import UserAvatar from './user-avatar/UserAvatar'
import EllipsisLine from '../components/text/EllipsisLine'
import useRelationRecord from '../hooks/useRelationRecord'
import LoadStab from '../components/load/LoadStab'

const CustomComponent = ({ value, fieldData, fieldDisplaySettings, directDataRecord }) => {
  const { dataRecord: relationDataRecord, loadingDataRecord } = useRelationRecord(
    fieldData,
    fieldDisplaySettings?.data?.keyField,
    value,
    fieldDisplaySettings?.data?.relationTableID,
    Boolean(directDataRecord)
  )

  if (!value) {
    return ''
  }

  if (!(fieldDisplaySettings || fieldDisplaySettings?.data?.keyField)) {
    return <EllipsisLine>{value}</EllipsisLine>
  }

  if (!(relationDataRecord || directDataRecord)) {
    return <LoadStab />
  }

  const dataRecord = directDataRecord ?? relationDataRecord

  switch (fieldDisplaySettings?.data?.component) {
    case 'sticker':
      return <Sticker stickerText={dataRecord[fieldDisplaySettings?.data['stickerText']]} />
    case 'coloredSticker':
      return (
        <ColoredSticker
          stickerText={dataRecord[fieldDisplaySettings?.data['stickerText']]}
          stickerColor={dataRecord[fieldDisplaySettings?.data['stickerColor']]}
        />
      )
    case 'userAvatar':
      return (
        <UserAvatar
          userName={dataRecord[fieldDisplaySettings?.data['userName']]}
          imageURL={dataRecord[fieldDisplaySettings?.data['imageURL']]}
        />
      )
    default:
      return <EllipsisLine>{value}</EllipsisLine>
  }
}

export default CustomComponent
