import styles from './ScrollableBlock.module.css'

const ScrollableBlock = ({ children }) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>{children}</div>
    </section>
  )
}

export default ScrollableBlock
