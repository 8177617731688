import { useState } from 'react'
import styles from './DNDController.module.scss'

const DNDController = ({
  className,
  draggable,
  droppable,
  dropDirection = 'column',
  onDragStart = () => null,
  onDragEnd = () => null,
  onDrop = () => null,
  children,
}) => {
  const [dragOverArea, setDragOverArea] = useState(null)
  const [dragging, setDragging] = useState(false)

  function onDragStartHandler(event) {
    // event.preventDefault()
    // event.dataTransfer.setDragImage()
    event.stopPropagation()
    setDragging(true)
    onDragStart()
    event.dataTransfer.effectAllowed = 'move'
  }

  function onDragEndHandler(event) {
    event.preventDefault()
    setDragging(false)
    onDragEnd()
  }

  function onDragOverHandler(event, direction) {
    event.preventDefault()
    if (direction === 0) {
      setDragOverArea('before')
      return
    }
    if (direction === 1) {
      setDragOverArea('after')
      return
    }
  }

  function onDragLeaveHandler(event) {
    event.preventDefault()
    event.stopPropagation()
    setDragOverArea(null)
  }

  function onDropHandler(event, direction) {
    event.stopPropagation()
    setDragging(false)
    setDragOverArea(null)
    onDrop(direction)
  }

  return (
    <div
      className={[
        className,
        styles.active,
        dragging ? styles.faded : '',
        dragOverArea ? styles[`drag_over_${dragOverArea}_${dropDirection}`] : '',
      ].join(' ')}
      draggable={draggable}
      onDragStart={onDragStartHandler}
      onDragEnd={onDragEndHandler}
      onDragOver={(event) => event.preventDefault()}
    >
      {children}
      {droppable && (
        <div
          className={styles.drop_area}
          style={{ flexDirection: dropDirection !== 'full' ? dropDirection : 'column' }}
        >
          <div
            className={styles.drop_before}
            onDragOver={(event) => onDragOverHandler(event, 0)}
            onDragLeave={onDragLeaveHandler}
            onDrop={(event) => onDropHandler(event, 0)}
          />
          {dropDirection !== 'full' && (
            <div
              className={styles.drop_after}
              onDragOver={(event) => onDragOverHandler(event, 1)}
              onDragLeave={onDragLeaveHandler}
              onDrop={(event) => onDropHandler(event, 1)}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default DNDController
