import MaskedInput from './MaskedInput'
import { useLayoutEffect, useRef, useState } from 'react'
import { validateDateValue } from '../../utils/validateDateValue'

import { LuCalendarClock } from 'react-icons/lu'
import TransparentButton from './TransparentButton'
import { validateTimeValue } from '../../utils/validateTimeValue'
import dayjs from 'dayjs'

const inputTemplate = '{*d**ДД**2*}.{*m**ММ**2*}.{*y**ГГГГ**4*}г. {*hr**--**2*}:{*mn**--**2*}'

const DateTimeInput = ({ initialValue, onChange, width = '100%', height = '100%' }) => {
  const [dateTimeValue, setDateTimeValue] = useState({ d: '', m: '', y: '', hr: '', mn: '' })
  const [inputIsValid, setInputIsValid] = useState(true)
  const [inputIsEmpty, setInputIsEmpty] = useState(true)
  const dateTimePickerRef = useRef(null)

  function onDateTimeChangeHandler(value) {
    const { day, month, year } = validateDateValue(value.d, value.m, value.y)
    const { hours, minutes } = validateTimeValue(value.hr, value.mn)
    const isValid = Boolean(Number(day) && Number(month) && Number(year) && hours && minutes)
    const isEmpty = !Boolean(day || month || year || hours || minutes)
    setInputIsValid(isValid || isEmpty)
    setInputIsEmpty(isEmpty)
    setDateTimeValue({ d: day, m: month, y: year, hr: hours, mn: minutes })
    onChange(
      isValid
        ? `${'1900'.slice(0, 4 - String(Number(year)).length) + String(Number(year))}-${(
            '00' + month
          ).slice(-2)}-${('00' + day).slice(-2)} ${('00' + hours).slice(-2)}:${(
            '00' + minutes
          ).slice(-2)}:00`
        : null
    )
  }

  function onDateTimePickHandler(event) {
    if (!event.target.value) {
      onDateTimeChangeHandler({ d: '', m: '', y: '', hr: '', mn: '' })
      return
    }
    const [dateValue, timeValue] = event.target.value.split('T')
    const [year, month, day] = dateValue.split('-')
    const [hours, minutes] = timeValue.split(':')
    onDateTimeChangeHandler({ d: day, m: month, y: year, hr: hours, mn: minutes })
  }

  useLayoutEffect(() => {
    if (!(initialValue && inputIsEmpty)) return
    const { $y, $M, $D, $H, $m } = dayjs(initialValue)
    setDateTimeValue({
      y: String($y),
      m: ('00' + String($M + 1)).slice(-2),
      d: ('00' + String($D)).slice(-2),
      hr: ('00' + String($H)).slice(-2),
      mn: ('00' + String($m)).slice(-2),
    })
    setInputIsEmpty(false)
    setInputIsValid(true)
  }, [initialValue])

  return (
    <div style={{ position: 'relative', width: width, height: height }}>
      <MaskedInput
        value={dateTimeValue}
        isValid={inputIsValid}
        inputTemplate={inputTemplate}
        onChange={onDateTimeChangeHandler}
        control={
          <TransparentButton onClick={() => dateTimePickerRef.current.showPicker()}>
            <LuCalendarClock size={'2rem'} />
          </TransparentButton>
        }
      />
      <input
        ref={dateTimePickerRef}
        type="datetime-local"
        style={{
          position: 'absolute',
          height: '0',
          width: '0',
          bottom: '0',
          border: 'none',
          visibility: 'hidden',
        }}
        onChange={onDateTimePickHandler}
      />
    </div>
  )
}

export default DateTimeInput
