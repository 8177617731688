import TransparentButton from '../../../controls/TransparentButton'

import { FaSort } from 'react-icons/fa'
import { FaSortAmountDown } from 'react-icons/fa'
import { FaSortAmountDownAlt } from 'react-icons/fa'

const sortIcons = {
  NONE: <FaSort size={'1.4rem'} />,
  ASC: <FaSortAmountDownAlt size={'1.4rem'} />,
  DESC: <FaSortAmountDown size={'1.4rem'} />,
}

const switchSortMap = {
  NONE: 'ASC',
  ASC: 'DESC',
  DESC: null,
}

const SwitchSortButton = ({
  width,
  height,
  disabled,
  fieldName,
  applySort,
  sortState,
}) => {
  return (
    <TransparentButton
      width={width}
      height={height}
      disabled={disabled}
      onClick={() => applySort(fieldName, switchSortMap[sortState])}
    >
      {sortIcons[sortState]}
    </TransparentButton>
  )
}

export default SwitchSortButton
