import styles from './EntitiCard.module.css'
import { MdEdit } from 'react-icons/md'
import { HiTrash } from 'react-icons/hi'

const EntitiCard = ({
  icon,
  title,
  description,
  onClick,
  onRightClick,
  onRemove,
  onEdit,
}) => {
  return (
    <button
      className={styles.card}
      onClick={onClick}
      onContextMenu={(event) => {
        event.preventDefault()
      }}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.icon}>{icon}</div>
      <div
        className={styles.remove_button}
        onClick={(event) => {
          event.stopPropagation()
          onRemove()
        }}
      >
        <HiTrash size={'1.5rem'} />
      </div>
      <div
        className={styles.edit_button}
        onClick={(event) => {
          event.stopPropagation()
          onEdit()
        }}
      >
        <MdEdit size={'1.5rem'} />
      </div>
    </button>
  )
}

export default EntitiCard
