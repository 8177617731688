import ControlledSelect from '../../components/controls/ControlledSelect'
import useRelationField from '../../hooks/useRelationField'

const SingleSelect = ({ value, onChange, fieldData, keyField, displayField }) => {
  const { options, loadingOptions } = useRelationField(fieldData)

  return (
    <ControlledSelect
      placeholder={'Выберите значение'}
      options={options}
      value={value}
      optionIdentifier={(option) => option[keyField ?? 'id']}
      optionDisplay={(option) => option[displayField ?? 'id']}
      onChange={(value) => (value ? onChange(value) : onChange(null))}
    />
  )
}

export default SingleSelect
