import FormBasis, { ContentBlock } from '../forms/components/FormBasis'
import Button from '../controls/Button'

const ConfirmDialog = ({ title, onConfirm, onCancel }) => {
  return (
    <FormBasis width={'25rem'} onSubmit={onConfirm}>
      <ContentBlock height={'3rem'}>{title}</ContentBlock>
      <ContentBlock height={'3rem'}>
        <Button type="submit">Yes</Button>
        <Button onClick={onCancel}>No</Button>
      </ContentBlock>
    </FormBasis>
  )
}

export default ConfirmDialog
