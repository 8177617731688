import EllipsisLine from '../../../text/EllipsisLine'
import styles from '../styles/KanbanCardRecord.module.scss'

const KanbanCardRecord = ({ title, children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <EllipsisLine>{title + ':'}</EllipsisLine>
      </div>
      {children}
    </div>
  )
}

export default KanbanCardRecord
