import { useRef, useState } from 'react'

import filterOpsParams from '../constants/filterOpsParams.json'

/////////////////////////////////// filterOpsParams ///////////////////////////////////
// filterValueIsRequired - фильтр может быть применен только с каким-то значением    //
// multiple - может быть применено несколько фильтров с одни и там же ops оператором //
///////////////////////////////////////////////////////////////////////////////////////

const useCombineFilters = (entitiID) => {
  const lastKnownEntitiID = useRef(entitiID)
  const [combinedFilters, setCombinedFilters] = useState([])

  function applyFilter(fieldKey, opsValue, filterValue) {
    const appliedFilters = [...combinedFilters]

    ////////// Проверка, применен ли фильтр с такими же параметрами //////////
    const { isApplied, filterIndex } = checkIsFilterApplied(
      fieldKey,
      opsValue,
      filterValue
    )

    if (!isApplied) {
      ////////// Добавление нового фильтра //////////
      if (filterOpsParams[opsValue].filterValueIsRequired && !filterValue)
        return
      if (filterOpsParams[opsValue].filterValueIsRequired) {
        appliedFilters.push({ key: fieldKey, ops: opsValue, val: filterValue })
      } else {
        appliedFilters.push({ key: fieldKey, ops: opsValue, val: null })
      }
      setCombinedFilters(appliedFilters)
      return
    } else {
      ////////// Изменение параметров примененного фильтра/очистка фильтра //////////
      if (filterOpsParams[opsValue].multiple) return
      if (filterOpsParams[opsValue].filterValueIsRequired && !filterValue) {
        clearFilter(fieldKey, opsValue, filterValue)
        return
      }
      if (filterValue && filterValue !== appliedFilters[filterIndex].val) {
        appliedFilters[filterIndex] = {
          ...appliedFilters[filterIndex],
          val: filterValue,
        }
        setCombinedFilters(appliedFilters)
        return
      }
    }
  }

  function clearFilter(fieldKey, opsValue, filterValue) {
    if (!filterOpsParams[opsValue].multiple) {
      const clearedFilters = combinedFilters.filter(
        (fieldFilter) =>
          !(fieldFilter.key === fieldKey && fieldFilter.ops === opsValue)
      )
      setCombinedFilters(clearedFilters)
    } else {
      const clearedFilters = combinedFilters.filter(
        (fieldFilter) =>
          !(
            fieldFilter.key === fieldKey &&
            fieldFilter.ops === opsValue &&
            fieldFilter?.val === filterValue
          )
      )
      setCombinedFilters(clearedFilters)
    }
  }

  function checkIsFilterApplied(fieldKey, opsValue, filterValue) {
    if (!filterOpsParams[opsValue].multiple) {
      for (
        let filterIndex = 0;
        filterIndex < combinedFilters.length;
        filterIndex++
      ) {
        if (
          combinedFilters[filterIndex].key === fieldKey &&
          combinedFilters[filterIndex].ops === opsValue
        ) {
          return { isApplied: true, filterIndex: filterIndex }
        }
      }
    } else {
      for (
        let filterIndex = 0;
        filterIndex < combinedFilters.length;
        filterIndex++
      ) {
        if (
          combinedFilters[filterIndex].key === fieldKey &&
          combinedFilters[filterIndex].ops === opsValue &&
          combinedFilters[filterIndex].val === filterValue
        ) {
          return { isApplied: true, filterIndex: filterIndex }
        }
      }
    }
    return { isApplied: false, filterIndex: undefined }
  }

  function clearFieldFilters(fieldKey) {
    if (!fieldKey) return
    const clearedFilters = combinedFilters.filter((fieldFilter) => fieldFilter.key !== fieldKey)
    setCombinedFilters(clearedFilters)
  }

  function resetFilters() {
    setCombinedFilters([])
  }

  function applyViewFilters(viewFiltersData) {
    if (!viewFiltersData) return
    setCombinedFilters(viewFiltersData)
  }

  ////////// Сброс стейта и возврат пустышек для предотвращения лагов при переходе между сущностями //////////
  if (entitiID && lastKnownEntitiID.current !== entitiID) {
    lastKnownEntitiID.current = entitiID
    setCombinedFilters([])
    return {
      combinedFilters: [],
      applyFilter: () => null,
      clearFilter: () => null,
      clearFieldFilters: () => null,
      applyViewFilters: () => null,
      resetFilters: () => null,
    }
  }

  return {
    combinedFilters,
    applyFilter,
    clearFilter,
    clearFieldFilters,
    applyViewFilters,
    resetFilters,
  }
}

export default useCombineFilters
