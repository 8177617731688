import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slices/userSlice'
import dataSlice from './slices/dataSlice'
import workspaceSlice from './slices/workspaceSlice'
import { apiServiceSlice } from '../services/apiServiceSlice'
import authSlice from './slices/authSlice'
import developmentSlice from './slices/developmentSlice'
import notificationsSlice from './slices/notificationsSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    data: dataSlice,
    workspace: workspaceSlice,
    development: developmentSlice,
    notifications: notificationsSlice,
    [apiServiceSlice.reducerPath]: apiServiceSlice.reducer,
  },
  middleware: (getDefaultMiddlware) =>
    getDefaultMiddlware().concat(apiServiceSlice.middleware),
})
