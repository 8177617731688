export function removeObjectProperty(object, property) {
    if (!(object && property)) return {}
    const objectProperties = Object.keys(object)

    if (!objectProperties.length) return {}

    if (!objectProperties.includes(property)) return object

    const resultObject = { ...object }
    delete resultObject[property]

    return resultObject

}