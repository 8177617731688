import EllipsisLine from '../../components/text/EllipsisLine'
import styles from './UserAvatar.module.css'

const UserAvatar = ({ userName, imageURL }) => {
  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        {imageURL ? (
          <img src={imageURL} />
        ) : userName && userName?.length ? (
          userName[0].toUpperCase()
        ) : (
          ':-('
        )}
      </div>
      <EllipsisLine>{userName ?? 'Человек без имени'}</EllipsisLine>
    </div>
  )
}

export default UserAvatar
