import styles from './ControlledCheckbox.module.css'
import EllipsisLine from '../text/EllipsisLine'

const ControlledCheckbox = ({
  label = '',
  checked = false,
  onChange = () => null,
  disabled = false,
}) => {
  return (
    <label className={styles.label}>
      <input
        className={styles.checkbox}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(event) => onChange(event.target.checked)}
      />
      <EllipsisLine>{label}</EllipsisLine>
    </label>
  )
}

export default ControlledCheckbox
