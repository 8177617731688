import styles from './App.module.css'
import { Route, Routes } from 'react-router-dom'
import DevelopmentScreen from './screens/development/DevelopmentScreen'
import LoginScreen from './screens/login/LoginScreen'
import UsageScreen from './screens/usage/UsageScreen'
import SidePanel from './components/sidebar/SidePanel'

const App = () => {
  return (
    <div id="app_root" className={styles.app}>
      <SidePanel />
      <Routes>
        <Route path="/*" element={<UsageScreen />} />
        <Route path="development/*" element={<DevelopmentScreen />} />
        <Route path="login/*" element={<LoginScreen />} />
      </Routes>
    </div>
  )
}

export default App
