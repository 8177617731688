import {
  useCreateTableMutation,
  useDeleteDatabaseMutation,
  useLoadTablesQuery,
  useUpdateDatabaseMutation,
} from '../../../../services/apiServiceSlice'
import TablesNavigator from './TableNavigator'
import GroupedContent from './GroupedContent'
import AddEntitiButton from './AddEntitiButton'
import NavigationEntitiSticker from './NavigationEntitiSticker'
import useFixedModal from '../../../../features/modals/useFixedModal'
import CreateEditEntitiForm from '../../../../components/forms/CreateEditEntitiForm'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { NavigationContext } from './NavigationPanel'
import { FaDatabase } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { HiTrash } from 'react-icons/hi'
import TransparentButton from '../../../../components/controls/TransparentButton'
import ConfirmDialog from '../../../../components/gialogs/ConfirmDialog'

const DatabasesNavigator = ({ groupData, databaseData }) => {
  const { databaseID } = useParams()

  const { readOnly, branchDepth, onDatabaseSelect } =
    useContext(NavigationContext)

  const { openModal, closeModal } = useFixedModal()
  const { data: tablesData } = useLoadTablesQuery({
    databaseID: databaseData?.id,
  })

  const [createTable] = useCreateTableMutation()
  const [updateDatabase] = useUpdateDatabaseMutation()
  const [deleteDatabase] = useDeleteDatabaseMutation()

  async function createTableHandler(inputsData) {
    const creationResult = await createTable({
      databaseID: databaseData?.id,
      body: inputsData,
    })
    if (!creationResult?.error) {
      closeModal()
    }
  }

  async function updateDatabaseHandler(inputsData) {
    const updateResult = await updateDatabase({
      databaseID: databaseData?.id,
      body: inputsData,
    })
    if (!updateResult?.error) {
      closeModal()
    }
  }

  async function deleteDatabaseHandler() {
    const deleteResult = await deleteDatabase({
      databaseID: databaseData?.id,
    })
    if (!deleteResult?.error) {
      closeModal()
    }
  }

  return (
    <>
      <NavigationEntitiSticker
        icon={<FaDatabase size={'1.1rem'} style={{ flexShrink: 0 }} />}
        label={databaseData?.name}
        selected={databaseData?.id === databaseID}
        disabled={!onDatabaseSelect}
        controls={
          !readOnly ? (
            <>
              <TransparentButton
                width="1.2rem"
                height="1.2rem"
                onClick={() =>
                  openModal(
                    <CreateEditEntitiForm
                      name={databaseData?.name}
                      description={databaseData?.description}
                      descriptionIsAvailable
                      onSubmit={updateDatabaseHandler}
                      onClose={closeModal}
                    />
                  )
                }
              >
                <MdEdit />
              </TransparentButton>
              <TransparentButton
                width="1.2rem"
                height="1.2rem"
                onClick={() =>
                  openModal(
                    <ConfirmDialog
                      title={'Delete database?'}
                      onConfirm={deleteDatabaseHandler}
                      onCancel={closeModal}
                    />
                  )
                }
              >
                <HiTrash />
              </TransparentButton>
            </>
          ) : undefined
        }
      />
      {branchDepth !== 'database' && (
        <GroupedContent>
          {tablesData?.data?.map((tableData) => (
            <TablesNavigator
              key={tableData?.id}
              groupData={groupData}
              databaseData={databaseData}
              tableData={tableData}
            />
          ))}
        </GroupedContent>
      )}
      {!readOnly && (
        <AddEntitiButton
          label={'+ table'}
          onClick={() =>
            openModal(
              <CreateEditEntitiForm
                onSubmit={createTableHandler}
                onClose={closeModal}
              />
            )
          }
        />
      )}
    </>
  )
}

export default DatabasesNavigator
