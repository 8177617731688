import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { BrowserRouter } from 'react-router-dom'
import AuthShield from './features/auth/AuthShield'
import NotificationsProvider from './features/notifications/NotificationsProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthShield>
        <NotificationsProvider>
          <App />
        </NotificationsProvider>
        <div id="modals_root" />
        <div id="notifications_root" />
      </AuthShield>
    </BrowserRouter>
  </Provider>
)
