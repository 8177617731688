import styles from './ControlledSelect.module.css'

const ControlledSelect = ({
  width = '100%',
  height = '100%',
  value,
  onChange = () => null,
  options,
  optionDisplay = (value) => value,
  optionIdentifier = (value) => value,
  placeholder,
}) => {
  return (
    <select
      className={styles.select}
      style={{ width: width, height: height }}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    >
      {placeholder && (
        <option key={placeholder} className={styles.default_option} value={''}>
          {placeholder}
        </option>
      )}
      {options?.length &&
        options?.map((optionData) => (
          <option
            className={styles.available_option}
            key={optionIdentifier(optionData)}
            value={optionIdentifier(optionData)}
          >
            {optionDisplay(optionData)}
          </option>
        ))}
    </select>
  )
}

export default ControlledSelect
