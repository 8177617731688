import EllipsisLine from '../text/EllipsisLine'
import styles from './EntitiSticker.module.css'

const EntitiSticker = ({ icon, label, selected, disabled, onClick }) => {
  return (
    <div className={styles.wrapper} onClick={!disabled ? onClick : () => null}>
      <div
        className={
          selected
            ? styles.content_selected
            : disabled
            ? styles.content_disabled
            : styles.content
        }
      >
        <EllipsisLine>{label}</EllipsisLine>
      </div>
    </div>
  )
}

export default EntitiSticker
