// TO DELETE

import styles from './CreateFieldForm.module.css'
import { useState } from 'react'
import { useCreateFieldMutation } from '../../services/apiServiceSlice'
import FormBasis from './components/FormBasis'
import ControlledInput from '../controls/ControlledInput'
import ControlledCheckbox from '../controls/ControlledCheckbox'

const CreateFieldForm = ({ groupID, databaseID, tableID, onClose }) => {
  const [fieldType, setFieldType] = useState(undefined)
  const [fieldName, setFieldName] = useState('')
  const [isUnique, setIsUnique] = useState(false)

  const [createField] = useCreateFieldMutation()

  async function createFieldHandler() {
    const createFieldResult = await createField({
      groupID: groupID,
      databaseID: databaseID,
      tableID: tableID,
      body: { name: fieldName, type: fieldType, unique: isUnique },
    })
    if (!createFieldResult?.error) {
      onClose()
    }
  }

  function onSubmitHandler(event) {
    event.preventDefault()
    if (!(fieldType && fieldName)) return
    createFieldHandler()
  }

  return (
    <FormBasis>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <select
          value={fieldType}
          onChange={(event) => setFieldType(event.target.value)}
        >
          <option value={undefined}>Choose type...</option>
          <option value="uuid">id</option>
          <option value="string">string</option>
          <option value="integer">integer</option>
          <option value="double">double</option>
          <option value="boolean">boolean</option>
          <option value="timestamptz">timestamp</option>
        </select>
        <ControlledInput
          value={fieldName}
          onChange={setFieldName}
          placeholder="Field name"
        />
        <label>
          Unique
          <ControlledCheckbox checked={isUnique} onChange={setIsUnique} />
        </label>
        <button type="submit" disabled={!fieldName || !fieldType}>
          Save
        </button>
        <button type="button" onClick={onClose}>
          Cancel
        </button>
      </form>
    </FormBasis>
  )
}

export default CreateFieldForm
