import { useLayoutEffect, useRef, useState } from 'react'
import { useLoadFieldsQuery } from '../services/apiServiceSlice'

const useTableFields = (tableID) => {
  const requestedTable = useRef(null)

  const [tableFields, setTableFields] = useState([])
  const [editableTableFields, setEditableTableFields] = useState([])

  const { data: fieldsData, isFetching: loadingFieldsData } =
    useLoadFieldsQuery(
      { tableID: requestedTable.current },
      { skip: !requestedTable.current }
    )

  useLayoutEffect(() => {
    if (!loadingFieldsData && fieldsData?.data) {
      ///// костыль для отображения системных полей в таблице /////
      setEditableTableFields(fieldsData?.data.filter(fieldData => !fieldData?.isSystemField))
      setTableFields(fieldsData?.data?.map(fieldData => {
        if (!fieldData?.isSystemField) { return fieldData } else {
          return { ...fieldData, systemName: fieldData?.name }
        }
      }))
      /////////////////////////////////////////////////////////////
      // setTableFields(fieldsData?.data) // в идеальном мире это должно выглядеть так
    }
  }, [fieldsData?.data])

  function cleanUpOnTableIsChanged() {
    setTableFields([])
    setEditableTableFields([])
  }

  useLayoutEffect(() => {
    if (tableID === requestedTable.current) return
    requestedTable.current = tableID
    cleanUpOnTableIsChanged()
  }, [tableID])

  if (tableID !== requestedTable.current) {
    return {
      tableFields: [],
      editableTableFields: [],
      loadingTableFields: loadingFieldsData
    }
  }

  return { tableFields, editableTableFields, loadingTableFields: loadingFieldsData }
}

export default useTableFields
