import styles from './NavigationButton.module.css'

const NavigationButton = ({
  buttonIcon,
  disabled,
  isSelected,
  onClick = () => null,
}) => {
  return (
    <div
      className={[
        styles.wrapper,
        isSelected ? styles.selected : '',
        !disabled ? styles.active : styles.disabled,
      ].join(' ')}
      onClick={!disabled ? onClick : () => null}
    >
      <div className={styles.content}>
        <div className={styles.icon}>{buttonIcon}</div>
      </div>
    </div>
  )
}

export default NavigationButton
