import { useEffect, useState } from 'react'
import LoadStab from '../../components/load/LoadStab'
import useAuth from './useAuth'
import { useSelector } from 'react-redux'
import { authSelector } from '../../store/slices/authSlice'

const AuthShield = ({ children }) => {
  const { isAuthorized, updateToken } = useAuth()

  const accessTokenIsExpired = useSelector(authSelector.getAccessTokenIsExpired)

  useEffect(() => {
    if (accessTokenIsExpired) {
      updateToken()
    }
  }, [accessTokenIsExpired])

  return isAuthorized ? children : <LoadStab />
}

export default AuthShield
