import { useLayoutEffect, useState } from 'react'
import styles from './MaskedInput.module.css'

/* {* key ** placeholder ** length *} */

const MaskedInput = ({
  inputTemplate = '',
  value = null,
  isValid = true,
  onChange = () => null,
  width = '100%',
  height = '100%',
  control = null,
}) => {
  const [template, setTemplate] = useState([])
  const [schema, setSchema] = useState({})

  function parseTemplate(rawTemplate) {
    const inputsSchema = {}
    const templateSchema = []
    const patternsBorders = []

    for (let i = 0; i < rawTemplate.length; i++) {
      if (
        (rawTemplate[i] === '{' && rawTemplate[i + 1] === '*') ||
        (rawTemplate[i] === '}' && rawTemplate[i - 1] === '*')
      ) {
        patternsBorders.push(i)
      }
    }

    if (patternsBorders[0] !== 0) {
      templateSchema.push(rawTemplate.slice(0, patternsBorders[0]))
    }

    for (let i = 0; i < patternsBorders.length - 1; i++) {
      if (rawTemplate[patternsBorders[i]] === '{' && rawTemplate[patternsBorders[i + 1]] === '}') {
        const objectPattern = rawTemplate.slice(patternsBorders[i] + 2, patternsBorders[i + 1] - 1)
        const keySchema = objectPattern.split('**')
        inputsSchema[keySchema[0]] = { placeholder: keySchema[1], length: keySchema[2] }
        templateSchema.push(`key_${keySchema[0]}`)
      } else {
        templateSchema.push(rawTemplate.slice(patternsBorders[i] + 1, patternsBorders[i + 1]))
      }
    }

    if (patternsBorders[patternsBorders.length] !== templateSchema.length) {
      templateSchema.push(rawTemplate.slice(patternsBorders[patternsBorders.length - 1] + 1))
    }

    return { templateSchema, inputsSchema }
  }

  useLayoutEffect(() => {
    const { templateSchema, inputsSchema } = parseTemplate(inputTemplate)
    setTemplate(templateSchema)
    setSchema(inputsSchema)
  }, [inputTemplate])

  function onChangeHandler(key, event) {
    onChange({ ...value, [key]: event.target.value })
  }

  return (
    <div
      className={[styles.wrapper, isValid ? styles.valid : styles.invalid].join(' ')}
      style={{ width: width, height: height }}
    >
      {template.map((templateItem) => {
        if (templateItem.slice(0, 4) === 'key_') {
          const key = templateItem.slice(4)
          const width = String(value[key]).length
            ? `${String(value[key]).length + 1}ch`
            : `${schema[key]?.placeholder?.length + 1}ch`

          return (
            <input
              key={key}
              placeholder={schema[key]?.placeholder}
              maxLength={schema[key]?.length}
              className={styles.input}
              onChange={(event) => onChangeHandler(key, event)}
              value={value[key]}
              style={{ width: width }}
            />
          )
        } else {
          return <span className={styles.pattern}>{templateItem}</span>
        }
      })}
      {control && <div className={styles.control_slot}>{control}</div>}
    </div>
  )
}

export default MaskedInput
