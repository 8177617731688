import styles from './NavigationPanel.module.css'
import {
  useCreateGroupMutation,
  useLoadGroupsQuery,
} from '../../../../services/apiServiceSlice'
import GroupNavigator from './GroupNavigator'
import { createContext } from 'react'
import AddEntitiButton from './AddEntitiButton'
import TransparentButton from '../../../../components/controls/TransparentButton'
import useFixedModal from '../../../../features/modals/useFixedModal'
import CreateEditEntitiForm from '../../../../components/forms/CreateEditEntitiForm'

export const NavigationContext = createContext()

const NavigationPanel = ({
  readOnly,
  branchDepth,
  onGroupSelect,
  onDatabaseSelect,
  onTableSelect,
  onViewSelect,
}) => {
  const { openModal, closeModal } = useFixedModal()

  const { data: groupsData } = useLoadGroupsQuery()

  const [createGroup] = useCreateGroupMutation()

  async function createGroupHandler(inputsData) {
    if (!inputsData) return
    const createResult = await createGroup({ body: inputsData })
    if (!createResult?.error) {
      closeModal()
    }
  }

  return (
    <NavigationContext.Provider
      value={{
        readOnly: readOnly,
        branchDepth: branchDepth,
        onGroupSelect: onGroupSelect,
        onDatabaseSelect: onDatabaseSelect,
        onTableSelect: onTableSelect,
        onViewSelect: onViewSelect,
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {groupsData?.data?.map((groupData) => (
            <GroupNavigator key={groupData?.id} groupData={groupData} />
          ))}
          <TransparentButton
            width="100%"
            height="4rem"
            onClick={() =>
              openModal(
                <CreateEditEntitiForm
                  descriptionIsAvailable
                  onSubmit={createGroupHandler}
                  onClose={closeModal}
                />
              )
            }
          >
            + group
          </TransparentButton>
        </div>
      </div>
    </NavigationContext.Provider>
  )
}

export default NavigationPanel
