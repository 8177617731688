import { useState } from 'react'
import FormBasis, { ContentBlock } from './components/FormBasis'
import ControlledSelect from '../controls/ControlledSelect'
import EllipsisLine from '../text/EllipsisLine'
import Button from '../controls/Button'
import LabeledControl from '../controls/LabeledControl'

const KanbanSettingsForm = ({
  currentDefiner,
  definers = [],
  displaySettings = {},
  onSubmit,
  onClose,
}) => {
  const [selectedDefiner, setSelectedDefiner] = useState(currentDefiner)

  function handleSubmit() {
    if (selectedDefiner?.id === currentDefiner?.id) {
      onClose()
      return
    }
    onSubmit(selectedDefiner)
    onClose()
  }

  return (
    <FormBasis width={'35rem'} onSubmit={handleSubmit}>
      <ContentBlock direction="column">
        <EllipsisLine>Настройка канбан-доски</EllipsisLine>
      </ContentBlock>
      <ContentBlock direction="column">
        <LabeledControl label={'Задающее поле'}>
          <ControlledSelect
            height="3rem"
            placeholder={'Выберите поле'}
            value={selectedDefiner?.id}
            options={definers}
            optionIdentifier={(optionData) => optionData?.id}
            optionDisplay={(optionData) =>
              displaySettings[optionData?.systemName]?.displayName ?? optionData?.name
            }
            onChange={(value) =>
              setSelectedDefiner(definers.find((definer) => definer?.id === value))
            }
          />
        </LabeledControl>
      </ContentBlock>

      <ContentBlock height={'3rem'}>
        <Button type="submit" disabled={!selectedDefiner}>
          Сохранить
        </Button>
        <Button onClick={onClose}>Отмена</Button>
      </ContentBlock>
    </FormBasis>
  )
}

export default KanbanSettingsForm
