import styles from '../styles/KanbanColumn.module.scss'
import DNDController from './DNDController'

const KanbanColumn = ({
  headerComponent,
  footerComponent,
  children,
  draggable,
  droppable,
  onDragStart,
  onDragEnd,
  onDrop,
}) => {
  return (
    <DNDController
      className={styles.wrapper}
      dropDirection="row"
      draggable={draggable}
      droppable={droppable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
    >
      {headerComponent && <div className={styles.header}>{headerComponent}</div>}
      <div className={styles.content_wrapper}>
        <div className={styles.content}>{children}</div>
      </div>
      {footerComponent && <div className={styles.footer}>{footerComponent}</div>}
    </DNDController>
  )
}

export default KanbanColumn
