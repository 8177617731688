import dayjs from 'dayjs'
import MaskedInput from './MaskedInput'
import { useLayoutEffect, useRef, useState } from 'react'
import { validateDateValue } from '../../utils/validateDateValue'

import { LuCalendar } from 'react-icons/lu'
import TransparentButton from './TransparentButton'

const inputTemplate = '{*d**ДД**2*}.{*m**ММ**2*}.{*y**ГГГГ**4*}г.'

const DateInput = ({ initialValue, onChange, width = '100%', height = '100%' }) => {
  const [dateValue, setDateValue] = useState({ d: '', m: '', y: '' })
  const [inputIsValid, setInputIsValid] = useState(true)
  const [inputIsEmpty, setInputIsEmpty] = useState(true)
  const datePickerRef = useRef(null)

  function onDateChangeHandler(value) {
    const { day, month, year } = validateDateValue(value.d, value.m, value.y)
    const isValid = Boolean(Number(day) && Number(month) && Number(year))
    const isEmpty = !Boolean(day || month || year)
    setInputIsValid(isValid || isEmpty)
    setInputIsEmpty(isEmpty)
    setDateValue({ d: day, m: month, y: year })
    onChange(
      isValid
        ? `${'1900'.slice(0, 4 - String(Number(year)).length) + String(Number(year))}-${(
            '00' + month
          ).slice(-2)}-${('00' + day).slice(-2)}`
        : null
    )
  }

  function onDatePickHandler(event) {
    const [year, month, day] = event.target.value.split('-')
    onDateChangeHandler({ d: day, m: month, y: year })
  }

  useLayoutEffect(() => {
    if (!(initialValue && inputIsEmpty)) return
    const { $y, $M, $D } = dayjs(initialValue)
    setDateValue({
      y: String($y),
      m: ('00' + String($M + 1)).slice(-2),
      d: ('00' + String($D)).slice(-2),
    })
    setInputIsEmpty(false)
    setInputIsValid(true)
  }, [initialValue])

  return (
    <div style={{ position: 'relative', width: width, height: height }}>
      <MaskedInput
        value={dateValue}
        isValid={inputIsValid}
        inputTemplate={inputTemplate}
        onChange={onDateChangeHandler}
        control={
          <TransparentButton onClick={() => datePickerRef.current.showPicker()}>
            <LuCalendar size={'2rem'} />
          </TransparentButton>
        }
      />
      <input
        ref={datePickerRef}
        type="date"
        style={{
          position: 'absolute',
          height: '0',
          width: '0',
          bottom: '0',
          border: 'none',
          visibility: 'hidden',
        }}
        onChange={onDatePickHandler}
      />
    </div>
  )
}

export default DateInput
