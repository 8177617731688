import { useLayoutEffect, useState } from 'react'
import FormBasis, { ContentBlock } from './components/FormBasis'
import ControlledInput from '../controls/ControlledInput'
import Button from '../controls/Button'
import LabeledControl from '../controls/LabeledControl'
import useCombineView from '../../hooks/useCombineView'
import ControlledCheckbox from '../controls/ControlledCheckbox'

const CreateViewForm = ({ fieldsData, onSubmit, onClose }) => {
  const [viewName, setViewName] = useState('')
  const {
    combinedViewFields,
    combinedViewJoins,
    applyViewField,
    applyMultipleViewFields,
    clearViewField,
    applyViewFieldDisplaySettings,
    applyJoin,
    resetCombinedView,
    prepareViewRequestBody,
  } = useCombineView()

  useLayoutEffect(() => {
    applyMultipleViewFields(fieldsData)
  }, [fieldsData])

  function toggleViewField(fieldData) {
    if (
      combinedViewFields?.some(
        (combinedViewField) => combinedViewField?.id === fieldData?.id
      )
    ) {
      clearViewField(fieldData)
    } else {
      applyViewField(fieldData)
    }
  }

  return (
    <FormBasis
      width={'20rem'}
      onSubmit={() => onSubmit(prepareViewRequestBody(viewName))}
    >
      <ContentBlock height={'5rem'}>
        <LabeledControl label={'View name'}>
          <ControlledInput value={viewName} onChange={setViewName} />
        </LabeledControl>
      </ContentBlock>
      <ContentBlock direction="column" height={'fit-content'}>
        {fieldsData?.map((fieldData) => (
          <ControlledCheckbox
            label={fieldData?.name}
            checked={combinedViewFields?.some(
              (combinedViewField) => combinedViewField?.id === fieldData?.id
            )}
            onChange={() => toggleViewField(fieldData)}
          />
        ))}
      </ContentBlock>
      <ContentBlock height={'3rem'}>
        <Button
          type="submit"
          disabled={!viewName || !combinedViewFields?.length}
        >
          Save
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ContentBlock>
    </FormBasis>
  )
}

export default CreateViewForm
