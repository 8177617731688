import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fields: [],
  joins: [],
  filters: [],
  sorts: [],
}

const developmentSlice = createSlice({
  name: 'development',
  initialState,
  reducers: {
    setFields: (state, action) => {
      state.fields = action.payload
    },
    setJoins: (state, action) => {
      state.joins = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setSorts: (state, action) => {
      state.sorts = action.payload
    },
  },
})

export const developmentSelector = {
  getFields: (state) => state.development.fields,
  getJoins: (state) => state.development.joins,
  getFilters: (state) => state.development.filters,
  getSorts: (state) => state.development.sorts,
}

export const { setFields, setJoins, setFilters, setSorts } =
  developmentSlice.actions

export default developmentSlice.reducer
