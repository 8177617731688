import ControlledSelect from '../../controls/ControlledSelect'
import './styles/PaginationPanel.css'

const PaginationPanel = (props) => {
  const { totalPages = 1, currentPage = 1, recordsPerPage, onPageSelect, onRangeSelect } = props

  let pageSelectors = []

  for (let page = 1; page < totalPages + 1; page++) {
    pageSelectors.push(
      <li
        key={page}
        className={`page_selector${page === currentPage ? ' selected_page' : ' available_page'}`}
        onClick={() => onPageSelect(page)}
      >
        {page}
      </li>
    )
  }

  return (
    <div className="pagination_panel_wrapper">
      <div className="additional_slot">
        <ControlledSelect
          width="fit-content"
          height="3rem"
          options={[5, 10, 25, 50, 100]}
          value={recordsPerPage}
          onChange={(value) => onRangeSelect(parseInt(value))}
        />
      </div>
      <ul className="pages_panel">{pageSelectors}</ul>
      <div className="additional_slot"></div>
    </div>
  )
}

export default PaginationPanel
