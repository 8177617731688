import { HiTrash } from 'react-icons/hi'
import { MdEdit } from 'react-icons/md'

import TableBody from './TableBody'
import TableCell from './TableCell'
import TableRow from './TableRow'
import TableHeader from './components/TableHeader'
import CellContent from './components/CellContent'
import ControlButton from './components/controls/ControlButton'
import ControlsPanel from './components/controls/ControlsPanel'
import TableHeaders from './TableHeaders'
import TableBottomControls from './TableBottomControls'
import TableRightControls from './TableRightControls'
import TableData from './TableData'
import TableContent from './TableContent'
import TransparentButton from '../controls/TransparentButton'
import SwitchSortButton from './components/controls/SwitchSortButton'

const UniversalTable = ({
  fieldsData,
  applySort,
  combinedSorts,
  tableData,
  deleteDataRecord,
  editDataRecord,
  deleteField,
  editField,
  bottomControls,
  rightControls,
}) => {
  return (
    <TableBody>
      <TableContent>
        <TableHeaders rightControls={Boolean(rightControls)}>
          {fieldsData?.map((fieldData) => (
            <TableHeader
              key={fieldData?.id}
              canStretch={!fieldData?.displaySettings?.fixed}
              width={fieldData?.displaySettings?.width}
              basicControls={
                <SwitchSortButton
                  width="2rem"
                  height="2rem"
                  applySort={applySort}
                  fieldName={fieldData?.systemName}
                  sortState={
                    combinedSorts?.find(
                      (appliedSort) =>
                        appliedSort?.column === fieldData?.systemName
                    )?.direction ?? 'NONE'
                  }
                />
              }
              additionalControls={
                <>
                  {editField && (
                    <TransparentButton
                      width="2rem"
                      height="2rem"
                      onClick={() => editField({ fieldData: fieldData })}
                    >
                      <MdEdit size={'1.4rem'} />
                    </TransparentButton>
                  )}
                  {deleteField && (
                    <TransparentButton
                      width="2rem"
                      height="2rem"
                      onClick={() => deleteField({ fieldData: fieldData })}
                    >
                      <HiTrash size={'1.4rem'} />
                    </TransparentButton>
                  )}
                </>
              }
            >
              {fieldData?.name}
            </TableHeader>
          ))}
        </TableHeaders>
        <TableData>
          {tableData?.map((rowData) => (
            <TableRow
              key={rowData?.id}
              controlsPanel={
                <ControlsPanel
                  controls={
                    <>
                      <ControlButton
                        onClick={() => deleteDataRecord({ rowID: rowData?.id })}
                        icon={<HiTrash size={'1.8rem'} />}
                      />
                      <ControlButton
                        onClick={() =>
                          editDataRecord({
                            rowID: rowData?.id,
                            rowData: rowData,
                          })
                        }
                        icon={<MdEdit size={'1.8rem'} />}
                      />
                    </>
                  }
                ></ControlsPanel>
              }
            >
              {fieldsData.map((fieldData) => (
                <TableCell
                  key={fieldData?.systemName}
                  canStretch={!fieldData?.displaySettings?.fixed}
                  width={fieldData?.displaySettings?.width}
                >
                  <CellContent
                    type={fieldData?.type}
                    value={rowData[fieldData?.systemName]}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableData>
        {bottomControls && (
          <TableBottomControls rightControls={Boolean(rightControls)}>
            {bottomControls}
          </TableBottomControls>
        )}
      </TableContent>
      {rightControls && (
        <TableRightControls>{rightControls}</TableRightControls>
      )}
    </TableBody>
  )
}

export default UniversalTable
