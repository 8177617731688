import { useParams } from 'react-router-dom'
import useCombineSorts from '../../../hooks/useCombineSorts'
import useFixedModal from '../../../features/modals/useFixedModal'
import {
  useCreateViewMutation,
  useDeleteDataRecordMutation,
  useDeleteFieldMutation,
  useLoadRecordsDataQuery,
} from '../../../services/apiServiceSlice'
import CreateDataRecordForm from '../../../components/forms/CreateDataRecordForm'
import ScrollableBlock from '../../../components/blocks/ScrollableBlock'
import LoadStab from '../../../components/load/LoadStab'
import CreateTableFieldForm from '../../../components/forms/CreateTableFieldForm'
import { FaPlus } from 'react-icons/fa6'
import ConfirmDialog from '../../../components/gialogs/ConfirmDialog'
import EditTableFieldForm from '../../../components/forms/EditTableFieldForm'
import CreateViewForm from '../../../components/forms/CreateViewForm'
import { useLayoutEffect, useMemo, useState } from 'react'
import EditDataRecordForm from '../../../components/forms/EditDataRecordForm'
import UniversalGrid from '../../../components/shared/universal-grid/UniversalGrid'
import TransparentButton from '../../../components/controls/TransparentButton'
import SwitchSortButton from '../../../components/universal-table/components/controls/SwitchSortButton'
import PaginationPanel from '../../../components/shared/pagination-panel/PaginationPanel'
import InlinePopupMenu from '../../../components/popups/InlinePopupMenu'
import TypedContent from '../../../components/shared/typed-content/TypedContent'
import { HiDotsHorizontal, HiTrash } from 'react-icons/hi'
import { MdEdit } from 'react-icons/md'
import useTableFields from '../../../hooks/useTableFields'
import useView from '../../../hooks/useView'
import FiltersPanel from '../../../components/filters/FiltersPanel'
import { TbArrowBarLeft } from 'react-icons/tb'
import { TbArrowBarRight } from 'react-icons/tb'
import EditViewFieldForm from '../../../components/forms/EditViewFieldForm'
import AddViewFieldsForm from '../../../components/forms/AddViewFieldsForm'
import UniversalKanban from '../../../components/shared/universal-kanban/UniversalKanban'
import CustomComponent from '../../../custom-components/CustomComponent'
import ServicePanel from '../../../components/shared/service-panel/ServicePanel'
import { CiViewBoard } from 'react-icons/ci'
import { CiViewList } from 'react-icons/ci'
import { CiSettings } from 'react-icons/ci'
import useKanban from '../../../hooks/useKanban'
import KanbanSettingsForm from '../../../components/forms/KanbanSettingsForm'
import { useDispatch } from 'react-redux'
import { applyNotification } from '../../../store/slices/notificationsSlice'

const DataDisplay = () => {
  const { groupID, databaseID, tableID, viewID } = useParams()
  const dispatch = useDispatch()
  const { combinedSorts, applySort } = useCombineSorts(viewID ?? tableID)
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(25)
  const [totalPages, setTotalPages] = useState(1)

  const [viewOption, setViewOption] = useState('grid')

  const { tableFields, editableTableFields, loadingTableFields } = useTableFields(tableID)

  const {
    viewFields,
    availableViewFields,
    displaySettings,
    displayOptions,
    reorderViewField,
    updateFieldDisplaySettings,
    removeViewField,
    viewFilters,
    applyFilter,
    clearFilter,
    viewJoins,
    applyViewJoin,
    applyViewFields,
  } = useView(viewID)

  const {
    columnsDefiner,
    columnsOrder,
    columnsData,
    availableDefiners,
    changeColumnsDefiner,
    replaceElement,
  } = useKanban({
    groupID: groupID,
    databaseID: databaseID,
    tableID: tableID,
    viewID: viewID,
    viewData: {
      displayOptions,
      displaySettings,
      fields: viewFields,
      filters: viewFilters,
      joins: viewJoins,
    },
    skip: !viewID || viewOption !== 'kanban',
  })

  const currentModeFields = viewID
    ? viewFields
    : tableFields
        .filter((fieldData) => !fieldData?.isSystemField)
        .filter((fieldData) => !fieldData?.isSystemField)

  const skipViewDataLoading = viewID ? !viewFields.length : false

  const tableDataRequestBody = {
    fields: viewID ? viewFields.map((fieldData) => fieldData?.systemName) : ['*'],
    filters: viewID ? viewFilters : null,
    sorts: combinedSorts,
    joins: viewID ? viewJoins : null,
    paginate: {
      perPage: recordsPerPage,
      currentPage: currentPage,
    },
  }

  const { data: tableData, isFetching: loadingTableData } = useLoadRecordsDataQuery(
    {
      groupID: groupID,
      databaseID: databaseID,
      tableID: tableID,
      body: tableDataRequestBody,
    },
    {
      skip: !(groupID && databaseID && tableID) || skipViewDataLoading || viewOption !== 'grid',
    }
  )

  const { openModal, closeModal } = useFixedModal()

  const [createView] = useCreateViewMutation()
  const [deleteDataRecord] = useDeleteDataRecordMutation()
  const [deleteField] = useDeleteFieldMutation()

  useLayoutEffect(() => {
    if (!tableData?.meta) return
    if (currentPage > tableData?.meta?.totalPages) {
      setCurrentPage(tableData?.meta?.totalPages)
    }
    if (tableData?.meta?.totalPages !== totalPages) {
      setTotalPages(tableData?.meta?.totalPages)
    }
    if (tableData?.meta?.totalPages !== 0 && currentPage === 0) {
      setCurrentPage(1)
    }
  }, [tableData?.meta])

  async function createViewHandler(preparedView) {
    const createResult = await createView({
      tableID: tableID,
      body: preparedView,
    })
    if (!createResult?.error) {
      closeModal()
    }
  }

  useLayoutEffect(() => {
    setViewOption('grid')
  }, [viewID, tableID])

  async function deleteDataRecordHandler(rowData) {
    const createDataRecordResult = await deleteDataRecord({
      groupID: groupID,
      databaseID: databaseID,
      tableID: tableID,
      rowID: rowData?.id,
    })
    if (!createDataRecordResult?.error) {
      closeModal()
    }
  }

  function createDataRecord() {
    openModal(
      <CreateDataRecordForm
        groupID={groupID}
        databaseID={databaseID}
        tableID={tableID}
        onClose={closeModal}
        fieldsData={
          viewID
            ? viewFields.filter((fieldData) => fieldData?.tableId === tableID)
            : editableTableFields
        }
        displaySettings={viewID ? displaySettings : undefined}
      />
    )
  }

  function editDataRecord(rowData) {
    const currentRowData = {}

    if (viewID) {
      viewFields
        .filter((fieldData) => fieldData?.tableId === tableID)
        .forEach(
          (fieldData) => (currentRowData[fieldData?.systemName] = rowData[fieldData?.systemName])
        )
    } else {
      editableTableFields.forEach(
        (fieldData) => (currentRowData[fieldData?.systemName] = rowData[fieldData?.systemName])
      )
    }

    openModal(
      <EditDataRecordForm
        groupID={groupID}
        databaseID={databaseID}
        tableID={tableID}
        rowID={rowData?.id}
        rowData={currentRowData}
        fieldsData={viewID ? viewFields : editableTableFields}
        displaySettings={viewID ? displaySettings : undefined}
        onClose={closeModal}
      />
    )
  }

  function createTableField() {
    openModal(
      <CreateTableFieldForm
        tableID={tableID}
        onClose={closeModal}
        totalFields={currentModeFields?.length}
      />
    )
  }

  function deleteFieldHandler(fieldData) {
    openModal(
      <ConfirmDialog
        title={'Удалить поле?'}
        onConfirm={async () => {
          const deleteResult = await deleteField({
            fieldID: fieldData?.id,
          })
          if (!deleteResult?.error) {
            closeModal()
          }
        }}
        onCancel={closeModal}
      />
    )
  }

  function editFieldHandler(fieldData) {
    openModal(
      <EditTableFieldForm
        groupID={groupID}
        databaseID={databaseID}
        tableID={tableID}
        fieldData={fieldData}
        onClose={closeModal}
      />
    )
  }

  function editViewField(fieldData, fieldDisplaySettings) {
    openModal(
      <EditViewFieldForm
        fieldData={fieldData}
        displaySettingsData={fieldDisplaySettings}
        onSubmit={updateFieldDisplaySettings}
        onClose={closeModal}
      />
    )
  }

  function addViewFieldsHandler(fieldsData, joinData) {
    if (!fieldsData && !fieldsData?.length) return
    if (!joinData) {
      applyViewFields(fieldsData)
    } else {
      applyViewJoin(joinData)
      applyViewFields(fieldsData)
    }
  }

  function addViewFields() {
    const appliedFields = viewFields?.map((fieldData) => fieldData?.systemName)
    const unappliedFields = availableViewFields?.filter(
      (fieldData) => !appliedFields.includes(fieldData?.systemName)
    )
    openModal(
      <AddViewFieldsForm
        databaseID={databaseID}
        tableID={tableID}
        onClose={closeModal}
        onSubmit={addViewFieldsHandler}
        joinedTablesIDs={viewJoins.map((joinData) => joinData?.joinTableId)}
        unappliedViewFields={unappliedFields}
        availableViewFields={availableViewFields}
      />
    )
  }

  const rowRightControl = (rowData) => {
    return (
      <InlinePopupMenu
        size="2.4rem"
        icon={<HiDotsHorizontal size={'1.8rem'} />}
        menuItems={[
          <TransparentButton
            onClick={() =>
              openModal(
                <ConfirmDialog
                  title={'Удалить запись?'}
                  onConfirm={() => deleteDataRecordHandler(rowData)}
                  onCancel={closeModal}
                />
              )
            }
          >
            <HiTrash size={'1.8rem'} />
          </TransparentButton>,
          <TransparentButton onClick={() => editDataRecord(rowData)}>
            <MdEdit size={'1.8rem'} />
          </TransparentButton>,
        ]}
      />
    )
  }

  const tableHeaderRightControls = (fieldData) => {
    if (fieldData?.isSystemField) {
      return null
    }
    return (
      <InlinePopupMenu
        size="2.4rem"
        icon={<HiDotsHorizontal size={'1.8rem'} />}
        menuItems={[
          <TransparentButton onClick={() => deleteFieldHandler(fieldData)}>
            <HiTrash size={'1.8rem'} />
          </TransparentButton>,
          <TransparentButton onClick={() => editFieldHandler(fieldData)}>
            <MdEdit size={'1.8rem'} />
          </TransparentButton>,
        ]}
      />
    )
  }

  const viewHeaderRightControls = (fieldData) => {
    const fieldDisplaySettings = displaySettings[fieldData?.systemName] ?? {}
    return (
      <InlinePopupMenu
        size="2.4rem"
        icon={<HiDotsHorizontal size={'1.8rem'} />}
        menuItems={[
          <TransparentButton
            width="2rem"
            disabled={fieldDisplaySettings?.order === 1}
            onClick={() => reorderViewField(fieldData, fieldDisplaySettings?.order - 1)}
          >
            <TbArrowBarLeft size={'1.8rem'} />
          </TransparentButton>,
          <TransparentButton
            width="2rem"
            disabled={fieldDisplaySettings?.order === viewFields?.length}
            onClick={() => reorderViewField(fieldData, fieldDisplaySettings?.order + 1)}
          >
            <TbArrowBarRight size={'1.8rem'} />
          </TransparentButton>,
          <TransparentButton
            disabled={viewFields.length < 2}
            onClick={() => removeViewField(fieldData)}
          >
            <HiTrash size={'1.8rem'} />
          </TransparentButton>,
          <TransparentButton onClick={() => editViewField(fieldData, fieldDisplaySettings)}>
            <MdEdit size={'1.8rem'} />
          </TransparentButton>,
        ]}
      />
    )
  }

  const tableRightControls = viewID ? (
    <TransparentButton width="4.8rem" onClick={addViewFields}>
      <FaPlus size={'2rem'} />
    </TransparentButton>
  ) : (
    <TransparentButton width="4.8rem" onClick={createTableField}>
      <FaPlus size={'2rem'} />
    </TransparentButton>
  )

  // Индивидуальное отображение содержимого ячеек таблицы в зависимости от типа поля
  const contentComponents = useMemo(() => {
    const fieldsContentComponents = {}
    currentModeFields?.forEach((fieldData) => {
      if (viewID && fieldData?.relationFieldId) {
        fieldsContentComponents[fieldData?.systemName] = (contentValue, directDataRecord) => (
          <CustomComponent
            value={contentValue}
            fieldData={fieldData}
            fieldDisplaySettings={displaySettings[fieldData?.systemName]}
            directDataRecord={directDataRecord}
          />
        )
      } else {
        fieldsContentComponents[fieldData?.systemName] = (contentValue) => (
          <TypedContent type={fieldData?.type} value={contentValue} />
        )
      }
    })

    return fieldsContentComponents
  }, [currentModeFields])

  const hiddenFilters =
    viewOption === 'kanban' && columnsDefiner ? [columnsDefiner?.systemName] : []

  return (
    <ScrollableBlock>
      <ServicePanel
        leftControls={
          !viewID ? (
            currentModeFields?.length ? (
              <TransparentButton
                height="3rem"
                width="fit-content"
                onClick={() =>
                  openModal(
                    <CreateViewForm
                      fieldsData={currentModeFields}
                      onClose={closeModal}
                      onSubmit={createViewHandler}
                    />
                  )
                }
              >
                Создать вью
              </TransparentButton>
            ) : (
              <TransparentButton height="3rem" width="fit-content" onClick={createTableField}>
                Создать поле
              </TransparentButton>
            )
          ) : null
        }
        mainSlotComponent={
          viewID ? (
            <FiltersPanel
              fieldsData={viewFields}
              displaySettings={displaySettings}
              appliedFilters={viewFilters}
              hiddenFilters={hiddenFilters}
              applyFilter={applyFilter}
              clearFilter={clearFilter}
            />
          ) : null
        }
        rightControls={
          viewID ? (
            <>
              {viewOption === 'kanban' && (
                <TransparentButton
                  width="2.4rem"
                  height="2.4rem"
                  onClick={() =>
                    openModal(
                      <KanbanSettingsForm
                        definers={availableDefiners}
                        currentDefiner={columnsDefiner}
                        displaySettings={displaySettings}
                        onSubmit={changeColumnsDefiner}
                        onClose={closeModal}
                      />
                    )
                  }
                >
                  <CiSettings size={'2rem'} />
                </TransparentButton>
              )}
              {/*  */}
              <TransparentButton
                width="2.4rem"
                height="2.4rem"
                onClick={() => {
                  if (
                    viewFields?.some(
                      (fieldData) =>
                        fieldData?.relationFieldId &&
                        displaySettings[fieldData?.systemName]?.data?.relationTableID
                    )
                  ) {
                    setViewOption('kanban')
                  } else {
                    dispatch(
                      applyNotification({
                        type: 'warning',
                        message: 'Нет настроенных селектов для построения канбан-доски',
                      })
                    )
                  }
                }}
                disabled={viewOption === 'kanban'}
              >
                <CiViewBoard size={'2rem'} />
              </TransparentButton>
              <TransparentButton
                width="2.4rem"
                height="2.4rem"
                onClick={() => setViewOption('grid')}
                disabled={viewOption === 'grid'}
              >
                <CiViewList size={'2rem'} />
              </TransparentButton>
            </>
          ) : null
        }
      />

      {viewOption === 'grid' && (
        <UniversalGrid
          key={tableID + viewID}
          componentStab={'нет полей для отображения'}
          topSlotComponent={undefined}
          bottomSlotComponent={
            <PaginationPanel
              totalPages={totalPages}
              currentPage={currentPage}
              recordsPerPage={recordsPerPage}
              onPageSelect={(selectedPage) => setCurrentPage(selectedPage)}
              onRangeSelect={(recordsPerPage) => setRecordsPerPage(recordsPerPage)}
            />
          }
          tableRightControls={tableRightControls}
          tableBottomControls={
            <TransparentButton height="4.8rem" onClick={createDataRecord}>
              <FaPlus size={'2rem'} />
            </TransparentButton>
          }
          headerLeftControls={(fieldData) => {
            if (fieldData?.isSystemField) {
              return null
            }

            if (!displaySettings) {
              return null
            }

            const sortFieldSystemName =
              displaySettings[fieldData?.systemName]?.data?.sortField || fieldData?.systemName

            return (
              <SwitchSortButton
                width="2rem"
                height="2rem"
                applySort={applySort}
                fieldName={sortFieldSystemName}
                sortState={
                  combinedSorts?.find((appliedSort) => appliedSort?.column === sortFieldSystemName)
                    ?.direction ?? 'NONE'
                }
              />
            )
          }}
          headerRightControls={viewID ? viewHeaderRightControls : tableHeaderRightControls}
          fieldsData={currentModeFields}
          recordsData={tableData?.data}
          displaySettings={displaySettings}
          contentComponents={contentComponents}
          recordsDataStab={'нет записей'}
          tableRowRightControl={(rowData) => rowRightControl(rowData)}
        />
      )}
      {viewOption === 'kanban' && (
        <UniversalKanban
          key={viewID}
          columnsDefiner={columnsDefiner}
          fieldsData={viewFields?.filter((fieldData) => fieldData?.id !== columnsDefiner?.id)}
          columnsOrder={columnsOrder}
          replaceElement={replaceElement}
          columnsData={columnsData}
          displaySettings={displaySettings}
          contentComponents={contentComponents}
        />
      )}
    </ScrollableBlock>
  )
}

export default DataDisplay
