import styles from './TableBody.module.css'

const TableBody = ({ children }) => {
  return (
    <div className={styles.body}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default TableBody
