import styles from '../styles/KanbanCard.module.scss'
import DNDController from './DNDController'

const KanbanCard = ({ children, draggable, droppable, onDragStart, onDragEnd, onDrop }) => {
  return (
    <DNDController
      className={styles.card_wrapper}
      dropDirection="column"
      draggable={draggable}
      droppable={droppable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
    >
      <div className={styles.card_content}>{children}</div>
    </DNDController>
  )
}

export default KanbanCard
